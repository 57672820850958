import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import { amountUnit } from '../../../../../utils/helper';

function GridData({ data, filterRange, cardName, advisorcode, setAdvisorcode, filteredData }) {
    let apiData = data;
    const sliceValue = 8
    const colname = (values) => {
        if (apiData?.columnname.some(colData => colData.colname === values)) {
            const getDisplayName = apiData?.columnname.find(colData => colData.colname === values);
            return getDisplayName?.displayName;
        }
    }

    const [currentPage, setCurrentPage] = useState(0);
    const recordsPerPage = 4;
    const totalPages = Math.ceil(filteredData?.length / recordsPerPage);

    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const currentRecords = filteredData?.slice(
        currentPage * recordsPerPage,
        (currentPage + 1) * recordsPerPage
    );

    return (
        <div className='lyt-grid-table'>
            {currentRecords?.map((item, index) => (
                <Accordion className='bs-table-accordion'>
                    <AccordionSummary
                        expandIcon={<span className='icon icon-chevron-down'></span>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <ul className='acc-list'>
                            {Object.entries(item).slice(0, sliceValue).map(([key, value], i) => {

                                return (
                                    key !== "id" && (

                                        <li className='acc-item' >
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                {key === "ape" ? amountUnit(value, filterRange) : value}

                                            </span>
                                        </li>
                                    )
                                )
                            })}
                        </ul>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul className='acc-list'>
                            {Object.entries(item).slice(sliceValue).map(([key, value], i) => {
                                return (
                                    key !== "id" && (
                                        <li className='acc-item' >
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                {key === "ape" ? amountUnit(value, filterRange) : value}

                                            </span>
                                        </li>
                                    )
                                )
                            })}
                        </ul>
                    </AccordionDetails>
                </Accordion>

            ))
            }
            <div className='pagination-wrap'>
                <div className='left-wrap'>
                    <IconButton disableRipple className='btn btn-search' onClick={handlePrev} disabled={currentPage === 0}>
                        <span className='icon icon-prev'></span>
                    </IconButton>
                    <IconButton disableRipple className='btn btn-search' onClick={handleNext} disabled={currentPage === totalPages - 1}>
                        <span className='icon icon-next'></span>
                    </IconButton>
                </div>
                <div className='right-wrap'>
                    <span>Page</span>
                    <span>{currentPage + 1}</span>
                    <span>of {totalPages}</span>
                </div>
            </div>
        </div >

    )
}

export default GridData