
import Login from "../pages/Login/view";
// import Home from "../pages/Home/view";
import Dashboard from "../pages/Dashboard/view";
import Dashboard_CXO from "../pages/DashboardCXO/view";
import Issuance from "../pages/Issuance/view";
import Leakage from "../pages/Leakage/view";
import WIP from "../pages/WIP/view";
import Persistency from "../pages/Persistency/view";
import PMS from "../pages/PMS/view";
import ProductMix from "../pages/ProductMix/view";
import Support from "../pages/Support/view";
import Statement from "../pages/Statement/view";
import Components from "../pages/Components/view";

const publicRoutes = [
    // { path: "/login", component: Login },
    { path: "/", component: Login }
]

const protectedRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/components", component: Components },
    { path: "/dashboard-cxo", component: Dashboard_CXO },
    { path: "/issuance", component: Issuance },
    { path: "/leakage", component: Leakage },
    { path: "/wip", component: WIP },
    { path: "/persistency", component: Persistency },
    { path: "/pms", component: PMS },
    { path: "/product-mix", component: ProductMix },
    { path: "/support", component: Support },
    { path: "/statement", component: Statement },
]


export { publicRoutes, protectedRoutes };