import React, { useState, useEffect, useRef } from 'react';
import loadIcon from '../../../common/assets/images/favicon.png'
import loadingImg from '../../../common/assets/images/three-dots.svg'
import Grid from "@mui/material/Grid";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Zoom } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import TableData from './components/TableData'
import { useFetchData } from '../../../hooks/common/useApiService';
import { dateRangeFormat } from '../../../hooks/common/useDateRangeFormat';
import { formatValue, amountUnit } from '../../../utils/helper';
import AgentPolicyTable from './components/AgentPolicyTable';
import DirectTableData from './components/DirectTableData';
import BAPDPremiumTable from './components/BAPDPremiumTable';
import CompanyPremiumTable from './components/CompanyPremiumTable';
import RupeesFilter from '../../../common/components/RupeesFilter';
import { useFilterRange, useDateRange } from '../../../store/generalStore';
import DateRangeSelector from '../../../common/components/DateRangeSelector';


function Issuance() {
    const fetchData = useFetchData();

    const [activeIndex, setActiveIndex] = React.useState(0);
    const [cardName, setcardName] = React.useState("premium");
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [token, setToken] = useState(null);

    const handleCompAdvisorClick = (code, name, channel) => {
        const drillDown = localStorage.getItem("drillDown");
        const dataArray = drillDown ? JSON.parse(drillDown) : []
        const currDrillDown = { "code": code, "name": name }
        localStorage?.setItem("userChannel", channel)
        const newArray = [...dataArray, currDrillDown];
        localStorage.setItem("drillDown", JSON.stringify(newArray))
        const getLength = newArray.length - 1;
        setAdvisorcode(newArray[getLength].code)
        window.scrollTo(0, 0)
        setToken(null)
        setLoading(true)
    }
    const [advisorcode, setAdvisorcode] = useState("");
    const drillDown = JSON.parse(localStorage.getItem("drillDown"));
    const userName = localStorage.getItem("username");
    const findUserType = localStorage.getItem('usertype')
    var findChannel = localStorage.getItem('userChannel')
    var apiUrlPrefix = ""
    if (findChannel === "BANK" || findChannel === "ALNT") {
        apiUrlPrefix = "bapd";
    } else if (findChannel === "DRCT" || findChannel === "WIN") {
        apiUrlPrefix = "direct";
    } else if (findChannel === "TIED" || findChannel === "agency") {
        apiUrlPrefix = "";
    } else {
        apiUrlPrefix = "company";
    }

    useEffect(() => {
        if (drillDown?.length > 0) {
            const getLength = drillDown.length - 1;
            setAdvisorcode(drillDown[getLength].code)
        } else {
            setAdvisorcode(localStorage.getItem('userId'))
        }


        var loginData = ""
        if (findChannel === "DRCT" || findChannel === "WIN" || findChannel === "BANK" || findChannel === "ALNT" || apiUrlPrefix === "company") {
            loginData = JSON.stringify({ "token": `issuance_details_${apiUrlPrefix}` })
        } else {
            loginData = JSON.stringify(findUserType === "agent" || findUserType === "Agent" ? { "token": "policy_combine" } : { "token": "issuance_details_agency" });
        }
        const loginHeaders = {
            'x-token-key': 'BZz8VIdg39p0OxT',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
            'Content-Type': 'application/json'
        };
        fetchData('post', process.env.REACT_APP_TOKEN, loginData, loginHeaders)
            .then((response) => {
                setToken(response.data?.token);

                if (drillDown?.length > 0) {
                    const getLength = drillDown.length - 1;
                    setAdvisorcode(drillDown[getLength].code)
                } else {
                    setAdvisorcode(localStorage.getItem('userId'))
                }
            })
            .catch((error) => {
                console.error('Error fetching token:', error);
            });
    }, [apiUrlPrefix, cardName, drillDown, findChannel, findUserType]);
    const [selectedDate, setSelectedDate] = useState("");
    const dateValue = useDateRange((state) => state.dateValue);
    useEffect(() => {
        if (advisorcode && localStorage.getItem('userId')) {
            var newData = ""
            if (findChannel === "DRCT" || findChannel === "WIN" || findChannel === "BANK" || findChannel === "ALNT" || apiUrlPrefix === "company") {
                newData = JSON.stringify({ "token": `issuance_card_${apiUrlPrefix}` })
            } else {
                newData = JSON.stringify({ "token": "issuance_card_agency" })
            }
            const newHeaders = {
                'x-token-key': 'BZz8VIdg39p0OxT',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
                'Content-Type': 'application/json',
                "agent_id": advisorcode
            };
            fetchData('post', process.env.REACT_APP_TOKEN, newData, newHeaders)
                .then((response) => {
                    const tokenCard = response.data?.token;
                    var payloadData = ""
                    var APIurl = ""
                    if (findChannel === "DRCT" || findChannel === "WIN" || findChannel === "BANK" || findChannel === "ALNT" || apiUrlPrefix === "company") {
                        payloadData = JSON.stringify({
                            "start_date": dateRangeFormat(dateValue[0]),
                            "end_date": dateRangeFormat(dateValue[1]),
                            "agent_id": advisorcode
                        });
                        APIurl = `issuance_card_api_${apiUrlPrefix}`
                    } else {
                        payloadData = JSON.stringify({
                            // "date_column": selectedDate !== "" ? selectedDate : formatDate(),
                            "start_date": dateRangeFormat(dateValue[0]),
                            "end_date": dateRangeFormat(dateValue[1]),
                            "agent_id": advisorcode
                        });
                        APIurl = process.env.REACT_APP_ISSUANCE_CARD_AGENCY
                    }
                    fetchData('post', `gcp/${APIurl}`, payloadData, null, tokenCard)
                        .then((response) => {
                            setData(response?.data);
                            setLoading(false);
                        })
                })
        }
    }, [advisorcode, apiUrlPrefix, findChannel, selectedDate, dateValue])

    const handleLiClick = (index, name) => {
        setActiveIndex(index);
        setcardName(name);
    };

    const filterRange = useFilterRange((state) => state.filterRange);

    const handleBreadcrumb = (value) => {
        setLoading(true)
        if (localStorage.getItem('userId') === value) {
            localStorage.removeItem('drillDown');
            setAdvisorcode(value)
            if (localStorage.getItem("isCompanyUser")) {
                localStorage?.setItem("userChannel", "company")
                setToken(null)
            }
        } else {
            let drillDown = JSON.parse(localStorage.getItem("drillDown"));
            const index = drillDown.findIndex(obj => obj.code === value);
            if (index !== -1) {
                drillDown = drillDown.slice(0, index + 1);
            }
            localStorage.setItem('drillDown', JSON.stringify(drillDown));
            const getLength = drillDown.length - 1;
            setAdvisorcode(drillDown[getLength].code)
        }
    }

    const swiperRef = useRef(null);
    useEffect(() => {
        if (swiperRef.current) {
            const swiperInstance = swiperRef.current.swiper;
            if (findChannel === "DRCT" || findChannel === "WIN") {
                setcardName(data?.card?.[swiperInstance?.realIndex]?.name)
            }
            const handleSlideChange = () => {
                const currentSlideIndex = swiperInstance.realIndex;
                const currentSlide = data?.card?.[currentSlideIndex];
                if (currentSlide) {
                    setcardName(currentSlide.name);
                    setActiveIndex(currentSlideIndex)
                }
            };

            swiperInstance.on('slideChange', handleSlideChange);

            // Cleanup listener on component unmount
            return () => {
                swiperInstance.off('slideChange', handleSlideChange);
            };
        }
    }, [data, findChannel]);

    return (
        <>
            <div className='switch-wrap'>
                {/* <div className='bs-breadcrumb'>
                    <ul className='b-list'>
                        <li className='b-item'>
                            <Link to="#" className='link'>Dashboard</Link>
                        </li>
                        <li className='b-item'>
                            <Link to="#" className='link'>Zone</Link>
                        </li>
                        <li className='b-item'>
                            <Link to="#" className='link'>Retail</Link>
                        </li>
                    </ul>
                </div> */}
                <div className='breadcrumb-wrap'>
                    <div className='bs-breadcrumb'>
                        <ul className='b-list'>
                            <li className='b-item'>
                                <button className='link' onClick={() => handleBreadcrumb(localStorage.getItem('userId'))}>Issuance</button>
                            </li>
                            <li className='b-item'>
                                <button className='link' onClick={() => handleBreadcrumb(localStorage.getItem('userId'))}>{userName !== "" ? userName : "Username"}</button>
                            </li>
                            {drillDown?.map((item, index) => {
                                return (
                                    <li className='b-item'>
                                        <button className='link' onClick={() => handleBreadcrumb(item.code)}>{item.name}</button>
                                    </li>
                                )
                            })}

                        </ul>
                    </div>
                </div>
                <RupeesFilter />
                <DateRangeSelector />
            </div>

            <Grid
                container
                rowSpacing={{ md: 4, xs: 2 }}
                columnSpacing={{ md: 4, xs: 4 }}
            >
                <Grid item md={12} sm={12} xs={12}>
                    <Swiper
                        ref={swiperRef}
                        // navigation={true}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        zoom={true}
                        modules={[Navigation, Pagination, Zoom]}

                        slidesPerView={1.05}
                        spaceBetween={16}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 24,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 24,
                            },
                        }}
                        className="mySwiper bs-swiper"
                    >
                        {loading ?

                            [null, null, null]?.map((iem, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        {loading ? (
                                            <div className='cm-loader'>
                                                <div className='load-img'>
                                                    <img src={loadIcon} alt='loader' />
                                                    <img src={loadingImg} alt='loading' className='loading-gif' />
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )

                                        }
                                    </SwiperSlide>

                                )
                            })
                            :
                            data && (
                                data?.card?.map((item, index) => (

                                    (findUserType === "agent" || findUserType === "Agent") && (item.name.toLowerCase() === "nca" || item.name.toLowerCase().replaceAll(" ", "") === "newcoding")
                                        ?
                                        <></> :
                                        <SwiperSlide key={index}>
                                            {loading ? (
                                                <div className='cm-loader'>
                                                    <div className='load-img'>
                                                        <img src={loadIcon} alt='loader' />
                                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={`bs-card ${item.bgiconcolor} ${activeIndex === index ? 'active' : ''} ${findChannel === "BANK" || findChannel === "ALNT" ? 'typ-equal-height' : ''}`} onClick={() => handleLiClick(index, item.name)}>

                                                    <div className='head-wrap'>
                                                        <div className='title-wrap'>
                                                            <div className='icon-wrap'>
                                                                <span className={`icon icon-${item.iconvalue}`}></span>
                                                            </div>
                                                            <h2 className='title'>
                                                                <span>{item.name}</span>
                                                                {item.name.toLowerCase() === "premium" || item.name.toLowerCase() === "ape" || item.name.toLowerCase() === "cp" || item.name.toLowerCase() === "wfyp" || item.name.toLowerCase().replaceAll(' ', '') === "productmix-ape" || item.name.toLowerCase().replaceAll(' ', '') === "apegross" || item.name.toLowerCase().replaceAll(' ', '') === "apenet" ?
                                                                    amountUnit(item.value, filterRange) : formatValue(item?.value)
                                                                }
                                                            </h2>
                                                        </div>
                                                        {(apiUrlPrefix === "company" && item.name.toLowerCase().replaceAll(' ', '') === "productmix-ape") ? <></> :
                                                            <div className='stock-wrap'>
                                                                {(apiUrlPrefix === "direct" && (item.name.toLowerCase().replaceAll(' ', '') === "attrition%" || item.name.toLowerCase().replaceAll(' ', '') === "netgain")) || (apiUrlPrefix === "bapd" && (item.name.toLowerCase().replaceAll(' ', '') === "productmix")) ? <></> :
                                                                    <div className={`mod-stock-compare ${parseFloat(item.planvalue) <= 85 ? "down" : ""} ${parseFloat(item.planvalue) > 85 && parseFloat(item.planvalue) < 95 ? "medium" : ""}`}>
                                                                        <span className={`icon ${parseFloat(item.planvalue) <= 85 ? "icon-down-indicator" : "icon-up-indicator"} ${parseFloat(item.planvalue) > 85 && parseFloat(item.planvalue) < 95 ? "icon-up-indicator" : "icon-up-indicator"}`}></span>
                                                                        <span className='text'>{item.planvalue} vs Plan</span>
                                                                    </div>
                                                                }
                                                                <div className={`mod-stock-compare ${parseFloat(item.lysmvalue) <= 85 ? "down" : ""} ${parseFloat(item.lysmvalue) > 85 && parseFloat(item.lysmvalue) < 95 ? "medium" : ""}`}>
                                                                    <span className={`icon ${parseFloat(item.lysmvalue) <= 85 ? "icon-down-indicator" : "icon-up-indicator"} ${parseFloat(item.lysmvalue) > 85 && parseFloat(item.lysmvalue) < 95 ? "icon-up-indicator" : "icon-up-indicator"}`}></span>
                                                                    <span className='text'>{item.lysmvalue} vs LYSM</span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='cont-wrap'>
                                                        <div className={`bs-table typ-simple typ-commo ${filterRange === "absolute" ? "typ-abt" : ""}`}>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        {item?.columnname?.map((col, index) => (
                                                                            <th key={index}>{col.displayName}</th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>
                                                                {item.name.toLowerCase() === "premium" || item.name.toLowerCase() === "ape" || item.name.toLowerCase() === "cp" || item.name.toLowerCase() === "wfyp" || item.name.toLowerCase().replaceAll(' ', '') === "productmix-ape" || item.name.toLowerCase().replaceAll(' ', '') === "apegross" || item.name.toLowerCase().replaceAll(' ', '') === "apenet" ? (
                                                                    <tbody>
                                                                        {item?.columndata?.map((colData, index) => (
                                                                            (findUserType === "agent" || findUserType === "Agent") && (index === 1 || index === 2) ? <></> :
                                                                                <tr key={index}>
                                                                                    {item?.columnname?.map((col, index) => (
                                                                                        <td key={index}>
                                                                                            {!Number(colData[col.colname]) ?
                                                                                                colData[col.colname]
                                                                                                :
                                                                                                <>
                                                                                                    {amountUnit(colData[col.colname], filterRange)}
                                                                                                    {(col.colname === "ftd" || col.colname === "mtd") && <span className={`icon icon-${colData[col.colname] > 0 ? 'up' : 'down'}`}></span>}
                                                                                                </>
                                                                                            }
                                                                                        </td>
                                                                                    ))}
                                                                                </tr>
                                                                        ))}

                                                                    </tbody>
                                                                ) : (
                                                                    <tbody>
                                                                        {item?.columndata?.map((colData, index) => (
                                                                            (findUserType === "agent" || findUserType === "Agent") && (index === 1 || index === 2) ? <></> :
                                                                                <tr key={index}>
                                                                                    {item?.columnname?.map((col, index) => (
                                                                                        <td key={index}>
                                                                                            {!Number(colData[col.colname]) ?
                                                                                                colData[col.colname]
                                                                                                :
                                                                                                <>
                                                                                                    {formatValue(colData[col.colname])}
                                                                                                    {(col.colname === "ftd" || col.colname === "mtd") && <span className={`icon icon-${colData[col.colname] > 0 ? 'up' : 'down'}`}></span>}
                                                                                                </>
                                                                                            }
                                                                                        </td>
                                                                                    ))}
                                                                                </tr>
                                                                        ))}

                                                                    </tbody>
                                                                )}
                                                            </table>
                                                        </div>
                                                        {/* <div className='action-wrap cm-hidden-md'>
                                                        <Button className="btn btn-link typ-underline">VIEW DETAILS</Button>
                                                    </div> */}
                                                    </div>
                                                    <div className='indicator'></div>
                                                </div>
                                            )

                                            }
                                        </SwiperSlide>
                                ))
                            )}
                        <div className="swiper-button-prev"><span className='icon icon-prev'></span></div>
                        <div className="swiper-button-next"><span className='icon icon-next'></span></div>
                    </Swiper>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    {loading ? (
                        <div className='cm-loader'>
                            <div className='load-img'>
                                <img src={loadIcon} alt='loader' />
                                <img src={loadingImg} alt='loading' className='loading-gif' />
                            </div>
                        </div>
                    ) :
                        (
                            findUserType === "agent" || findUserType === "Agent" ?
                                <AgentPolicyTable advisorcode={advisorcode} filterRange={filterRange} selectedDate={selectedDate} token={token} />
                                :
                                findChannel === "DRCT" || findChannel === "WIN" ?
                                    <DirectTableData cardName={cardName} advisorcode={advisorcode} filterRange={filterRange} setAdvisorcode={setAdvisorcode} dateValue={dateValue} token={token} />
                                    :
                                    findChannel === "BANK" || findChannel === "ALNT" ?
                                        <BAPDPremiumTable cardName={cardName} advisorcode={advisorcode} filterRange={filterRange} setAdvisorcode={setAdvisorcode} dateValue={dateValue} token={token} />
                                        :
                                        findChannel === "TIED" || findChannel === "agency" ?
                                            <TableData cardName={cardName} advisorcode={advisorcode} filterRange={filterRange} setAdvisorcode={setAdvisorcode} dateValue={dateValue} token={token} />
                                            :
                                            <CompanyPremiumTable cardName={cardName} advisorcode={advisorcode} filterRange={filterRange} setAdvisorcode={setAdvisorcode} dateValue={dateValue} token={token} handleCompAdvisorClick={handleCompAdvisorClick} />
                        )
                    }
                </Grid>

            </Grid>
        </>
    )
}

export default Issuance