import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import useTableFormat from '../../../../../hooks/common/useTableFormat';
import GridData from '../GridData';
import useSize from '../../../../../hooks/common/useSize'
import { useFetchData } from '../../../../../hooks/common/useApiService';
import { formatDate } from '../../../../../hooks/common/useDateFormat';

import loadIcon from '../../../../../common/assets/images/favicon.png'
import loadingImg from '../../../../../common/assets/images/three-dots.svg'

import { amountUnit } from '../../../../../utils/helper';

import Pagination from '@mui/material/Pagination';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


function PremiumTable({ filterRange, advisorcode, setAdvisorcode, token }) {
    const fetchData = useFetchData();
    const winDimensions = useSize();
    const [data, setData] = useState(null);
    const [columnsData, setColumnsData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(0);
    let pageSize = 7;

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        const payloadData = JSON.stringify({
            "date_column": formatDate(),
            "agent_id": advisorcode,
            // "date_column": "2024-06-11T00:00:00",
            // "date_column": "2024-04-30T00:00:00",
            // "agent_id": "80145123"
        });
        setLoading(true)
        setPage(0)


        fetchData('post', process.env.REACT_APP_WIP_PREMIUM_DETAILS, payloadData, null, token)?.then((response) => {
            var cardData = response?.data[0];
            setData(cardData)
            setFilteredData(cardData?.detail);
            const colData = (cardData?.columnname ?? []).map(col => {
                if (col && col.colname && col.colname !== 'id') {
                    return {
                        field: col.colname,
                        headerName: col.displayName,
                        width: col.dWidth,
                    };
                }
                return null;
            }).filter(Boolean);

            setColumnsData(colData)
            setLoading(false)
        })
    }, [advisorcode, token]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage - 1);
    };
    const [searchShow, setSearchShow] = React.useState(false)
    const handleSearch = () => {
        setSearchShow(true)
    }



    const CustomCellRenderer = ({ value, column, row }) => {
        if (column.field === 'policyDetails') {
            return (
                <Button className="btn btn-link typ-underline">{value}</Button>
            );
        }
        if (column.field === 'ape' || column.field === 'sum_assured') {
            return amountUnit(value, filterRange)
        }
        return <div data-th={column?.headerName || ''}>{value}</div>;
    };



    const handleFilterSearch = (event, value) => {
        // const { value } = event.target;
        setSearchTerm(value);
        if (value === "") {
            setFilteredData(data?.detail || []);
        } else {
            const lowercasedValue = value.toLowerCase();
            const filtered = data?.detail.filter((item) =>
                Object.keys(item).some((key) =>
                    item[key]?.toString().toLowerCase().includes(lowercasedValue)
                )
            );
            setFilteredData(filtered);
            setPage(0)
        }
    };

    const handleSearchClear = () => {
        setSearchTerm("")
        setFilteredData(data?.detail || []);
        setSearchShow(false)
    }
    const { divRef, columns } = useTableFormat(columnsData);
    const calculateColumnWidths = (data, pageWidth) => {
        // const doc = new jsPDF({ orientation: 'landscape' });
        const totalWidth = columns.reduce((acc, col) => acc + col.width, 0);
        const scaleFactor = pageWidth / totalWidth;

        return columns.map(col => ({
            headerName: col.headerName,
            field: col.field,
            width: col.width * scaleFactor,
        }));
    };


    const handleExport = (format) => {
        if (format === 'excel') {
            const ws = XLSX.utils.json_to_sheet(data?.detail);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Data');
            XLSX.writeFile(wb, 'data.xlsx');
        } else if (format === 'pdf') {
            const doc = new jsPDF({ orientation: 'landscape' });
            const pageWidth = doc.internal.pageSize.getWidth() - 20;
            const scaledColumns = calculateColumnWidths(data?.detail, pageWidth);



            doc.autoTable({
                head: [scaledColumns.map(col => col.headerName)],
                body: data?.detail?.map(row => scaledColumns.map(col => row[col.field])),
                columnStyles: scaledColumns.reduce((acc, col, i) => ({
                    ...acc, [i]: { cellWidth: col.width }
                }), {}),
                // styles: {
                //     overflow: 'linebreak',
                //     cellPadding: 2,
                //     fontSize: 10,
                // },
                // columnStyles: columns.reduce((acc, col, i) => ({ ...acc, [i]: { cellWidth: columnWidths[i] } }), {}),
                // styles: {
                //     overflow: 'linebreak',
                //     cellPadding: 2,
                //     fontSize: 10,
                // },
            });
            doc.save('data.pdf');
        }
    };

    return (
        <>
            <div className={`bs-panel typ-table typ-auto`}>
                <div className='panel-head'>
                    <div className='left-wrap'>
                        <h2 className='p-title'>{data?.name}</h2>
                    </div>
                    <div className='right-wrap'>
                        <div className={`bs-search  ${searchShow ? 'active' : ''}`}>
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                open={false}
                                value={searchTerm}
                                options={filteredData?.flatMap(Object.values).map(value => value.toString())}
                                onInputChange={handleFilterSearch}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        InputProps={{
                                            ...params.InputProps,

                                            endAdornment: (
                                                <InputAdornment position="end" onClick={handleSearchClear}>
                                                    <span className={`icon icon-${searchTerm === "" ? "search" : "close"}`}></span>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder='Search' />
                                )}
                                classes={{ paper: 'bs-menu-dropdown' }}

                            />
                        </div>
                        <div className='bs-select typ-sm cm-hidden-sm'>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value=""
                                    renderValue={(selected) => "Export"}
                                    onChange={(e) => handleExport(e.target.value)}
                                    IconComponent={(props) => (
                                        <span
                                            {...props}
                                            className={`icon-chevron-down ${props.className}`}
                                        ></span>
                                    )}
                                    MenuProps={{
                                        classes: {
                                            paper: "bs-menu-dropdown",
                                        },
                                    }}
                                    displayEmpty
                                >
                                    <MenuItem value="excel">Export to Excel</MenuItem>
                                    {/* <MenuItem value="pdf">Export to PDF</MenuItem> */}
                                </Select>
                            </FormControl>
                        </div>
                        {/* <div className='cm-hidden-md'>
                            <IconButton disableRipple className='btn btn-search' onClick={() => handleExport("excel")}>
                                <span className='icon icon-download'></span>
                            </IconButton>
                        </div> */}
                        <div className='cm-hidden-md'>
                            <IconButton disableRipple className='btn btn-search' onClick={handleSearch}>
                                <span className='icon icon-search'></span>
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className='panel-content' ref={divRef}>
                    {winDimensions[0] <= 768 ?
                        (
                            loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (
                                <GridData data={data} advisorcode={advisorcode} setAdvisorcode={setAdvisorcode} filteredData={filteredData} filterRange={filterRange} />
                            )
                        ) : (
                            loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (
                                <DataGrid
                                    className='bs-data-table'
                                    rows={searchTerm ? filteredData.slice(page * pageSize, page * pageSize + pageSize) : data?.detail?.length > 0 ? data?.detail.slice(page * pageSize, page * pageSize + pageSize) : []}
                                    columns={columns?.map(col => ({ ...col, renderCell: (params) => <CustomCellRenderer {...params} column={col} row={params.row} /> }))}
                                    pageSize={pageSize}
                                />
                            )
                        )}
                </div>
            </div>
            <div className='table-pagination'>
                <Pagination
                    count={Math.ceil(data?.detail?.length / pageSize)}
                    page={page + 1}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                    className='bs-pagination'
                />
            </div>
        </>

    )
}

export default PremiumTable