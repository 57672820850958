export function formatDate() {
    const date = new Date()
    const pad = (number) => (number < 10 ? '0' + number : number);

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `2024-08-31T00:00:00`;
    // return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}