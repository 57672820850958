import { useEffect, useCallback, useRef } from 'react';

const useIdleTimer = (onIdle, timeout = 1000 * 60 * 15) => {
    const lastActiveTimeRef = useRef(Date.now());

    const resetTimer = useCallback(() => {
        lastActiveTimeRef.current = Date.now();
    }, []);

    useEffect(() => {
        const handleActivity = () => {
            resetTimer();
        };

        const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];
        events.forEach(event => window.addEventListener(event, handleActivity));

        const checkIdle = () => {
            if (Date.now() - lastActiveTimeRef.current > timeout) {
                onIdle();
            }
        };

        const intervalId = setInterval(checkIdle, 1000);

        return () => {
            events.forEach(event => window.removeEventListener(event, handleActivity));
            clearInterval(intervalId);
        };
    }, [onIdle, resetTimer, timeout]);

    return { resetTimer };
}

export default useIdleTimer;
