import React, { useState, useEffect } from 'react';
import loadIcon from '../../../common/assets/images/favicon.png'
import loadingImg from '../../../common/assets/images/three-dots.svg'
import Grid from "@mui/material/Grid";
import BarChart from './components/BarChart'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/flatpickr.min.css';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js';
import Donut from './components/Donut'
import IconButton from '@mui/material/IconButton';
import GridData from './components/GridData'
import useSize from '../../../hooks/common/useSize'
import MenuFilter from '@mui/material/Menu';
import Fade from "@mui/material/Fade";

function ProductMix() {
    const winDimensions = useSize();
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <div className='mb-32'></div>
            <Grid
                container
                rowSpacing={{ md: 5, xs: 3 }}
                columnSpacing={{ md: 4, xs: 3 }}
            >
                <Grid item md={12} sm={12} xs={12}>
                    <div className='bs-panel typ-table typ-transparent'>
                        <div className='panel-head'>
                            <div className='left-wrap'>
                                <h2 className='p-title'>PRODUCT MIX</h2>
                            </div>
                            <div className='right-wrap'>
                                <div className='bs-select typ-sm'>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                            IconComponent={(props) => (
                                                <span
                                                    {...props}
                                                    className={`icon-chevron-down ${props.className}`}
                                                ></span>
                                            )}
                                            MenuProps={{
                                                classes: {
                                                    paper: "bs-menu-dropdown",
                                                },
                                            }}
                                            displayEmpty
                                        >
                                            <MenuItem value="">Filters</MenuItem>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <div className='cm-loader'>
                                <div className='load-img'>
                                    <img src={loadIcon} alt='loader' />
                                    <img src={loadingImg} alt='loading' className='loading-gif' />
                                </div>
                            </div>
                        ) : (
                            <div className='panel-content'>

                                {winDimensions[0] <= 768 ? (
                                    <GridData />
                                ) : (
                                    <div className='bs-table typ-persistency'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th rowSpan='2'>Zones</th>
                                                    <th colSpan='2'>PAR</th>
                                                    <th colSpan='2'>NO PAR</th>
                                                    <th colSpan='2'>ULIP</th>
                                                    <th colSpan='2'>TERM</th>
                                                    <th colSpan='2'>ANNUITY</th>
                                                    <th colSpan='2'>PENSION</th>
                                                </tr>
                                                <tr>
                                                    <th>PPT&lt;=10</th>
                                                    <th>PPT&gt;=10</th>
                                                    <th>PPT&lt;=10</th>
                                                    <th>PPT&gt;=10</th>
                                                    <th>PPT&lt;=10</th>
                                                    <th>PPT&gt;=10</th>
                                                    <th>PPT&lt;=10</th>
                                                    <th>PPT&gt;=10</th>
                                                    <th>PPT&lt;=10</th>
                                                    <th>PPT&gt;=10</th>
                                                    <th>PPT&lt;=10</th>
                                                    <th>PPT&gt;=10</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Zone 1</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                </tr>
                                                <tr>
                                                    <td>Zone 2</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                </tr>
                                                <tr>
                                                    <td>Zone 3</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                </tr>
                                                <tr>
                                                    <td>Zone 4</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                </tr>
                                                <tr>
                                                    <td>Zone 5</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                    <td>43%</td>
                                                    <td>23%</td>
                                                </tr>

                                            </tbody>

                                        </table>
                                    </div>
                                )}
                            </div>
                        )}

                    </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <div className='bs-panel typ-graph'>
                        <div className='panel-head'>
                            <div className='left-wrap'>
                                <h2 className='p-title'>SALES BY ZONE</h2>
                            </div>
                            <div className='right-wrap'>
                                <div className='bs-select typ-sm cm-hidden-sm'>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                            IconComponent={(props) => (
                                                <span
                                                    {...props}
                                                    className={`icon-chevron-down ${props.className}`}
                                                ></span>
                                            )}
                                            MenuProps={{
                                                classes: {
                                                    paper: "bs-menu-dropdown",
                                                },
                                            }}
                                            displayEmpty
                                        >
                                            <MenuItem value="">Filters</MenuItem>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='cm-hidden-md'>
                                    <IconButton
                                        disableRipple
                                        className='btn btn-search'
                                        aria-controls={open ? 'fade-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleFilterClick}
                                    >
                                        <span className='icon icon-filter'></span>
                                    </IconButton>
                                    <MenuFilter
                                        id="fade-menu"
                                        className="bs-menu-dropdown"
                                        MenuListProps={{
                                            "aria-labelledby": "fade-button",
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleFilterClose}
                                        TransitionComponent={Fade}
                                        elevation={0}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                    >
                                        <MenuItem value="" onClick={handleFilterClose}>Filters</MenuItem>
                                        <MenuItem value={10} onClick={handleFilterClose}>Ten</MenuItem>
                                        <MenuItem value={20} onClick={handleFilterClose}>Twenty</MenuItem>
                                        <MenuItem value={30} onClick={handleFilterClose}>Thirty</MenuItem>
                                    </MenuFilter>
                                </div>
                            </div>
                        </div>
                        <div className='panel-content'>
                            {loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (
                                <div className='donut-container'>
                                    <div className='donut-wrap'>
                                        <div className='d-left-wrap'>
                                            <h3 className='d-title'>PPT &lt;=10</h3>
                                            <div className='chart-wrap'>
                                                <Donut />
                                                <div className='middle-text'>
                                                    <p className='pair'>Total sales (₹)</p>
                                                    <p className='value'>7.2 cr</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='legend-wrap'>
                                            <div className='bs-legend'>
                                                <ul className='l-list'>
                                                    <li className='l-item'>
                                                        <label className='pair'>
                                                            <span className='dot' style={{ background: "#43A4EC" }}></span>
                                                            Par
                                                        </label>
                                                        <span className='value'>2.52 CR (35%)</span>
                                                    </li>
                                                    <li className='l-item'>
                                                        <label className='pair'>
                                                            <span className='dot' style={{ background: "#3DDF94" }}></span>
                                                            No Par
                                                        </label>
                                                        <span className='value'>1.44 CR (20%)</span>
                                                    </li>
                                                    <li className='l-item'>
                                                        <label className='pair'>
                                                            <span className='dot' style={{ background: "#FFD643" }}></span>
                                                            ULIP
                                                        </label>
                                                        <span className='value'>1.08 CR (15%)</span>
                                                    </li>
                                                    <li className='l-item'>
                                                        <label className='pair'>
                                                            <span className='dot' style={{ background: "#FF714E" }}></span>
                                                            Term
                                                        </label>
                                                        <span className='value'>1.15 CR (16%)</span>
                                                    </li>
                                                    <li className='l-item'>
                                                        <label className='pair'>
                                                            <span className='dot' style={{ background: "#B4C6D9" }}></span>
                                                            Annuity
                                                        </label>
                                                        <span className='value'>0.72 CR (10%)</span>
                                                    </li>
                                                    <li className='l-item'>
                                                        <label className='pair'>
                                                            <span className='dot' style={{ background: "#E463EF" }}></span>
                                                            Pension
                                                        </label>
                                                        <span className='value'>0.28 CR (4%)</span>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='donut-wrap'>
                                        <div className='d-left-wrap'>
                                            <h3 className='d-title'>PPT &lt;=10</h3>
                                            <div className='chart-wrap'>
                                                <Donut />
                                                <div className='middle-text'>
                                                    <p className='pair'>Total sales (₹)</p>
                                                    <p className='value'>7.2 cr</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='legend-wrap'>
                                            <div className='bs-legend'>
                                                <ul className='l-list'>
                                                    <li className='l-item'>
                                                        <label className='pair'>
                                                            <span className='dot' style={{ background: "#43A4EC" }}></span>
                                                            Par
                                                        </label>
                                                        <span className='value'>2.52 CR (35%)</span>
                                                    </li>
                                                    <li className='l-item'>
                                                        <label className='pair'>
                                                            <span className='dot' style={{ background: "#3DDF94" }}></span>
                                                            No Par
                                                        </label>
                                                        <span className='value'>1.44 CR (20%)</span>
                                                    </li>
                                                    <li className='l-item'>
                                                        <label className='pair'>
                                                            <span className='dot' style={{ background: "#FFD643" }}></span>
                                                            ULIP
                                                        </label>
                                                        <span className='value'>1.08 CR (15%)</span>
                                                    </li>
                                                    <li className='l-item'>
                                                        <label className='pair'>
                                                            <span className='dot' style={{ background: "#FF714E" }}></span>
                                                            Term
                                                        </label>
                                                        <span className='value'>1.15 CR (16%)</span>
                                                    </li>
                                                    <li className='l-item'>
                                                        <label className='pair'>
                                                            <span className='dot' style={{ background: "#B4C6D9" }}></span>
                                                            Annuity
                                                        </label>
                                                        <span className='value'>0.72 CR (10%)</span>
                                                    </li>
                                                    <li className='l-item'>
                                                        <label className='pair'>
                                                            <span className='dot' style={{ background: "#E463EF" }}></span>
                                                            Pension
                                                        </label>
                                                        <span className='value'>0.28 CR (4%)</span>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <div className='bs-panel typ-graph'>
                        <div className='panel-head'>
                            <div className='left-wrap'>
                                <h2 className='p-title'>AEP: CATEGORY & PRODUCT NAME MIX</h2>
                            </div>
                            <div className='right-wrap'>
                                <div className='bs-date cm-hidden-sm'>
                                    <Flatpickr
                                        options={{
                                            mode: "range",
                                            dateFormat: "F y",
                                            altFormat: "M y",
                                            // defaultDate: "Jan 24",
                                            // disableMobile: true,
                                            plugins: [
                                                new monthSelectPlugin({
                                                    shorthand: true,
                                                    dateFormat: "M y",
                                                    altInput: true,
                                                    altFormat: "M y",
                                                    theme: "light"
                                                })]
                                        }}
                                        placeholder="Jan 24"
                                    />
                                    <span className='icon icon-calendar'></span>
                                </div>
                                <div className='bs-select typ-sm cm-hidden-sm'>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                            IconComponent={(props) => (
                                                <span
                                                    {...props}
                                                    className={`icon-chevron-down ${props.className}`}
                                                ></span>
                                            )}
                                            MenuProps={{
                                                classes: {
                                                    paper: "bs-menu-dropdown",
                                                },
                                            }}
                                            displayEmpty
                                        >
                                            <MenuItem value="">Filters</MenuItem>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {/* <div className='cm-hidden-md'>
                                    <IconButton disableRipple className='btn btn-search'>
                                        <span className='icon icon-calendar'></span>
                                    </IconButton>
                                </div> */}
                                <div className='cm-hidden-md'>
                                    <IconButton
                                        disableRipple
                                        className='btn btn-search'
                                        aria-controls={open ? 'fade-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleFilterClick}
                                    >
                                        <span className='icon icon-filter'></span>
                                    </IconButton>
                                    <MenuFilter
                                        id="fade-menu"
                                        className="bs-menu-dropdown"
                                        MenuListProps={{
                                            "aria-labelledby": "fade-button",
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleFilterClose}
                                        TransitionComponent={Fade}
                                        elevation={0}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                    >
                                        <MenuItem value="" onClick={handleFilterClose}>Filters</MenuItem>
                                        <MenuItem value={10} onClick={handleFilterClose}>Ten</MenuItem>
                                        <MenuItem value={20} onClick={handleFilterClose}>Twenty</MenuItem>
                                        <MenuItem value={30} onClick={handleFilterClose}>Thirty</MenuItem>
                                    </MenuFilter>
                                </div>
                            </div>
                        </div>
                        <div className='panel-content'>
                            {loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (

                                <BarChart />
                            )}
                        </div>
                    </div>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <div className='bs-panel typ-graph'>
                        <div className='panel-head'>
                            <div className='left-wrap'>
                                <h2 className='p-title'>NOP: CATEGORY & PRODUCT NAME MIX</h2>
                            </div>
                            <div className='right-wrap'>
                                <div className='bs-date cm-hidden-sm'>
                                    <Flatpickr
                                        options={{
                                            mode: "range",
                                            dateFormat: "F y",
                                            altFormat: "M y",
                                            // defaultDate: "Jan 24",
                                            // disableMobile: true,
                                            plugins: [
                                                new monthSelectPlugin({
                                                    shorthand: true,
                                                    dateFormat: "M y",
                                                    altInput: true,
                                                    altFormat: "M y",
                                                    theme: "light"
                                                })]
                                        }}
                                        placeholder="Jan 24"
                                    />
                                    <span className='icon icon-calendar'></span>
                                </div>
                                <div className='bs-select typ-sm cm-hidden-sm'>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                            IconComponent={(props) => (
                                                <span
                                                    {...props}
                                                    className={`icon-chevron-down ${props.className}`}
                                                ></span>
                                            )}
                                            MenuProps={{
                                                classes: {
                                                    paper: "bs-menu-dropdown",
                                                },
                                            }}
                                            displayEmpty
                                        >
                                            <MenuItem value="">Filters</MenuItem>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {/* <div className='cm-hidden-md'>
                                    <IconButton disableRipple className='btn btn-search'>
                                        <span className='icon icon-calendar'></span>
                                    </IconButton>
                                </div> */}
                                <div className='cm-hidden-md'>
                                    <IconButton
                                        disableRipple
                                        className='btn btn-search'
                                        aria-controls={open ? 'fade-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleFilterClick}
                                    >
                                        <span className='icon icon-filter'></span>
                                    </IconButton>
                                    <MenuFilter
                                        id="fade-menu"
                                        className="bs-menu-dropdown"
                                        MenuListProps={{
                                            "aria-labelledby": "fade-button",
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleFilterClose}
                                        TransitionComponent={Fade}
                                        elevation={0}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                    >
                                        <MenuItem value="" onClick={handleFilterClose}>Filters</MenuItem>
                                        <MenuItem value={10} onClick={handleFilterClose}>Ten</MenuItem>
                                        <MenuItem value={20} onClick={handleFilterClose}>Twenty</MenuItem>
                                        <MenuItem value={30} onClick={handleFilterClose}>Thirty</MenuItem>
                                    </MenuFilter>
                                </div>
                            </div>
                        </div>
                        <div className='panel-content'>
                            {loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (

                                <BarChart />
                            )}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default ProductMix