import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import { amountUnit, formatValue } from '../../../../../utils/helper';
import Button from '@mui/material/Button';

function CompanyGridData({ data, filterRange, cardName, advisorcode, setAdvisorcode, filteredData, handleCompAdvisorClick }) {
    let apiData = data;
    const colname = (values) => {
        if (apiData?.columnname.some(colData => colData.colname === values)) {
            const getDisplayName = apiData?.columnname.find(colData => colData.colname === values);
            return getDisplayName?.displayName;
        }
    }

    const [currentPage, setCurrentPage] = useState(0);
    const recordsPerPage = 4;
    const totalPages = Math.ceil(filteredData?.length / recordsPerPage);

    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const currentRecords = filteredData?.slice(
        currentPage * recordsPerPage,
        (currentPage + 1) * recordsPerPage
    );


    return (
        <div className='lyt-grid-table'>
            {currentRecords?.map((item, index) => (

                <Accordion className='bs-table-accordion'>
                    <AccordionSummary
                        expandIcon={<span className='icon icon-chevron-down'></span>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <ul className='acc-list'>
                            <li className='acc-item' >
                                <span className='pair'>{item.agentname} - {item.details || item.channel}</span>
                            </li>
                            {Object.entries(item).map(([key, value], i) => {
                                return (
                                    (key === "mtdgrossach%" || key === "mtdnetach%" || key === "ytdach%") && (
                                        <li className='acc-item' >
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                <div className={`mod-tag ${value <= 85 ? "down" : ""} ${value > 85 && value < 95 ? "medium" : ""}`}>
                                                    <span className='value'>
                                                        {formatValue(value)}%
                                                    </span>
                                                    <span className='icon icon-trending'></span>
                                                </div>
                                            </span>
                                        </li>
                                    )
                                )
                            })}
                            {Object.entries(item).map(([key, value], i) => {
                                return (
                                    (
                                        key === "mtdgrossftd" || key === "mtdgrossach" || key === "mtdgrossshortfall"

                                    ) && (
                                        <li className='acc-item'>
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                {(cardName.toLowerCase() === "premium" || cardName.toLowerCase() === "ape" || cardName.toLowerCase() === "cp" || cardName.toLowerCase().replaceAll(' ', '') === "productmix-ape") || cardName.toLowerCase().replaceAll(" ", "") === "apegross" || cardName.toLowerCase().replaceAll(" ", "") === "apenet" ?
                                                    amountUnit(value, filterRange) : formatValue(value)
                                                }
                                            </span>
                                        </li>
                                    )
                                )
                            })}
                        </ul>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul className='acc-list'>
                            {Object.entries(item).map(([key, value], i) => {
                                return (
                                    (key === "t-1" || key === "ftd" || key === "mtdgrossftd" || key === "mtdnetissuance" || key === "mtdnetshortfall"
                                        || key === "ytdtarget" || key === "ytdach" || key === "ytdshortfall" || key === "lysm%") && (
                                        <li li className='acc-item' >
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                {key === "lysm%" || key === "lm%" ? <div className={`mod-tag ${value <= 85 ? "down" : ""} ${value > 85 && value < 95 ? "medium" : ""}`}>
                                                    <span className='value'>
                                                        {formatValue(value)}%
                                                    </span>
                                                    <span className='icon icon-trending'></span>
                                                </div> : (cardName.toLowerCase() === "premium" || cardName.toLowerCase().replaceAll(" ", "") === "apegross" || cardName.toLowerCase().replaceAll(" ", "") === "apenet" || cardName.toLowerCase() === "cp" || cardName.toLowerCase().replaceAll(' ', '') === "productmix-ape") ?
                                                    amountUnit(value, filterRange) : formatValue(value)
                                                }
                                            </span>
                                        </li>
                                    )
                                )
                            })}
                            {item.details == "Grand Total" || item.details == "Group NB" || item.details == "Total Retail" ?
                                <></> :
                                <li className='acc-item action-item'>
                                    <Button className="btn btn-link" onClick={() => handleCompAdvisorClick(item.agentcode, item.agentname, item.details)}>View</Button>
                                </li>
                            }
                        </ul>
                    </AccordionDetails>
                </Accordion>

            ))
            }
            <div className='pagination-wrap'>
                <div className='left-wrap'>
                    <IconButton disableRipple className='btn btn-search' onClick={handlePrev} disabled={currentPage === 0}>
                        <span className='icon icon-prev'></span>
                    </IconButton>
                    <IconButton disableRipple className='btn btn-search' onClick={handleNext} disabled={currentPage === totalPages - 1}>
                        <span className='icon icon-next'></span>
                    </IconButton>
                </div>
                <div className='right-wrap'>
                    <span>Page</span>
                    <span>{currentPage + 1}</span>
                    <span>of {totalPages}</span>
                </div>
            </div>
        </div >

    )
}

export default CompanyGridData