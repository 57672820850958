import React, { useState, useEffect } from 'react';
import loadIcon from '../../../common/assets/images/favicon.png'
import loadingImg from '../../../common/assets/images/three-dots.svg'
import Grid from "@mui/material/Grid";
import PremiumTable from './components/PremiumTable'
import BarChart from './components/BarChart'
import { formatDate } from '../../../hooks/common/useDateFormat';
import 'flatpickr/dist/flatpickr.min.css';
import Button from '@mui/material/Button';
import { useFetchData } from '../../../hooks/common/useApiService';
import { amountUnit } from '../../../utils/helper';
import CollapsibleTable from './components/CollapsibleTable';
import RupeesFilter from '../../../common/components/RupeesFilter';
import { useFilterRange } from '../../../store/generalStore';

function WIP() {
    const fetchData = useFetchData();
    const [data, setData] = useState(null);
    const [token, setToken] = useState(null);
    const [pendingReportData, setPendingReportData] = useState(null);
    const [loginDetailsData, setLoginDetailsData] = useState(null);
    const [lastPIVCData, setLastPIVCData] = useState(null);
    const [tatData, setTatData] = useState(null);
    const [pendingTatData, setPendingTatData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [wipSummaryData, setWipSummaryData] = useState(null);
    const [advisorcode, setAdvisorcode] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingWipSummary, setLoadingWipSummary] = useState(true);
    const [hideSection, setHideSection] = useState(false);


    useEffect(() => {
        setAdvisorcode(localStorage.getItem('userId'))

        const loginData = JSON.stringify({ "token": "wip_premium_detail" });
        const loginHeaders = {
            'x-token-key': 'BZz8VIdg39p0OxT',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
            'Content-Type': 'application/json'
        };
        fetchData('post', process.env.REACT_APP_TOKEN, loginData, loginHeaders)
            .then((response) => {
                setToken(response.data?.token)

                setAdvisorcode(localStorage.getItem('userId'))
            })
    }, []);

    useEffect(() => {
        if (advisorcode && localStorage.getItem('userId')) {
            const newData = JSON.stringify({ "token": "wip_approval_medical_summary" });
            const newHeaders = {
                'x-token-key': 'BZz8VIdg39p0OxT',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
                'Content-Type': 'application/json',
                "agent_id": advisorcode
            };
            fetchData('post', process.env.REACT_APP_TOKEN, newData, newHeaders)
                .then((response) => {
                    const wipSummary = response.data?.token;
                    const payloadData = JSON.stringify({
                        "date_column": formatDate(),
                        "agent_id": advisorcode
                    });
                    fetchData('post', process.env.REACT_APP_WIP_APPROVAL_SUMMARY, payloadData, null, wipSummary)
                        .then((response) => {
                            setWipSummaryData(response?.data);
                            setLoadingWipSummary(false);
                        })
                })
        }
        if (advisorcode && localStorage.getItem('userId')) {
            const newData = JSON.stringify({ "token": "wip_card" });
            const newHeaders = {
                'x-token-key': 'BZz8VIdg39p0OxT',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
                'Content-Type': 'application/json',
                "agent_id": advisorcode
            };
            fetchData('post', process.env.REACT_APP_TOKEN, newData, newHeaders)
                .then((response) => {
                    const tokenCard = response.data?.token
                    const payloadData = JSON.stringify({
                        "date_column": formatDate(),
                        "agent_id": advisorcode
                    });
                    fetchData('post', process.env.REACT_APP_WIP_CARD, payloadData, null, tokenCard)
                        .then((response) => {
                            setData(response?.data);
                            setLoading(false);
                        })
                })
        }
        if (advisorcode && localStorage.getItem('userId')) {
            const newData = JSON.stringify({ "token": "wip_pending_for_report" });
            const newHeaders = {
                'x-token-key': 'BZz8VIdg39p0OxT',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
                'Content-Type': 'application/json',
                "agent_id": advisorcode
            };
            fetchData('post', process.env.REACT_APP_TOKEN, newData, newHeaders)
                .then((response) => {
                    const pendingReport = response.data?.token
                    const payloadData = JSON.stringify({
                        "date_column": formatDate(),
                        "agent_id": advisorcode
                    });
                    fetchData('post', process.env.REACT_APP_WIP_PENDING_REPORT, payloadData, null, pendingReport)
                        .then((response) => {
                            setPendingReportData(response?.data?.card);
                            setLoading(false);
                        })
                })
        }
        if (advisorcode && localStorage.getItem('userId')) {
            const newData = JSON.stringify({ "token": "wip_login_details" });
            const newHeaders = {
                'x-token-key': 'BZz8VIdg39p0OxT',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
                'Content-Type': 'application/json',
                "agent_id": advisorcode
            };
            fetchData('post', process.env.REACT_APP_TOKEN, newData, newHeaders)
                .then((response) => {
                    const loginDetails = response.data?.token
                    const payloadData = JSON.stringify({
                        "date_column": formatDate(),
                        "agent_id": advisorcode
                    });
                    fetchData('post', process.env.REACT_APP_WIP_DETAILS, payloadData, null, loginDetails)
                        .then((response) => {
                            setLoginDetailsData(response?.data?.card);
                            setLoading(false);
                        })
                })
        }
        if (advisorcode && localStorage.getItem('userId')) {
            const newData = JSON.stringify({ "token": "wip_last_pivc_date" });
            const newHeaders = {
                'x-token-key': 'BZz8VIdg39p0OxT',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
                'Content-Type': 'application/json',
                "agent_id": advisorcode
            };
            fetchData('post', process.env.REACT_APP_TOKEN, newData, newHeaders)
                .then((response) => {
                    const lastPIVCDate = response.data?.token
                    const payloadData = JSON.stringify({
                        "date_column": formatDate(),
                        "agent_id": advisorcode
                    });
                    fetchData('post', process.env.REACT_APP_WIP_LAST_PIVC_DATE, payloadData, null, lastPIVCDate)
                        .then((response) => {
                            setLastPIVCData(response?.data?.card);
                            setLoading(false);
                        })
                })
        }
        if (advisorcode && localStorage.getItem('userId')) {
            const newData = JSON.stringify({ "token": "wip_tat" });
            const newHeaders = {
                'x-token-key': 'BZz8VIdg39p0OxT',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
                'Content-Type': 'application/json',
                "agent_id": advisorcode
            };
            fetchData('post', process.env.REACT_APP_TOKEN, newData, newHeaders)
                .then((response) => {
                    const tatDate = response.data?.token
                    const payloadData = JSON.stringify({
                        "date_column": formatDate(),
                        "agent_id": advisorcode
                    });
                    fetchData('post', process.env.REACT_APP_WIP_TAT, payloadData, null, tatDate)
                        .then((response) => {
                            setTatData(response?.data?.card);
                            setLoading(false);
                        })
                })
        }
        if (advisorcode && localStorage.getItem('userId')) {
            const newData = JSON.stringify({ "token": "wip_pending_tat" });
            const newHeaders = {
                'x-token-key': 'BZz8VIdg39p0OxT',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
                'Content-Type': 'application/json',
                "agent_id": advisorcode
            };
            fetchData('post', process.env.REACT_APP_TOKEN, newData, newHeaders)
                .then((response) => {
                    const pendingTatDate = response.data?.token
                    const payloadData = JSON.stringify({
                        "date_column": formatDate(),
                        "agent_id": advisorcode
                    });
                    fetchData('post', process.env.REACT_APP_WIP_PENDING_TAT, payloadData, null, pendingTatDate)
                        .then((response) => {
                            setPendingTatData(response?.data?.card);
                            setLoading(false);
                        })
                })
        }
        if (advisorcode && localStorage.getItem('userId')) {
            const newData = JSON.stringify({ "token": "wip_tab_chart" });
            const newHeaders = {
                'x-token-key': 'BZz8VIdg39p0OxT',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
                'Content-Type': 'application/json',
                "agent_id": advisorcode
            };
            fetchData('post', process.env.REACT_APP_TOKEN, newData, newHeaders)
                .then((response) => {
                    const tabChart = response.data?.token
                    const payloadData = JSON.stringify({
                        "date_column": formatDate(),
                        "agent_id": advisorcode
                    });
                    fetchData('post', process.env.REACT_APP_WIP_CHART, payloadData, null, tabChart)
                        .then((response) => {
                            setChartData(response?.data?.[0]);
                            setLoading(false);
                        })
                })
        }
    }, [advisorcode])

    const filterRange = useFilterRange((state) => state.filterRange);

    const handleHideSection = () => {
        setHideSection(!hideSection)
    }

    const [summaryFilter, setSummaryFilter] = useState('ape');

    const handleSummaryFilter = (event) => {
        setSummaryFilter(event.target.value);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFilterClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItemClick = (value) => {
        setSummaryFilter(value)
        handleFilterClose();
    };
    return (
        <>
            <div className='switch-wrap'>
                <RupeesFilter />
            </div>
            <Grid
                container
                rowSpacing={{ md: 5, xs: 3 }}
                columnSpacing={{ md: 4, xs: 3 }}
            >
                {loadingWipSummary ?
                    [null, null]?.map((iem, index) => {
                        return (
                            <Grid item md={12} sm={12} xs={12} key={index}>
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            </Grid>

                        )
                    }) :
                    wipSummaryData?.map((item, index) => (
                        <Grid item md={12} sm={12} xs={12} key={index}>
                            <CollapsibleTable summaryData={item} filterRange={filterRange} summaryFilter={summaryFilter} handleSummaryFilter={handleSummaryFilter} handleFilterClick={handleFilterClick} open={open} handleFilterClose={handleFilterClose} anchorEl={anchorEl} handleMenuItemClick={handleMenuItemClick} />
                        </Grid>
                    ))
                }

                {hideSection && <>
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid
                            container
                            rowSpacing={{ md: 3, xs: 2 }}
                            columnSpacing={{ md: 3, xs: 2 }}
                        >
                            {loading ?
                                [null, null, null, null]?.map((iem, index) => {
                                    return (
                                        <Grid item md={6} sm={12} xs={12} key={index}>
                                            {loading ? (
                                                <div className='cm-loader'>
                                                    <div className='load-img'>
                                                        <img src={loadIcon} alt='loader' />
                                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )

                                            }
                                        </Grid>

                                    )
                                })
                                :
                                data && (data?.map((item, index) => (
                                    index === 2 ? (
                                        <Grid item md={12} sm={12} xs={12} key={index}>

                                            <div className={`bs-card ${item?.bgiconcolor}`}>
                                                <div className='head-wrap'>
                                                    <div className='title-wrap'>
                                                        <div className='icon-wrap'>
                                                            <span className={`icon icon-${item?.iconvalue}`}></span>
                                                        </div>
                                                        <h2 className='title'>
                                                            {item?.subtitle &&
                                                                <span>{item?.subtitle}</span>
                                                            }
                                                            {item?.title}</h2>
                                                    </div>
                                                </div>
                                                <div className='cont-wrap'>
                                                    <Grid
                                                        container
                                                        rowSpacing={{ md: 1.5, xs: 1.5 }}
                                                        columnSpacing={{ md: 1.5, xs: 1.5 }}
                                                    >
                                                        {item?.columnname?.map((col, index) => (
                                                            <Grid item lg={3} md={6} xs={6} key={index}>
                                                                <div className='mod-box'>
                                                                    <h2 className='title'>{col?.displayName}</h2>
                                                                    <div className='info-wrap'>
                                                                        <p className='val'>
                                                                            {
                                                                                item?.columndata && item.columndata[0][col.colname]?.value
                                                                                    ?
                                                                                    amountUnit(item.columndata[0][col.colname]?.value, filterRange)
                                                                                    :
                                                                                    amountUnit(item.columndata[0][col.colname], filterRange)
                                                                            }
                                                                        </p>
                                                                        {
                                                                            item?.columndata && item.columndata[0][col.colname]?.percentage && (
                                                                                <div className={`mod-tag ${item.columndata[0][col.colname].percentage < 0 ? "down" : ""}`}>
                                                                                    <span className='value'>{item.columndata[0][col.colname].percentage}</span>
                                                                                    <span className='icon icon-trending'></span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        ))}

                                                    </Grid>

                                                </div>
                                            </div>
                                        </Grid>
                                    ) : (
                                        <Grid item md={6} sm={12} xs={12} key={index}>
                                            <div className={`bs-card ${item?.bgiconcolor}`}>
                                                <div className='head-wrap'>
                                                    <div className='title-wrap'>
                                                        <div className='icon-wrap'>
                                                            <span className={`icon icon-${item?.iconvalue}`}></span>
                                                        </div>
                                                        <h2 className='title'>
                                                            {item?.subtitle &&
                                                                <span>{item?.subtitle}</span>
                                                            }
                                                            {item?.title}</h2>
                                                    </div>
                                                </div>
                                                <div className='cont-wrap'>
                                                    <Grid
                                                        container
                                                        rowSpacing={{ md: 1.5, xs: 1.5 }}
                                                        columnSpacing={{ md: 1.5, xs: 1.5 }}
                                                    >
                                                        {index === 1 ? (
                                                            item?.columnname?.map((col, index) => (
                                                                <Grid item lg={index === 0 || index === 1 ? 6 : 4} md={6} xs={6} key={index}>
                                                                    <div className='mod-box'>
                                                                        <h2 className='title'>{col?.displayName}</h2>
                                                                        <div className='info-wrap'>
                                                                            <p className='val'>
                                                                                {
                                                                                    item?.columndata && item.columndata[0][col.colname]?.value
                                                                                        ?
                                                                                        amountUnit(item.columndata[0][col.colname]?.value, filterRange)
                                                                                        :
                                                                                        amountUnit(item.columndata[0][col.colname], filterRange)
                                                                                }
                                                                            </p>
                                                                            {
                                                                                item?.columndata && item.columndata[0][col.colname]?.percentage && (
                                                                                    <div className={`mod-tag ${item.columndata[0][col.colname].percentage < 0 ? "down" : ""}`}>
                                                                                        <span className='value'>{item.columndata[0][col.colname].percentage}</span>
                                                                                        <span className='icon icon-trending'></span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            ))
                                                        ) : (
                                                            item?.columnname?.map((col, index) => (
                                                                <Grid item lg={4} md={6} xs={6} key={index}>
                                                                    <div className='mod-box'>
                                                                        <h2 className='title'>{col?.displayName}</h2>
                                                                        <div className='info-wrap'>
                                                                            <p className='val'>
                                                                                {
                                                                                    item?.columndata && item.columndata[0][col.colname]?.value
                                                                                        ?
                                                                                        amountUnit(item.columndata[0][col.colname]?.value, filterRange)
                                                                                        :
                                                                                        amountUnit(item.columndata[0][col.colname], filterRange)
                                                                                }
                                                                            </p>
                                                                            {
                                                                                item?.columndata && item.columndata[0][col.colname]?.percentage && (
                                                                                    <div className={`mod-tag ${item.columndata[0][col.colname].percentage < 0 ? "down" : ""}`}>
                                                                                        <span className='value'>{item.columndata[0][col.colname].percentage}</span>
                                                                                        <span className='icon icon-trending'></span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            ))
                                                        )}


                                                    </Grid>

                                                </div>
                                            </div>
                                        </Grid>
                                    )

                                ))
                                )
                            }
                        </Grid>
                    </Grid >
                    <Grid item md={12} sm={12} xs={12}>
                        <div className='bs-panel typ-graph'>
                            <div className='panel-head'>
                                <div className='left-wrap'>
                                    <h2 className='p-title'>{chartData?.title}</h2>
                                </div>
                            </div>
                            <div className='panel-content'>
                                {loading ? (
                                    <div className='cm-loader'>
                                        <div className='load-img'>
                                            <img src={loadIcon} alt='loader' />
                                            <img src={loadingImg} alt='loading' className='loading-gif' />
                                        </div>
                                    </div>
                                ) : (
                                    <BarChart chartData={chartData?.datasets} filterRange={filterRange} />
                                )}
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <div className='bs-panel'>
                            <div className='panel-head'>
                                <div className='left-wrap'>
                                    <h2 className='p-title'>{pendingReportData?.[0]?.name}</h2>
                                </div>
                            </div>
                            <div className='panel-content'>
                                {loading ? (
                                    <div className='cm-loader'>
                                        <div className='load-img'>
                                            <img src={loadIcon} alt='loader' />
                                            <img src={loadingImg} alt='loading' className='loading-gif' />
                                        </div>
                                    </div>
                                ) : (
                                    <div className='bs-table typ-persistency typ-wip'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    {
                                                        pendingReportData?.[0]?.columnname.map((colName, index) => (colName?.colname !== "id" && <th>{colName?.displayName}</th>))
                                                    }

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pendingReportData?.[0]?.detail?.map((item, index) =>
                                                (
                                                    <tr>
                                                        {
                                                            pendingReportData?.[0]?.columnname.map((colName, index) => (colName?.colname !== "id" &&
                                                                <td>
                                                                    {isNaN(item?.[colName?.colname]) ? item?.[colName?.colname] :
                                                                        amountUnit(item?.[colName?.colname], filterRange)

                                                                    }
                                                                </td>))
                                                        }
                                                    </tr>
                                                )
                                                )}

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    {pendingReportData?.[0]?.["grand total"]?.map((item, index) =>
                                                    (
                                                        pendingReportData?.[0]?.columnname.map((colName, index) => (colName?.colname !== "id" &&
                                                            <td key={index}>
                                                                {isNaN(item?.[colName?.colname]) ? item?.[colName?.colname] :
                                                                    amountUnit(item?.[colName?.colname], filterRange)
                                                                }
                                                            </td>))
                                                    )
                                                    )}
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                )}

                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <div className='bs-panel'>
                            <div className='panel-head'>
                                <div className='left-wrap'>
                                    <h2 className='p-title'>{pendingTatData?.[0]?.name}</h2>
                                </div>
                            </div>
                            <div className='panel-content'>
                                {loading ? (
                                    <div className='cm-loader'>
                                        <div className='load-img'>
                                            <img src={loadIcon} alt='loader' />
                                            <img src={loadingImg} alt='loading' className='loading-gif' />
                                        </div>
                                    </div>
                                ) : (
                                    <div className='bs-table typ-persistency typ-wip typ-height-equal'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    {
                                                        pendingTatData?.[0]?.columnname.map((colName, index) => (colName?.colname !== "id" && <th>{colName?.displayName}</th>))
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pendingTatData?.[0]?.detail?.map((item, index) =>
                                                (
                                                    <tr>
                                                        {
                                                            pendingTatData?.[0]?.columnname.map((colName, colIndex) => (
                                                                colName?.colname !== "id" &&
                                                                (item[colName?.colname].colvalue !== undefined ?
                                                                    <td className={`${item?.[colName?.colname]?.highlight !== null ? item?.[colName?.colname]?.highlight : ""}`}>
                                                                        {
                                                                            amountUnit(item?.[colName?.colname]?.colvalue, filterRange)
                                                                        }
                                                                    </td>
                                                                    :
                                                                    <td>{item[colName?.colname]}</td>)
                                                            ))
                                                        }

                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    {pendingTatData?.[0]?.["grand total"]?.map((item, index) =>
                                                    (
                                                        pendingTatData?.[0]?.columnname.map((colName, colIndex) => (
                                                            colName?.colname !== "id" && colName?.colname !== "substatus" &&
                                                            (item[colName?.colname].colvalue !== undefined ?
                                                                <td className={`${item?.[colName?.colname]?.highlight !== null ? item?.[colName?.colname]?.highlight : ""}`}>
                                                                    {
                                                                        amountUnit(item?.[colName?.colname]?.colvalue, filterRange)
                                                                    }
                                                                </td>
                                                                :
                                                                (colIndex === 1 ? <td colSpan={2}>{item[colName?.colname]}</td> : <td>{item[colName?.colname]}</td>)
                                                            )
                                                        ))
                                                    )
                                                    )}
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                )}

                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>

                        <div className='bs-panel'>
                            <div className='panel-head'>
                                <div className='left-wrap'>
                                    <h2 className='p-title'>{loginDetailsData?.[0]?.name}</h2>
                                </div>
                            </div>
                            <div className='panel-content'>
                                {loading ? (
                                    <div className='cm-loader'>
                                        <div className='load-img'>
                                            <img src={loadIcon} alt='loader' />
                                            <img src={loadingImg} alt='loading' className='loading-gif' />
                                        </div>
                                    </div>
                                ) : (
                                    <div className='bs-table typ-persistency typ-wip typ-height-equal'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    {
                                                        loginDetailsData?.[0]?.columnname.map((colName, index) => (colName?.colname !== "id" && <th>{colName?.displayName}</th>))
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    loginDetailsData?.[0]?.detail?.map((item, rowIndex) => (
                                                        <tr key={rowIndex}>
                                                            {
                                                                loginDetailsData?.[0]?.columnname.map((colName, colIndex) => (
                                                                    colName?.colname !== "id" &&
                                                                    (item[colName?.colname].colvalue !== undefined ?
                                                                        <td className={`${item?.[colName?.colname]?.highlight !== null ? item?.[colName?.colname]?.highlight : ""}`}>
                                                                            {
                                                                                amountUnit(item?.[colName?.colname]?.colvalue, filterRange)
                                                                            }
                                                                        </td>
                                                                        :
                                                                        <td>{item[colName?.colname]}</td>)
                                                                ))
                                                            }
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    {
                                                        loginDetailsData?.[0]?.["grand total"]?.map((item, rowIndex) => (
                                                            loginDetailsData?.[0]?.columnname.map((colName, colIndex) => (
                                                                colName?.colname !== "id" && colName?.colname !== "substatus" &&
                                                                (item[colName?.colname].colvalue !== undefined ?
                                                                    <td className={`${item?.[colName?.colname]?.highlight !== null ? item?.[colName?.colname]?.highlight : ""}`}>
                                                                        {
                                                                            amountUnit(item?.[colName?.colname]?.colvalue, filterRange)
                                                                        }
                                                                    </td>
                                                                    :
                                                                    (colIndex === 1 ? <td colSpan={2}>{item[colName?.colname]}</td> : <td>{item[colName?.colname]}</td>)
                                                                )
                                                            ))
                                                        ))
                                                    }
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                )}

                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>

                        <div className='bs-panel'>
                            <div className='panel-head'>
                                <div className='left-wrap'>
                                    <h2 className='p-title'>{lastPIVCData?.[0]?.name}</h2>
                                </div>
                            </div>
                            <div className='panel-content'>
                                {loading ? (
                                    <div className='cm-loader'>
                                        <div className='load-img'>
                                            <img src={loadIcon} alt='loader' />
                                            <img src={loadingImg} alt='loading' className='loading-gif' />
                                        </div>
                                    </div>
                                ) : (
                                    <div className='bs-table typ-persistency typ-wip typ-height-equal'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    {
                                                        lastPIVCData?.[0]?.columnname.map((colName, index) => (colName?.colname !== "id" && <th>{colName?.displayName}</th>))
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {lastPIVCData?.[0]?.detail?.map((item, index) =>
                                                (
                                                    <tr key={index}>
                                                        {
                                                            lastPIVCData?.[0]?.columnname.map((colName, colIndex) => (
                                                                colName?.colname !== "id" &&
                                                                (item[colName?.colname].colvalue !== undefined ?
                                                                    <td className={`${item?.[colName?.colname]?.highlight !== null ? item?.[colName?.colname]?.highlight : ""}`}>
                                                                        {
                                                                            amountUnit(item?.[colName?.colname]?.colvalue, filterRange)
                                                                        }
                                                                    </td>
                                                                    :
                                                                    <td>{item[colName?.colname]}</td>)
                                                            ))
                                                        }
                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    {lastPIVCData?.[0]?.["grand total"]?.map((item, index) =>
                                                    (
                                                        lastPIVCData?.[0]?.columnname.map((colName, colIndex) => (
                                                            colName?.colname !== "id" &&
                                                            (item[colName?.colname].colvalue !== undefined ?
                                                                <td className={`${item?.[colName?.colname]?.highlight !== null ? item?.[colName?.colname]?.highlight : ""}`}>
                                                                    {
                                                                        amountUnit(item?.[colName?.colname]?.colvalue, filterRange)
                                                                    }
                                                                </td>
                                                                :
                                                                <td>{item[colName?.colname]}</td>)
                                                        ))
                                                    )
                                                    )}
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                )}

                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <div className='bs-panel'>
                            <div className='panel-head'>
                                <div className='left-wrap'>
                                    <h2 className='p-title'>{tatData?.[0]?.name}</h2>
                                </div>
                            </div>
                            <div className='panel-content'>
                                {loading ? (
                                    <div className='cm-loader'>
                                        <div className='load-img'>
                                            <img src={loadIcon} alt='loader' />
                                            <img src={loadingImg} alt='loading' className='loading-gif' />
                                        </div>
                                    </div>
                                ) : (
                                    <div className='bs-table typ-persistency typ-wip typ-height-equal'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    {
                                                        tatData?.[0]?.columnname.map((colName, index) => (colName?.colname !== "id" && <th>{colName?.displayName}</th>))
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tatData?.[0]?.detail?.map((item, index) =>
                                                (
                                                    <tr>
                                                        {
                                                            tatData?.[0]?.columnname.map((colName, colIndex) => (
                                                                colName?.colname !== "id" &&
                                                                (item[colName?.colname].colvalue !== undefined ?
                                                                    <td className={`${item?.[colName?.colname]?.highlight !== null ? item?.[colName?.colname]?.highlight : ""}`}>
                                                                        {
                                                                            amountUnit(item?.[colName?.colname]?.colvalue, filterRange)
                                                                        }
                                                                    </td>
                                                                    :
                                                                    <td>{item[colName?.colname]}</td>)
                                                            ))
                                                        }


                                                    </tr>
                                                )
                                                )}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    {tatData?.[0]?.["grand total"]?.map((item, index) =>
                                                    (
                                                        tatData?.[0]?.columnname.map((colName, colIndex) => (
                                                            colName?.colname !== "id" && colName?.colname !== "substatus" &&
                                                            (item[colName?.colname].colvalue !== undefined ?
                                                                <td className={`${item?.[colName?.colname]?.highlight !== null ? item?.[colName?.colname]?.highlight : ""}`}>
                                                                    {
                                                                        amountUnit(item?.[colName?.colname]?.colvalue, filterRange)
                                                                    }
                                                                </td>
                                                                :
                                                                (colIndex === 1 ? <td colSpan={2}>{item[colName?.colname]}</td> : <td>{item[colName?.colname]}</td>)
                                                            )
                                                        ))
                                                    )
                                                    )}
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                )}

                            </div>
                        </div>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        {loading ? (
                            <div className='cm-loader'>
                                <div className='load-img'>
                                    <img src={loadIcon} alt='loader' />
                                    <img src={loadingImg} alt='loading' className='loading-gif' />
                                </div>
                            </div>
                        ) : (
                            <PremiumTable advisorcode={advisorcode} setAdvisorcode={setAdvisorcode} filterRange={filterRange} token={token} />
                        )}
                    </Grid>
                </>
                }
                <Grid item md={12} sm={12} xs={12} >
                    <div className='cm-align-center'>
                        <Button className="btn btn-link" onClick={handleHideSection}>{hideSection ? "View Less" : "View More"}</Button>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default WIP