import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import useTableFormat from '../../../../../hooks/common/useTableFormat';
import useSize from '../../../../../hooks/common/useSize'
import { useFetchData } from '../../../../../hooks/common/useApiService';
import { dateRangeFormat } from '../../../../../hooks/common/useDateRangeFormat';

import loadIcon from '../../../../../common/assets/images/favicon.png'
import loadingImg from '../../../../../common/assets/images/three-dots.svg'

import { amountUnit } from '../../../../../utils/helper';

import Pagination from '@mui/material/Pagination';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
    Checkbox,
    ListItemText,
} from '@mui/material';

// import { useNavigate, useLocation } from 'react-router-dom';

import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import BAPDGridData from '../BAPDGridData';


function BAPDPremiumTable({ cardName, filterRange, advisorcode, setAdvisorcode, dateValue, token }) {
    const fetchData = useFetchData();
    const winDimensions = useSize();
    const [data, setData] = useState(null);
    const [columnsData, setColumnsData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(0);
    let pageSize = 7;

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        var payloadData = JSON.stringify({
            "start_date": dateRangeFormat(dateValue[0]),
            "end_date": dateRangeFormat(dateValue[1]),
            "agent_id": advisorcode
        });

        setLoading(true)
        setPage(0)


        fetchData('post', process.env.REACT_APP_ISSUANCE_LOGIN_DETAILS_BAPD, payloadData, null, token)?.then((response) => {
            var cardData = response.data
            if (cardName.toLowerCase() === "premium") {
                cardData = response.data.ape
            } else {
                cardData = response.data[`${cardName.toLowerCase().replaceAll(' ', '')}`]
            }
            setData(cardData)
            setFilteredData(cardData?.details);
            const colData = (cardData?.columnname ?? []).map(col => {
                if (col && col.colname && col.colname !== 'id') {
                    return {
                        field: col.colname,
                        headerName: col.displayName,
                        width: col.dWidth,
                    };
                }
                return null;
            }).filter(Boolean);
            colData.push({
                field: 'action',
                headerName: 'Action',
                width: 150,
                renderCell: (params) => <Button className="btn btn-link">View</Button>,
            });

            setColumnsData(colData)
            setLoading(false)
        })
    }, [cardName, advisorcode, dateValue, token]);



    const handleChangePage = (event, newPage) => {
        setPage(newPage - 1);
    };
    const [searchShow, setSearchShow] = React.useState(false)
    const handleSearch = () => {
        setSearchShow(true)
    }

    const { divRef, columns } = useTableFormat(columnsData);

    const CustomCellRenderer = ({ value, column, row }) => {
        if (cardName.toLowerCase() === "premium" || cardName.toLowerCase() === "ape" || cardName.toLowerCase().replaceAll(" ", "") === "productmix" || cardName.toLowerCase() === "wfyp") {
            if (column.field === 'ftd' || column.field === 'mtd' || column.field === 'mtdplan' || column.field === 'ytd' || column.field === 'ytdplan' || column.field === 'apemtd' || column.field === 'cpmtd') {
                return (
                    <div>
                        {amountUnit(value, filterRange)}
                    </div>
                );
            }
        }
        if (column.field === 'mtd%' || column.field === 'ytd%') {
            return (
                <div className={`mod-tag ${value <= 85 ? "down" : ""} ${value > 85 && value < 95 ? "medium" : ""}`}>
                    <span className='value'>{value}</span>
                    <span className='icon icon-trending'></span>
                </div>
            )

        }
        if (column.field === 'action') {
            return (
                <Button className="btn btn-link" onClick={() => handleAdvisorClick(row.busscode, row.empname)}>View</Button>
            );
        }
        if (column.field === 'busscode' || column.field === 'empcode' || column.field === 'reportingmanager') {
            return <div data-th={column?.headerName || ''}>{value}</div>;
        }
        return <div data-th={column?.headerName || ''}>{Number(value) ? Number(value).toLocaleString() : value}</div>;
    };



    const handleFilterSearch = (event, value) => {
        setSearchTerm(value);
        if (value === "") {
            setFilteredData(data?.details || []);
        } else {
            const lowercasedValue = value.toLowerCase();
            const filtered = data?.details.filter((item) =>
                Object.keys(item).some((key) =>
                    item[key]?.toString().toLowerCase().includes(lowercasedValue)
                )
            );
            setFilteredData(filtered);
            setPage(0)
        }
    };

    const handleSearchClear = () => {
        setSearchTerm("")
        setFilteredData(data?.details || []);
        setSearchShow(false)
    }

    const calculateColumnWidths = (data, pageWidth) => {
        const totalWidth = columns.reduce((acc, col) => acc + col.width, 0);
        const scaleFactor = pageWidth / totalWidth;

        return columns.map(col => ({
            headerName: col.headerName,
            field: col.field,
            width: col.width * scaleFactor,
        }));
    };


    const handleExport = (format) => {
        if (format === 'excel') {
            const ws = XLSX.utils.json_to_sheet(data?.details);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Data');
            XLSX.writeFile(wb, 'data.xlsx');
        } else if (format === 'pdf') {
            const doc = new jsPDF({ orientation: 'landscape' });
            const pageWidth = doc.internal.pageSize.getWidth() - 20;
            const scaledColumns = calculateColumnWidths(data?.details, pageWidth);



            doc.autoTable({
                head: [scaledColumns.map(col => col.headerName)],
                body: data?.details?.map(row => scaledColumns.map(col => row[col.field])),
                columnStyles: scaledColumns.reduce((acc, col, i) => ({
                    ...acc, [i]: { cellWidth: col.width }
                }), {}),
            });
            doc.save('data.pdf');
        }
    };


    const handleAdvisorClick = (code, name) => {
        const drillDown = localStorage.getItem("drillDown");
        const dataArray = drillDown ? JSON.parse(drillDown) : []
        const currDrillDown = { "code": code, "name": name }
        const newArray = [...dataArray, currDrillDown];
        localStorage.setItem("drillDown", JSON.stringify(newArray))
        const getLength = newArray.length - 1;
        setAdvisorcode(newArray[getLength].code)
        window.scrollTo(0, 0)
    }

    const hideColumns = ["empcode", "shortdesignation", "reportingmanager", "branchlocation", "zone", "vertical"]
    const [hiddenColumns, setHiddenColumns] = useState(hideColumns);
    const [checkedColumns, setCheckedColumns] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState([]);
    const [isSelectAll, setIsSelectAll] = useState(false);

    const handleColumnToggle = (event) => {
        const value = event.target.value;

        if (value.includes('select_all')) {
            const allColumns = hideColumns;
            if (isSelectAll) {
                setCheckedColumns([]);
                setHiddenColumns(allColumns);
                setIsSelectAll(false);
            } else {
                setCheckedColumns(allColumns);
                setHiddenColumns([]);
                setIsSelectAll(true);
            }
        } else {
            setCheckedColumns(typeof value === 'string' ? value.split(',') : value);
            const getNewHiddenColumns = hideColumns.filter(column => !value.includes(column));
            setHiddenColumns(getNewHiddenColumns);
            setIsSelectAll(value.length === hideColumns.length);
            let newVisibleColumns = columnsData.filter((col) => !getNewHiddenColumns.includes(col.field));
            setVisibleColumns(newVisibleColumns);
        }
    };

    useEffect(() => {
        let newVisibleColumns = columns.filter((col) => !hiddenColumns.includes(col.field));
        setVisibleColumns(newVisibleColumns);
    }, [columns, hiddenColumns]);


    return (
        <>
            <div className={`bs-panel typ-table typ-auto ${data?.headingcolour} ${data?.bgcolor}`}>
                <div className='panel-head'>
                    <div className='left-wrap'>
                        <h2 className='p-title'>{data?.name}</h2>
                    </div>
                    <div className='right-wrap'>
                        <div className={`bs-search  ${searchShow ? 'active' : ''}`}>
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                open={false}
                                value={searchTerm}
                                options={filteredData?.flatMap(Object.values).map(value => value?.toString())}
                                onInputChange={handleFilterSearch}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        InputProps={{
                                            ...params.InputProps,

                                            endAdornment: (
                                                <InputAdornment position="end" onClick={handleSearchClear}>
                                                    <span className={`icon icon-${searchTerm === "" ? "search" : "close"}`}></span>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder='Search' />
                                )}
                                classes={{ paper: 'bs-menu-dropdown' }}

                            />
                        </div>
                        <div className='bs-select typ-sm typ-toggle cm-hidden-sm'>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    renderValue={(selected) => "Export"}
                                    onChange={(e) => handleExport(e.target.value)}
                                    IconComponent={(props) => (
                                        <span
                                            {...props}
                                            className={`icon-chevron-down ${props.className}`}
                                        ></span>
                                    )}
                                    MenuProps={{
                                        classes: {
                                            paper: "bs-menu-dropdown",
                                        },
                                    }}
                                    displayEmpty
                                >
                                    <MenuItem value="excel">Export to Excel</MenuItem>
                                    {/* <MenuItem value="pdf">Export to PDF</MenuItem> */}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='bs-select typ-sm cm-hidden-sm'>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={checkedColumns}
                                    onChange={handleColumnToggle}
                                    renderValue={(selected) => "Toggle"}
                                    multiple
                                    IconComponent={(props) => (
                                        <span
                                            {...props}
                                            className={`icon-chevron-down ${props.className}`}
                                        ></span>
                                    )}
                                    MenuProps={{
                                        classes: {
                                            paper: "bs-menu-dropdown typ-toggle",
                                        },
                                    }}
                                    displayEmpty
                                >
                                    <MenuItem key="select_all" value="select_all">
                                        <Checkbox checked={isSelectAll} />
                                        <ListItemText primary="Select All" />
                                    </MenuItem>
                                    {columnsData
                                        .filter((column1) => ["empcode", "shortdesignation", "reportingmanager", "branchlocation", "zone", "vertical"].includes(column1.field))
                                        .map((column) => {
                                            return (
                                                <MenuItem key={column.field} value={column.field}>
                                                    <Checkbox checked={checkedColumns.includes(column.field)} />
                                                    <ListItemText primary={column.headerName} />
                                                </MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='cm-hidden-md'>
                            <IconButton disableRipple className='btn btn-search' onClick={handleSearch}>
                                <span className='icon icon-search'></span>
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className='panel-content' ref={divRef}>
                    {winDimensions[0] <= 768 ?
                        (
                            loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (

                                <BAPDGridData data={data} filterRange={filterRange} cardName={cardName} advisorcode={advisorcode} setAdvisorcode={setAdvisorcode} filteredData={filteredData} />
                            )
                        ) : (
                            loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (
                                <DataGrid
                                    className='bs-data-table'
                                    rows={searchTerm ? filteredData.slice(page * pageSize, page * pageSize + pageSize) : data?.details?.length > 0 ? data?.details.slice(page * pageSize, page * pageSize + pageSize) : []}
                                    columns={visibleColumns.map(col => ({ ...col, renderCell: (params) => <CustomCellRenderer {...params} column={col} row={params.row} /> }))}
                                    pageSize={pageSize}
                                />
                            )
                        )}
                </div>
            </div>
            <div className='table-pagination'>
                <Pagination
                    count={Math.ceil(data?.details?.length / pageSize)}
                    page={page + 1}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                    className='bs-pagination'
                />
            </div>
        </>

    )
}

export default BAPDPremiumTable