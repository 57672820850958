import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import useTableFormat from '../../../../../hooks/common/useTableFormat';
import useSize from '../../../../../hooks/common/useSize'
import GridData from '../GridData';


function PremiumTable() {
    const winDimensions = useSize();
    const CustomCellRenderer = ({ value, column, row }) => {
        if (column.field === 'lysmPercentage') {
            if (value < 150) {
                return (
                    <div className='mod-tag down'>
                        <span className='value'>{value}%</span>
                        <span className='icon icon-trending'></span>
                    </div>
                );
            } else {
                return (
                    <div className='mod-tag'>
                        <span className='value'>{value}%</span>
                        <span className='icon icon-trending'></span>
                    </div>
                );
            }
        }
        if (column.field === 'ytdPercentage') {
            if (value < 150) {
                return (
                    <div className='mod-tag down'>
                        <span className='value'>{value}%</span>
                        <span className='icon icon-trending'></span>
                    </div>
                );
            } else {
                return (
                    <div className='mod-tag'>
                        <span className='value'>{value}%</span>
                        <span className='icon icon-trending'></span>
                    </div>
                );
            }
        }
        if (column.field === 'mtdPercentage') {
            if (value < 150) {
                return (
                    <div className='mod-tag down'>
                        <span className='value'>{value}%</span>
                        <span className='icon icon-trending'></span>
                    </div>
                );
            } else {
                return (
                    <div className='mod-tag'>
                        <span className='value'>{value}%</span>
                        <span className='icon icon-trending'></span>
                    </div>
                );
            }
        }
        if (column.field === 'ftd') {
            if (value > 15) {
                return (
                    <div className='mod-indicator'>
                        {value} <span className='icon icon-up' ></span>
                    </div>
                );
            } else {
                return (
                    <div className='mod-indicator'>
                        {value} <span className='icon icon-down' ></span>
                    </div>
                );
            }
        }
        if (column.field === 'action') {
            return (
                <Button className="btn btn-link">{value}</Button>
            );
        }

        if (column.field === 'advisorcode') {
            return <div data-th={column?.headerName || ''}>{value}</div>;
        }
        return <div data-th={column?.headerName || ''}>{Number(value) ? Number(value).toLocaleString() : value}</div>;
    };
    const columnsData = [
        {
            field: 'zones',
            headerName: 'Channel Name',
            width: 140,
        },
        {
            field: 'zoneManager',
            headerName: 'Channel Head Name',
            width: 220,
        },
        {
            field: 'ftd',
            headerName: 'FTD',
            width: 112,
        },
        {
            field: 'mtdPlan',
            headerName: 'MTD Plan',
            width: 128,
        },
        {
            field: 'mtdActual',
            headerName: 'MTD Actual',
            width: 128,
        },
        {
            field: 'mtdPercentage',
            headerName: 'MTD %',
            width: 152,
        },
        {
            field: 'lysm',
            headerName: 'LYSM',
            width: 112,
        },
        {
            field: 'lysmPercentage',
            headerName: 'LYSM %',
            width: 152,
        },
        {
            field: 'ytdPlan',
            headerName: 'YTD Plan',
            width: 141,
        },
        {
            field: 'ytdActual',
            headerName: 'YTD Actual',
            width: 154,
        },
        {
            field: 'ytdPercentage',
            headerName: 'YTD %',
            width: 184,
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 128,
        },
        // {
        //     field: 'fullName',
        //     headerName: 'Full name',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     width: 160,
        // type: 'number',

        // },
    ];

    const rows = [
        {
            id: 1,
            zones: 'Channel 1',
            zoneManager: 'Abhishek Acharya',
            ftd: '22.9',
            mtdPlan: '122.9',
            mtdActual: '305.8',
            mtdPercentage: '206.29',
            lysm: '12.9',
            lysmPercentage: '206.29',
            ytdPlan: '500.2',
            ytdActual: '500.2',
            ytdPercentage: '206.29',
            action: 'View',
        },
        {
            id: 2,
            zones: 'Channel 2',
            zoneManager: 'Praful Patel',
            ftd: '14.3',
            mtdPlan: '122.9',
            mtdActual: '305.8',
            mtdPercentage: '110.82',
            lysm: '12.9',
            lysmPercentage: '110.82',
            ytdPlan: '500.2',
            ytdActual: '500.2',
            ytdPercentage: '110.82',
            action: 'View',
        },
        {
            id: 3,
            zones: 'Retail Channel',
            zoneManager: 'Ahmed Hussain',
            ftd: '27.2',
            mtdPlan: '122.9',
            mtdActual: '305.8',
            mtdPercentage: '58.91',
            lysm: '12.9',
            lysmPercentage: '58.91',
            ytdPlan: '500.2',
            ytdActual: '500.2',
            ytdPercentage: '58.91',
            action: 'View',
        },
        {
            id: 4,
            zones: 'Channel Metro 1',
            zoneManager: 'Rakesh Rajagopal',
            ftd: '32.4',
            mtdPlan: '122.9',
            mtdActual: '305.8',
            mtdPercentage: '302.15',
            lysm: '12.9',
            lysmPercentage: '302.15',
            ytdPlan: '500.2',
            ytdActual: '500.2',
            ytdPercentage: '302.15',
            action: 'View',
        },
        {
            id: 5,
            zones: 'Channel Metro 2',
            zoneManager: 'Priyank Mehra',
            ftd: '21.4',
            mtdPlan: '122.9',
            mtdActual: '305.8',
            mtdPercentage: '120.77',
            lysm: '12.9',
            lysmPercentage: '120.77',
            ytdPlan: '500.2',
            ytdActual: '500.2',
            ytdPercentage: '120.77',
            action: 'View',
        },
        {
            id: 6,
            zones: 'Channel Metro 3',
            zoneManager: 'Vikram Dubey',
            ftd: '18.2',
            mtdPlan: '122.9',
            mtdActual: '305.8',
            mtdPercentage: '192.15',
            lysm: '12.9',
            lysmPercentage: '192.15',
            ytdPlan: '500.2',
            ytdActual: '500.2',
            ytdPercentage: '192.15',
            action: 'View',
        },
        {
            id: 7,
            zones: 'Retail Channel 2',
            zoneManager: 'Prashant Sawant',
            ftd: '12.2',
            mtdPlan: '122.9',
            mtdActual: '305.8',
            mtdPercentage: '102.77',
            lysm: '12.9',
            lysmPercentage: '102.77',
            ytdPlan: '500.2',
            ytdActual: '500.2',
            ytdPercentage: '102.77',
            action: 'View',
        },
    ];

    const { divRef, columns } = useTableFormat(columnsData);

    const top100Films = [
        { policy: 'B7912718912' },
        { policy: 'B7912718912' },
        { policy: 'B7912718912' },
        { policy: 'B7912718912' },
    ]
    const [searchShow, setSearchShow] = React.useState(false)
    const handleSearch = () => {
        setSearchShow(!searchShow)
    }


    return (
        <div className='bs-panel purple typ-table typ-auto'>
            <div className='panel-head'>
                <div className='left-wrap'>
                    <h2 className='p-title'>Premium</h2>
                </div>
                <div className='right-wrap'>
                    <div className={`bs-search ${searchShow ? 'active' : ''}`}>
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={top100Films.map((option) => option.policy)}
                            renderInput={(params) => (
                                <TextField {...params}
                                    InputProps={{
                                        ...params.InputProps,

                                        endAdornment: (
                                            <InputAdornment position="end" onClick={handleSearch}>
                                                <span className='icon icon-search'></span>
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder='Search' />
                            )}
                            classes={{ paper: 'bs-menu-dropdown' }}

                        />
                    </div>
                    {/* <p className='text-msg'>*All values in crores</p> */}
                    <div className='cm-hidden-md'>
                        <IconButton disableRipple className='btn btn-search' onClick={handleSearch}>
                            <span className='icon icon-search'></span>
                        </IconButton>
                    </div>
                </div>
            </div>
            <div className='panel-content' ref={divRef}>
                {winDimensions[0] <= 768 ? (
                    <GridData />
                ) : (
                    <DataGrid
                        className='bs-data-table'
                        rows={rows}
                        columns={columns.map(col => ({ ...col, renderCell: (params) => <CustomCellRenderer {...params} column={col} row={params.row} /> }))}
                    />
                )}

            </div>
        </div>

    )
}

export default PremiumTable