import React from 'react';
import "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import useSize from '../../../../../hooks/common/useSize'


function BarChart() {
    const winDimensions = useSize();
    const dataBar = {
        labels: ['Data'],
        datasets: [
            {
                label: 'Long Term - 4 CR',
                data: [4],
                backgroundColor: '#9ED2FF',
            },
            {
                label: 'New Assured - 10 CR',
                data: [10],
                backgroundColor: '#FF9AA2',
            },
            {
                label: 'Money Back Super - 3 CR',
                data: [3],
                backgroundColor: '#D29AFF',
            },
            {
                label: 'Term Insurance - 5 CR',
                data: [5],
                backgroundColor: '#C4D691',
            },
            {
                label: 'Pension Plan - 11 CR',
                data: [11],
                backgroundColor: '#9AA4FF',
            },
            {
                label: 'FG Retirement Plan - 2 CR',
                data: [2],
                backgroundColor: '#FFB89A',
            },
            {
                label: 'Term Insurance - 5 CR',
                data: [5],
                backgroundColor: '#9DE8CF',
            },
            {
                label: 'Pension Plan - 11 CR',
                data: [11],
                backgroundColor: '#D5E89D',
            },
            {
                label: 'FG Retirement Plan - 2 CR',
                data: [2],
                backgroundColor: '#9DC0E8',
            },
        ]
    };

    const options = {
        type: 'horizontalBar',
        indexAxis: 'y',
        plugins: {
            datalabels: {
                display: true,
                color: "black",
                align: "end",
                anchor: "end",
                font: { size: "14" }
            },
            legend: {
                display: true,
                position: `${winDimensions[0] <= 768 ? "top" : "bottom"}`,
                align: 'start',
                labels: {
                    usePointStyle: true,
                    boxWidth: 12,
                    boxHeight: 8,
                    padding: 15,
                    font: {
                        size: `${winDimensions[0] <= 768 ? 10 : (winDimensions[0] <= 1600 ? 12 : 16)}`,
                        family: '"Roboto", sans-serif',
                    },
                    color: '#707070',
                },
            },
        },
        // barThickness: `${winDimensions[0] <= 768 ? 6 : 12}`,
        barPercentage: 0.6,
        categoryPercentage: 0.9,
        // categorySpacing: 12,
        scales: {
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },
        },
        // layout: {
        //     padding: {
        //         left: 50, // Adjust padding as needed
        //         right: 50,
        //         top: 50,
        //         bottom: 50,
        //     },
        // },

    };
    return (
        <div className='bs-bar-chart'>
            <Bar data={dataBar} options={options} width={100} height={`${winDimensions[0] <= 768 ? 100 : 50}`} />
        </div>
    )
}

export default BarChart;
