import React, { useState, useEffect } from 'react';
import loadIcon from '../../../common/assets/images/favicon.png'
import loadingImg from '../../../common/assets/images/three-dots.svg'
import Grid from "@mui/material/Grid";
import TableData from './components/TableData'
import { useFetchData } from '../../../hooks/common/useApiService';
import { formatDate } from '../../../hooks/common/useDateFormat';
import useSize from '../../../hooks/common/useSize'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CollapsibleTable from './components/CollapsibleTable';
import RupeesFilter from '../../../common/components/RupeesFilter';
import { useFilterRange } from '../../../store/generalStore';

function Leakage() {
    const fetchData = useFetchData();

    const [loading, setLoading] = useState(true);
    const [loadingLeakageSummary, setLoadingLeakageSummary] = useState(true);
    const [data, setData] = useState(null);
    const [token, setToken] = useState(null);
    const [leakageSummaryData, setLeakageSummaryData] = useState(null);
    const [advisorcode, setAdvisorcode] = useState("");

    useEffect(() => {
        setAdvisorcode(localStorage.getItem('userId'))

        const loginData = JSON.stringify({ "token": "leakage_detail" });
        const loginHeaders = {
            'x-token-key': 'BZz8VIdg39p0OxT',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
            'Content-Type': 'application/json'
        };
        fetchData('post', process.env.REACT_APP_TOKEN, loginData, loginHeaders)
            .then((response) => {
                setToken(response.data?.token)

                setAdvisorcode(localStorage.getItem('userId'))
            })
            .catch((error) => {
                console.error('Error fetching token:', error);
            });
    }, []);


    useEffect(() => {
        if (advisorcode && localStorage.getItem('userId')) {
            const newData = JSON.stringify({ "token": "leakage_bar" });
            const newHeaders = {
                'x-token-key': 'BZz8VIdg39p0OxT',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
                'Content-Type': 'application/json',
                "agent_id": advisorcode
            };
            fetchData('post', process.env.REACT_APP_TOKEN, newData, newHeaders)
                .then((response) => {
                    const tokenCard = response?.data?.token
                    const payloadData = JSON.stringify({
                        // "date_column": "2024-06-11T00:00:00",
                        // "date_column": "2024-04-30T00:00:00",
                        // "agent_id": "80145123"
                        "date_column": formatDate(),
                        "agent_id": advisorcode
                    });
                    fetchData('post', process.env.REACT_APP_LEAKAGE_BAR, payloadData, null, tokenCard)
                        .then((response) => {
                            setData(response?.data);
                            setLoading(false);
                        })
                })
        }
        if (advisorcode && localStorage.getItem('userId')) {
            const newData = JSON.stringify({ "token": "leakage_reason_wise_summary" });
            const newHeaders = {
                'x-token-key': 'BZz8VIdg39p0OxT',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
                'Content-Type': 'application/json',
                "agent_id": advisorcode
            };
            fetchData('post', process.env.REACT_APP_TOKEN, newData, newHeaders)
                .then((response) => {
                    const leakageSummary = response.data?.token;
                    const payloadData = JSON.stringify({
                        // "date_column": "2024-04-30T00:00:00",
                        // "agent_id": "80145123"
                        "date_column": formatDate(),
                        "agent_id": advisorcode
                    });
                    fetchData('post', process.env.REACT_APP_LEAKAGE_REASON_SUMMARY, payloadData, null, leakageSummary)
                        .then((response) => {

                            setLeakageSummaryData(response?.data);
                            setLoadingLeakageSummary(false);
                        })
                })
        }
    }, [advisorcode])

    const winDimensions = useSize();
    const filterRange = useFilterRange((state) => state.filterRange);
    return (
        <>
            <div className='switch-wrap'>
                <RupeesFilter />
            </div>
            <Grid
                container
                rowSpacing={{ md: 4, xs: 3 }}
                columnSpacing={{ md: 4, xs: 4 }}
            >
                <Grid item md={12} sm={12} xs={12}>
                    {loading ? (
                        <div className='cm-loader'>
                            <div className='load-img'>
                                <img src={loadIcon} alt='loader' />
                                <img src={loadingImg} alt='loading' className='loading-gif' />
                            </div>
                        </div>
                    ) : (

                        <div className='bs-panel typ-bar'>
                            <div className='panel-head'>
                                <div className='left-wrap'>
                                    <h2 className='p-title'>{data?.[0].name}</h2>
                                </div>
                            </div>
                            <div className='panel-content'>
                                <div className='bs-stock-bar'>
                                    <div className='b-head'>
                                        <div className='title-wrap'>
                                            <h2 className='b-title'>OVERVIEW DETAILS</h2>
                                        </div>
                                    </div>
                                    <ul className='b-list'>
                                        {data?.[0]?.detail.map((item, index) => (
                                            <li className={`b-item`}>
                                                <div className='bar-wrap'>
                                                    <div className='info-wrap'>
                                                        <label className='pair'>{item?.title} <span className='icon icon-double-arrow'></span></label>
                                                        <span className='num'>
                                                            {filterRange === "lacs" ? Number((item?.valueincr / 100000).toFixed(2)).toLocaleString() + " L" : Number(item?.valueincr).toLocaleString()
                                                                &&
                                                                filterRange === "cr" ? Number((item?.valueincr / 10000000).toFixed(2)).toLocaleString() + " Cr" : Number(item?.valueincr).toLocaleString()}

                                                        </span>
                                                        <div className='mod-tag typ-border'>
                                                            <span className='value'>{item?.valueinpercentage}</span>
                                                            <span className='icon icon-trending'></span>
                                                        </div>
                                                    </div>
                                                    <div className='bar' style={{ width: `${winDimensions[0] <= 768 ? `${item?.barvalue}%` : 'auto'}`, height: `${winDimensions[0] <= 768 ? 'auto' : `${item?.barvalue}%`}`, background: `${winDimensions[0] <= 768 ? item?.d_barcolor : item?.d_barcolor}` }}>
                                                        <div className='indicator' style={{ background: `${winDimensions[0] <= 768 ? item?.d_barcolor : item?.d_barcolor}` }}></div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </Grid>
                {loadingLeakageSummary ? (
                    <Grid item md={12} sm={12} xs={12}>
                        <div className='cm-loader'>
                            <div className='load-img'>
                                <img src={loadIcon} alt='loader' />
                                <img src={loadingImg} alt='loading' className='loading-gif' />
                            </div>
                        </div>
                    </Grid>
                ) : (
                    leakageSummaryData?.map((item, index) => (
                        <Grid item md={12} sm={12} xs={12} key={index}>
                            <CollapsibleTable summaryData={item} filterRange={filterRange} />
                        </Grid>
                    ))
                )}

                <Grid item md={12} sm={12} xs={12}>
                    {loading ? (
                        <div className='cm-loader'>
                            <div className='load-img'>
                                <img src={loadIcon} alt='loader' />
                                <img src={loadingImg} alt='loading' className='loading-gif' />
                            </div>
                        </div>
                    ) : (
                        <TableData filterRange={filterRange} advisorcode={advisorcode} setAdvisorcode={setAdvisorcode} token={token} />
                    )}
                </Grid>
            </Grid >

        </>

    )
}

export default Leakage