import { useRef, useEffect, useState } from "react";

const useTableFormat = (columnsData) => {
    const divRef = useRef(null);
    const [tableWidth, setTableWidth] = useState(0);
    const [tableCellWidth, setTableCellWidth] = useState(0);
    const [totalWidth, setTotalWidth] = useState(0);
    const [columns, setColumns] = useState(columnsData)

    useEffect(() => {
        if (columnsData?.length > 0) {
            const totalNewWidth = columnsData.reduce((acc, column) => acc + (column.width || 0), 0);
            setTotalWidth(totalNewWidth)
            // console.log(totalNewWidth, 'totalWidth')
        }
    }, [columnsData]);


    useEffect(() => {
        if (divRef.current && totalWidth > 0) {
            const currentWidth = divRef.current.offsetWidth;
            // console.log("currentWidth before", totalWidth);
            setTableWidth(currentWidth);
            if (currentWidth > totalWidth) {
                // console.log("currentWidth", currentWidth, totalWidth, currentWidth > totalWidth);

                const calculatedCellWidth = currentWidth / columnsData.length;
                setTableCellWidth(calculatedCellWidth);
            }

        }
    }, [totalWidth, columnsData]);

    useEffect(() => {

        if (columnsData?.length > 0) {
            const updateColumns = () => {
                const updatedColumns = columnsData.map(column => {
                    return {
                        ...column,
                        // width: totalWidth > tableWidth ? column?.width : tableCellWidth
                        width: tableCellWidth
                    };
                });
                setColumns(updatedColumns);
            };
            if (totalWidth < tableWidth) {
                updateColumns();
            }
            else {
                setColumns(columnsData)
            }
        }

    }, [tableWidth, tableCellWidth, totalWidth, columnsData])
    // console.log("tablecell", tableCellWidth, columns);
    return { divRef, columns };


};


export default useTableFormat