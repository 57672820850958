import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { amountUnit } from '../../../../../utils/helper';

const CollapsibleTable = ({ summaryData, filterRange }) => {

    const data = summaryData;

    const [isVisible, setIsVisible] = useState('')
    const handleIsVisible = (index) => {
        if (index === isVisible) {
            setIsVisible('')
        } else {
            setIsVisible(index)
        }
    }
    return (
        <div className='bs-panel'>
            <div className='panel-head'>
                <div className='left-wrap'>
                    <h2 className='p-title'>{data?.name}</h2>
                </div>
            </div>
            <div className='panel-content'>
                <div className='bs-table typ-persistency typ-collasible'>
                    <table>
                        <thead>
                            <tr>
                                {
                                    data?.columnname.map((colName, index) => (colName?.colname !== "nop%" && colName?.colname !== "ape%" &&
                                        <th>{colName?.displayName}</th>)
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {data?.detail?.map((item, index) =>
                            (
                                <>
                                    <tr key={index}>
                                        {
                                            data?.columnname.map((colName, colIndex) =>
                                                colIndex === 0 && item.hasOwnProperty('moreDetails') ? <td>
                                                    {item?.[colName?.colname]}
                                                    <IconButton disableRipple className='btn' onClick={() => handleIsVisible(index)}>
                                                        <span className={`icon ${index === isVisible ? "icon-chevron-up" : "icon-chevron-down"}`}></span>
                                                    </IconButton>
                                                </td> :
                                                    (colName?.colname !== "nop%" && colName?.colname !== "ape%" &&

                                                        <td key={index}>{colName?.colname === "ape" ? amountUnit(item?.[colName?.colname], filterRange) : item?.[colName?.colname]}</td>
                                                    )
                                            )
                                        }
                                    </tr>
                                    {
                                        item.hasOwnProperty('moreDetails') && index === isVisible && item.moreDetails.map((moreDetailsItem, index) =>
                                            <tr key={index} className='sub-table'>
                                                {
                                                    data?.columnname.map((colName, index) =>
                                                    (colName?.colname !== "nop%" && colName?.colname !== "ape%" &&
                                                        <td key={index}>
                                                            {colName?.colname === "ape" ? amountUnit(moreDetailsItem?.[colName?.colname], filterRange) : moreDetailsItem?.[colName?.colname]}
                                                        </td>
                                                    )
                                                    )
                                                }
                                            </tr>
                                        )

                                    }
                                </>
                            )
                            )}

                        </tbody >
                    </table>
                </div>

            </div>
        </div>
    );
};

export default CollapsibleTable;
