import React from 'react'
import Pagination from '@mui/material/Pagination';

function TablePagination() {
    return (
        <Pagination
            count={10}
            showFirstButton
            showLastButton
            className='bs-pagination'
        />

    )
}

export default TablePagination