import React, { useState } from 'react';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

function DateRange() {
    const [value, setValue] = useState([new Date(), new Date()]);

    return (
        <div className='bs-date-range'>
            <DateRangePicker
                value={value}
                onChange={setValue}
                placeholder="Select Date Range"
            />
        </div>

    )
}

export default DateRange