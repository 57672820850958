import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import { amountUnit } from '../../../../../utils/helper';
import Button from '@mui/material/Button';

function GridData({ data, filterRange, cardName, advisorcode, setAdvisorcode, filteredData }) {
    let apiData = data;
    const colname = (values) => {
        if (apiData?.columnname.some(colData => colData.colname === values)) {
            const getDisplayName = apiData?.columnname.find(colData => colData.colname === values);
            return getDisplayName?.displayName;
        }
    }

    const [currentPage, setCurrentPage] = useState(0);
    const recordsPerPage = 4;
    const totalPages = Math.ceil(filteredData?.length / recordsPerPage);

    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const currentRecords = filteredData?.slice(
        currentPage * recordsPerPage,
        (currentPage + 1) * recordsPerPage
    );

    const handleAdvisorClick = (code, name) => {
        const drillDown = localStorage.getItem("drillDown");
        const dataArray = drillDown ? JSON.parse(drillDown) : []
        const currDrillDown = { "code": code, "name": name }
        const newArray = [...dataArray, currDrillDown];
        localStorage.setItem("drillDown", JSON.stringify(newArray))
        const getLength = newArray.length - 1;
        setAdvisorcode(newArray[getLength].code)
        window.scrollTo(0, 0)
    }


    return (
        <div className='lyt-grid-table'>
            {currentRecords?.map((item, index) => (
                <Accordion className='bs-table-accordion'>
                    <AccordionSummary
                        expandIcon={<span className='icon icon-chevron-down'></span>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <ul className='acc-list'>
                            <li className='acc-item' >
                                <span className='pair'>{item.advisorname}</span>
                                {/* <span className='pair'>{item.advisorcode}</span> */}
                            </li>
                            {Object.entries(item).map(([key, value], i) => {
                                return (
                                    (key === "lysm%" || key === "mtd%" || key === "ytd%") && (

                                        <li className='acc-item' >
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                <div className={`mod-tag ${value <= 85 ? "down" : ""} ${value > 85 && value < 95 ? "medium" : ""}`}>
                                                    <span className='value'>
                                                        {value}%
                                                    </span>
                                                    <span className='icon icon-trending'></span>
                                                </div>
                                            </span>
                                        </li>
                                    )
                                )
                            })}
                        </ul>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul className='acc-list'>
                            {Object.entries(item).map(([key, value], i) => {
                                return (
                                    (key === "mtdactual" || key === "lysmactual" || key === "ytdactual") && (
                                        <li className='acc-item' >
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                <div className='mod-indicator'>
                                                    {cardName.toLowerCase() === "premium" || cardName.toLowerCase() === "ape" || cardName.toLowerCase() === "cp" || cardName.toLowerCase() === "wfyp" ?

                                                        amountUnit(value, filterRange)
                                                        :
                                                        value
                                                    }<span className={`icon icon-${value > 0 ? "up" : "down"}`} ></span>
                                                </div>
                                            </span>
                                        </li>
                                    )

                                )
                            })}
                            {Object.entries(item).map(([key, value], i) => {
                                return (
                                    (key === 'mtdplan' || key === 'ytdplan') && (
                                        <li className='acc-item' >
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                {(cardName.toLowerCase() === "premium" || cardName.toLowerCase() === "ape" || cardName.toLowerCase() === "cp" || cardName.toLowerCase() === "wfyp") ?
                                                    amountUnit(value, filterRange) : value
                                                }
                                            </span>
                                        </li>
                                    )
                                )
                            })}
                            {Object.entries(item).map(([key, value], i) => {
                                return (
                                    key !== "id" && key !== "advisorname" && key !== "lysm%" && key !== "mtd%" && key !== "ytd%" && key !== "mtdactual" && key !== "lysmactual" && key !== "ytdactual" && key !== 'mtdplan' && key !== 'lysmplan' &&
                                    key !== 'ytdplan' &&
                                    key !== 'zonalmanager' &&
                                    key !== 'regioncode' &&
                                    key !== 'regionmanagercode' &&
                                    key !== 'regionmanagername' &&
                                    key !== 'branchmanagercode' &&
                                    key !== 'branchmanagername' &&
                                    key !== 'cp' &&
                                    key !== 'ape' &&
                                    key !== 'wfyp' &&
                                    (
                                        <li className='acc-item' >
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                {key === "ftdactual" ? (
                                                    <div className='mod-indicator'>
                                                        {(cardName.toLowerCase() === "premium" || cardName.toLowerCase() === "ape" || cardName.toLowerCase() === "cp" || cardName.toLowerCase() === "wfyp") ?
                                                            amountUnit(value, filterRange) : value}<span className={`icon icon-${value > 0 ? "up" : "down"}`} ></span>
                                                    </div>
                                                ) : key === 'cp' || key === 'ape' || key === 'wfyp' ? (
                                                    amountUnit(value, filterRange)) : value}
                                            </span>
                                        </li>
                                    )
                                )
                            })}
                            <li className='acc-item action-item'>
                                <Button className="btn btn-link" onClick={() => handleAdvisorClick(item.advisorcode, item.advisorname)}>View</Button>
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion >

            ))
            }
            <div className='pagination-wrap'>
                <div className='left-wrap'>
                    <IconButton disableRipple className='btn btn-search' onClick={handlePrev} disabled={currentPage === 0}>
                        <span className='icon icon-prev'></span>
                    </IconButton>
                    <IconButton disableRipple className='btn btn-search' onClick={handleNext} disabled={currentPage === totalPages - 1}>
                        <span className='icon icon-next'></span>
                    </IconButton>
                </div>
                <div className='right-wrap'>
                    <span>Page</span>
                    <span>{currentPage + 1}</span>
                    <span>of {totalPages}</span>
                </div>
            </div>
        </div >

    )
}

export default GridData