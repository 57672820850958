import React from 'react'
import Button from '@mui/material/Button';

function StockTable(props) {
    return (
        <div className={`bs-card ${props.colorName ? props.colorName : ''} ${props.activeClass ? props.activeClass : ''}`}>
            <div className='head-wrap'>
                <div className='title-wrap'>
                    <div className='icon-wrap'>
                        <span className={`icon icon-${props.icon ? props.icon : 'wallet'}`}></span>
                    </div>
                    <h2 className='title'>
                        <span>Current month</span>
                        Persistency
                    </h2>
                </div>
            </div>
            <div className='cont-wrap'>
                <div className='bs-table typ-simple'>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>NOP</th>
                                <th>APE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>13th month</td>
                                <td>89.17%<span className='icon icon-up'></span></td>
                                <td>15.12%<span className='icon icon-down'></span></td>
                            </tr>
                            <tr>
                                <td>25th month</td>
                                <td>89.17%<span className='icon icon-up'></span></td>
                                <td>15.12%<span className='icon icon-down'></span></td>
                            </tr>
                            <tr>
                                <td>37th month</td>
                                <td>89.17%<span className='icon icon-up'></span></td>
                                <td>15.12%<span className='icon icon-down'></span></td>
                            </tr>
                            <tr>
                                <td>49th month</td>
                                <td>89.17%<span className='icon icon-up'></span></td>
                                <td>15.12%<span className='icon icon-down'></span></td>
                            </tr>
                            <tr>
                                <td>61st month</td>
                                <td>89.17%<span className='icon icon-up'></span></td>
                                <td>15.12%<span className='icon icon-down'></span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='action-wrap cm-hidden-md'>
                    <Button className="btn btn-link typ-underline">VIEW DETAILS</Button>
                </div>
            </div>
            <div className='indicator'></div>
        </div>
    )
}

export default StockTable