import CryptoJS from 'crypto-js';

const encryptedData = (textToEncrypt) => {
    let byteKey = CryptoJS.enc.Base64.parse("NY33aaHKGUR9a3saGx3LAg==");
    // let byteKey = CryptoJS.enc.Base64.parse("RufBd1lBte8QIwE6L9mi3w==");
    let byteIV = CryptoJS.lib.WordArray.random(16);
    let encrypted = CryptoJS.AES.encrypt(textToEncrypt, byteKey, { keySize: 128 / 8, iv: byteIV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    let arrIV = CryptoJS.enc.Base64.parse(encrypted.iv.toString(CryptoJS.enc.Base64));
    let arrCipherText = CryptoJS.enc.Base64.parse(encrypted.ciphertext.toString(CryptoJS.enc.Base64));
    let arrEncryptedText = arrIV.concat(arrCipherText);
    let strEncryptedText = CryptoJS.enc.Base64.stringify(arrEncryptedText);
    // console.log(strEncryptedText)
    return strEncryptedText;
}

export { encryptedData }