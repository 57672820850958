import React, { useState, useEffect } from 'react';
import loadIcon from '../../../common/assets/images/favicon.png'
import loadingImg from '../../../common/assets/images/three-dots.svg'
import Grid from "@mui/material/Grid";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import PremiumTable from './components/PremiumTable';
// import PremiumTableSpan from './components/PremiumTableSpan';
import TablePagination from './components/TablePagination'
import StockTable from './components/StockTable'


function Dashboard() {

    const [activeIndex, setActiveIndex] = React.useState(0);

    const handleLiClick = (index) => {
        setActiveIndex(index);
    };

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            <div className='mod-switch'>

                <input type="radio" name="radio" id='fgli' defaultChecked />
                <label className="r-label" htmlFor="fgli">FGLI</label>

                <input type="radio" name="radio" id='partnership' />
                <label className="r-label" htmlFor='partnership'>Partnership</label>

                <span className='switch-active'></span>

            </div>
            <Grid
                container
                rowSpacing={{ md: 4, xs: 2 }}
                columnSpacing={{ md: 4, xs: 4 }}
            >
                <Grid item md={12} sm={12} xs={12}>

                    <Swiper
                        // navigation={true}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        modules={[Navigation, Pagination]}
                        slidesPerView={1}
                        spaceBetween={16}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 24,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 24,
                            },
                        }}
                        className="mySwiper bs-swiper"
                    >
                        <SwiperSlide>
                            {loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (
                                <StockTable activeIndex="1" activeClass={activeIndex === 1 ? 'active' : ''} handleLiClick={handleLiClick} />
                            )}
                        </SwiperSlide>
                        <SwiperSlide>
                            {loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (

                                <StockTable activeIndex="2" activeClass={activeIndex === 2 ? 'active' : ''} colorName="red" icon="account" handleLiClick={handleLiClick} />
                            )}
                        </SwiperSlide>
                        <SwiperSlide>
                            {loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (

                                <StockTable activeIndex="3" activeClass={activeIndex === 3 ? 'active' : ''} colorName="purple" icon="charger" handleLiClick={handleLiClick} />
                            )}
                        </SwiperSlide>
                        <SwiperSlide>
                            {loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (

                                <StockTable activeIndex="4" activeClass={activeIndex === 4 ? 'active' : ''} handleLiClick={handleLiClick} />
                            )}
                        </SwiperSlide>
                        <SwiperSlide>
                            {loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (

                                <StockTable activeIndex="5" activeClass={activeIndex === 5 ? 'active' : ''} handleLiClick={handleLiClick} />
                            )}
                        </SwiperSlide>
                        <SwiperSlide>
                            {loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (
                                <StockTable activeIndex="6" activeClass={activeIndex === 6 ? 'active' : ''} handleLiClick={handleLiClick} />
                            )}

                        </SwiperSlide>
                        <div className="swiper-button-prev"><span className='icon icon-prev'></span></div>
                        <div className="swiper-button-next"><span className='icon icon-next'></span></div>
                    </Swiper>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    {loading ? (
                        <div className='cm-loader'>
                            <div className='load-img'>
                                <img src={loadIcon} alt='loader' />
                                <img src={loadingImg} alt='loading' className='loading-gif' />
                            </div>
                        </div>
                    ) : (

                        <PremiumTable />
                        // <PremiumTableSpan />

                    )}
                    <div className='table-pagination'>
                        <TablePagination />
                    </div>

                </Grid>
            </Grid>
        </>
    )
}

export default Dashboard