// import React from "react";
// import { Route, useLocation } from "react-router-dom";
// import NonAuthLayout from "../Layout/NonAuthLayout";
// import AuthLayout from "../Layout/AuthLayout";

// const Authmiddleware = ({
//     component: Component,
//     layout: Layout,
//     isAuthProtected,
//     ...rest
// }) => {
//     const location = useLocation();
//     return (
//         <Route
//             {...rest}
//             element={
//                 location.pathname === '/login' ? (
//                     <NonAuthLayout>
//                         <Component />
//                     </NonAuthLayout>
//                 ) : (
//                     <AuthLayout>
//                         <Component />
//                     </AuthLayout>
//                 )
//             }
//         // render={(props) => {
//         //     return (
//         //         <>
//         //             {location.pathname === "/login" ? (
//         //                 <NonAuthLayout>
//         //                     <Component />
//         //                 </NonAuthLayout>
//         //             ) : (
//         //                 <AuthLayout>
//         //                     <Component {...props} />
//         //                 </AuthLayout>
//         //             )}
//         //         </>
//         //     );
//         // }}
//         />

//     );
// };

// export default Authmiddleware;


// Authmiddleware.js

// import React from "react";
// import { Route, useLocation, Routes, Navigate } from "react-router-dom";
// import NonAuthLayout from "../Layout/NonAuthLayout";
// import AuthLayout from "../Layout/AuthLayout";

// const Authmiddleware = ({
//     component: Component,
//     layout: Layout,
//     isAuthProtected,
//     ...rest
// }) => {
//     const location = useLocation();
//     return (
//         <Routes>
//             <Route
//                 {...rest}
//                 render={(props) => {
//                     if (isAuthProtected && localStorage?.getItem("token")) {
//                         return (
//                             <Navigate
//                                 to={{ pathname: "/login", state: { from: props.location } }}
//                             />
//                         );
//                     }
//                     else if (!isAuthProtected && localStorage?.getItem("token")) {
//                         return (
//                             <Navigate
//                                 to={{
//                                     pathname: '/',
//                                     state: { from: props.location },
//                                 }}
//                             />
//                         );
//                     }
//                 }}
//                 element={
//                     // location.pathname === '/login' ? (
//                     //     <Layout>
//                     //         <Component />
//                     //     </Layout>
//                     // ) : (
//                     <Layout>
//                         <Component />
//                     </Layout>
//                     // )
//                 }
//             />
//         </Routes>
//     );
// };

// export default Authmiddleware;


import { Navigate, useLocation } from 'react-router-dom';

const Authmiddleware = ({ component: Component, layout: Layout, isAuthProtected }) => {
    const location = useLocation();
    const token = localStorage?.getItem("userId");

    if (isAuthProtected && !token) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }
    if (!isAuthProtected && token && !location?.search?.includes("token")) {
        return <Navigate to="/dashboard" state={{ from: location }} />;
    }

    return (
        <Layout>
            <Component />
        </Layout>
    );
};

export default Authmiddleware;

