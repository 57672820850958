import React from 'react'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';

function FormFields() {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //select function
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const top100Films = [
        { policy: 'B7912718912' },
        { policy: 'B7912718912' },
        { policy: 'B7912718912' },
        { policy: 'B7912718912' },
    ]

    return (
        <div className='bs-form'>
            <form>
                <div className='form-group'>
                    <label className='form-label'>Username</label>
                    <TextField id="outlined-basic" variant="outlined" className='form-control' />
                </div>
                <div className='form-group'>
                    <label className='form-label'>Password</label>
                    <TextField id="outlined-password-input" variant="outlined" className='form-control'
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (<span className='icon icon-password-hide'></span>) : (<span className='icon icon-password-hide'></span>)}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className='form-group'>
                    <div className='bs-select'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" className='form-label'>Age</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleChange}
                                IconComponent={(props) => (
                                    <span
                                        {...props}
                                        className={`icon-chevron-down ${props.className}`}
                                    ></span>
                                )}
                                MenuProps={{
                                    classes: {
                                        paper: "bs-menu-dropdown",
                                    },
                                }}
                                displayEmpty
                            >
                                <MenuItem value="">Select</MenuItem>
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='form-group'>
                    <div className='bs-search'>
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={top100Films.map((option) => option.policy)}
                            renderInput={(params) => (
                                <TextField {...params}
                                    InputProps={{
                                        ...params.InputProps,

                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <span className='icon icon-search'></span>
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder='Search' />
                            )}
                            classes={{ paper: 'bs-menu-dropdown' }}

                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default FormFields