import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Filter from '../Filter';
import { useLocation, useNavigate } from 'react-router-dom';

function Menu() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const [sticky, setSticky] = useState("");

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 152 ? "is-sticky" : "";
        setSticky(stickyClass);
    };

    const navigate = useNavigate();
    const location = useLocation();

    const goToOtherPage = (route) => {
        navigate(route);
    };

    return (
        <>
            {['right'].map((anchor) => (

                <React.Fragment key={anchor}>
                    <div className={`bs-menu ${sticky}`}>
                        <div className='menu-wrap'>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons={false}
                                // allowScrollButtonsMobile={true}
                                aria-label="scrollable prevent tabs example"
                                className='menu-tabs'
                            >
                                <Tab className={`${location?.pathname === "/dashboard" ? "active" : ""}`} icon={<span className='icon icon-trending'></span>} label="Login" onClick={() => {
                                    goToOtherPage("/dashboard");
                                }} />
                                <Tab className={`${location?.pathname === "/issuance" ? "active" : ""}`} icon={<span className='icon icon-people'></span>} label="Issuance" onClick={() => {
                                    goToOtherPage("/issuance");
                                }} />
                                <Tab className={`${location?.pathname === "/leakage" ? "active" : ""}`} icon={<span className='icon icon-sync'></span>} label="Leakage" onClick={() => {
                                    goToOtherPage("/leakage");
                                }} />
                                <Tab className={`${location?.pathname === "/wip" ? "active" : ""}`} icon={<span className='icon icon-tools'></span>} label="Work in Progress"
                                    onClick={() => {
                                        goToOtherPage("/wip");
                                    }}
                                />
                                {/*<Tab className={`${location?.pathname == "/persistency" ? "active" : ""}`} icon={<span className='icon icon-calendar'></span>} label="Persistency" onClick={() => {
                                    goToOtherPage("/persistency");
                                }} />
                                <Tab className={`${location?.pathname == "/pms" ? "active" : ""}`} icon={<span className='icon icon-account'></span>} label="PMS" onClick={() => {
                                    goToOtherPage("/pms");
                                }} />
                                <Tab className={`${location?.pathname == "/product-mix" ? "active" : ""}`} icon={<span className='icon icon-account'></span>} label="Product Mix" onClick={() => {
                                    goToOtherPage("/product-mix");
                                }} /> */}
                            </Tabs>
                        </div>
                        {/* <div className='btn-wrap'>
                            <Button className="btn btn-icon"><span className='icon icon-download-arrow'></span> <span className='text'>Download</span></Button>
                            <Button className="btn btn-icon typ-grey" onClick={toggleDrawer(anchor, true)}>
                                <span className='icon icon-filter'></span>
                                <span className='text'>Filters</span>
                                <span className='count'>3</span>
                            </Button>

                        </div> */}
                    </div>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        // onClose={toggleDrawer(anchor, false)}
                        className='bs-drawer'
                    >
                        <div className='head-wrap'>
                            <h2 className='d-title'>Filters</h2>
                            <IconButton disableRipple className='btn close-btn' onClick={toggleDrawer(anchor, false)}>
                                <span className='icon icon-close'></span>
                            </IconButton>
                        </div>
                        <div className='cont-wrap'>
                            <Filter />
                        </div>
                        <div className='foot-wrap'>
                            <Button className="btn btn-default">APPLY FILTERS</Button>
                        </div>
                    </Drawer>
                </React.Fragment>
            ))}

        </>
    )
}

export default Menu