import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function PersistencyGrid() {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <div className='lyt-grid-table'>
            <Accordion className='bs-table-accordion typ-2'>
                <AccordionSummary
                    expandIcon={<span className='icon icon-chevron-down'></span>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <span className='key'>Agent name</span>
                            <span className='pair'>Sanjeev Kumar Singh</span>
                        </li>
                        <li className='acc-item'>
                            <div className='bs-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Details</th>
                                            <th>NOP</th>
                                            <th>APE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='bg-grey'>
                                            <td>13th month paid</td>
                                            <td>3902</td>
                                            <td>3902</td>
                                        </tr>
                                        <tr>
                                            <td>13th month not paid</td>
                                            <td>3902</td>
                                            <td>3902</td>
                                        </tr>
                                        <tr className='bg-grey'>
                                            <td>13th month total</td>
                                            <td>3902</td>
                                            <td>3902</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                    </ul>
                </AccordionSummary>
                <AccordionDetails>
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <div className='bs-table'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>13th month %</td>
                                            <td>
                                                <div className='mod-tag down'>
                                                    <span className='value'>29%</span>
                                                    <span className='icon icon-trending'></span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='mod-tag'>
                                                    <span className='value'>29%</span>
                                                    <span className='icon icon-trending'></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='bg-grey'>
                                            <td>MTD</td>
                                            <td>1921.23</td>
                                            <td>8202.2</td>
                                        </tr>
                                        <tr>
                                            <td>13th M paid <span className='cm-line-break'>but 25th M not paid</span></td>
                                            <td>1237</td>
                                            <td>7872</td>
                                        </tr>
                                        <tr className='bg-grey'>
                                            <td>Drop rate</td>
                                            <td>
                                                <div className='mod-tag down'>
                                                    <span className='value'>29%</span>
                                                    <span className='icon icon-trending'></span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='mod-tag'>
                                                    <span className='value'>29%</span>
                                                    <span className='icon icon-trending'></span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            <Accordion className='bs-table-accordion typ-2'>
                <AccordionSummary
                    expandIcon={<span className='icon icon-chevron-down'></span>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <span className='key'>Agent name</span>
                            <span className='pair'>Prashant Kumar Todi</span>
                        </li>
                        <li className='acc-item'>
                            <div className='bs-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Details</th>
                                            <th>NOP</th>
                                            <th>APE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='bg-grey'>
                                            <td>13th month paid</td>
                                            <td>3902</td>
                                            <td>3902</td>
                                        </tr>
                                        <tr>
                                            <td>13th month not paid</td>
                                            <td>3902</td>
                                            <td>3902</td>
                                        </tr>
                                        <tr className='bg-grey'>
                                            <td>13th month total</td>
                                            <td>3902</td>
                                            <td>3902</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                    </ul>
                </AccordionSummary>
                <AccordionDetails>
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <div className='bs-table'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>13th month %</td>
                                            <td>
                                                <div className='mod-tag down'>
                                                    <span className='value'>29%</span>
                                                    <span className='icon icon-trending'></span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='mod-tag'>
                                                    <span className='value'>29%</span>
                                                    <span className='icon icon-trending'></span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className='bg-grey'>
                                            <td>MTD</td>
                                            <td>1921.23</td>
                                            <td>8202.2</td>
                                        </tr>
                                        <tr>
                                            <td>13th M paid <span className='cm-line-break'>but 25th M not paid</span></td>
                                            <td>1237</td>
                                            <td>7872</td>
                                        </tr>
                                        <tr className='bg-grey'>
                                            <td>Drop rate</td>
                                            <td>
                                                <div className='mod-tag down'>
                                                    <span className='value'>29%</span>
                                                    <span className='icon icon-trending'></span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='mod-tag'>
                                                    <span className='value'>29%</span>
                                                    <span className='icon icon-trending'></span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            <div className='pagination-wrap'>
                <div className='left-wrap'>
                    <IconButton disableRipple className='btn btn-search'>
                        <span className='icon icon-prev'></span>
                    </IconButton>
                    <IconButton disableRipple className='btn btn-search'>
                        <span className='icon icon-next'></span>
                    </IconButton>
                </div>
                <div className='right-wrap'>
                    <span>Page</span>
                    <div className='bs-select typ-sm'>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleChange}
                                IconComponent={(props) => (
                                    <span
                                        {...props}
                                        className={`icon-chevron-down ${props.className}`}
                                    ></span>
                                )}
                                MenuProps={{
                                    classes: {
                                        paper: "bs-menu-dropdown",
                                    },
                                }}
                                displayEmpty
                            >
                                <MenuItem value="">1</MenuItem>
                                <MenuItem value={1}>2</MenuItem>
                                <MenuItem value={2}>3</MenuItem>
                                <MenuItem value={3}>4</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <span>of 10</span>
                </div>
            </div>
        </div>
    )
}

export default PersistencyGrid