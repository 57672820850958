import React from 'react'
import useSize from '../../../hooks/common/useSize'

function StockBar() {
    const winDimensions = useSize();
    return (
        <div className='bs-stock-bar'>
            <ul className='b-list'>
                <li className='b-item active'>
                    <div className='bar-wrap'>
                        <div className='info-wrap'>
                            <label className='pair'>Total stock <span className='icon icon-double-arrow'></span></label>
                            <span className='num'>24.4 Cr</span>
                            <div className='mod-tag typ-border'>
                                <span className='value'>29%</span>
                                <span className='icon icon-trending'></span>
                            </div>
                        </div>
                        <div className='bar' style={{ height: `calc(100% - 150px)`, background: `${winDimensions[0] <= 768 ? '#3C76FF' : '#f6a961'}` }}>
                            <div className='indicator' style={{ background: `${winDimensions[0] <= 768 ? '#3C76FF' : '#f6a961'}` }}></div>
                        </div>
                    </div>
                </li>
                <li className='b-item'>
                    <div className='bar-wrap'>
                        <div className='info-wrap'>
                            <label className='pair'>Pre-issued</label>
                            <span className='num'>20.1 Cr</span>
                            <div className='mod-tag typ-border'>
                                <span className='value'>29%</span>
                                <span className='icon icon-trending'></span>
                            </div>
                        </div>
                        <div className='bar' style={{ height: `calc(95% - 150px)`, background: `${winDimensions[0] <= 768 ? '#6290FE' : '#F59E4D'}` }}>
                            <div className='indicator' style={{ background: `${winDimensions[0] <= 768 ? '#6290FE' : '#F59E4D'}` }}></div>
                        </div>
                    </div>
                </li>
                <li className='b-item'>
                    <div className='bar-wrap'>
                        <div className='info-wrap'>
                            <label className='pair'>Leak</label>
                            <span className='num'>18.3 Cr</span>
                            <div className='mod-tag typ-border'>
                                <span className='value'>29%</span>
                                <span className='icon icon-trending'></span>
                            </div>
                        </div>
                        <div className='bar' style={{ height: `calc(90% - 150px)`, background: `${winDimensions[0] <= 768 ? '#82A7FF' : '#F6A961'}` }}>
                            <div className='indicator' style={{ background: `${winDimensions[0] <= 768 ? '#82A7FF' : '#F6A961'}` }}></div>
                        </div>
                    </div>
                </li>
                <li className='b-item'>
                    <div className='bar-wrap'>
                        <div className='info-wrap'>
                            <label className='pair'>Pending with OPS</label>
                            <span className='num'>17.6 Cr</span>
                            <div className='mod-tag typ-border'>
                                <span className='value'>29%</span>
                                <span className='icon icon-trending'></span>
                            </div>
                        </div>
                        <div className='bar' style={{ height: `calc(85% - 150px)`, background: `${winDimensions[0] <= 768 ? '#9CB9FE' : '#F7B375'}` }}>
                            <div className='indicator' style={{ background: `${winDimensions[0] <= 768 ? '#9CB9FE' : '#F7B375'}` }}></div>
                        </div>
                    </div>
                </li>
                <li className='b-item'>
                    <div className='bar-wrap'>
                        <div className='info-wrap'>
                            <label className='pair'>Pending with sales</label>
                            <span className='num'>16.8 Cr</span>
                            <div className='mod-tag typ-border'>
                                <span className='value'>29%</span>
                                <span className='icon icon-trending'></span>
                            </div>
                        </div>
                        <div className='bar' style={{ height: `calc(80% - 150px)`, background: `${winDimensions[0] <= 768 ? '#AEC6FF' : '#F8BE89'}` }}>
                            <div className='indicator' style={{ background: `${winDimensions[0] <= 768 ? '#AEC6FF' : '#F8BE89'}` }}></div>
                        </div>
                    </div>
                </li>
                <li className='b-item'>
                    <div className='bar-wrap'>
                        <div className='info-wrap'>
                            <label className='pair'>Gross issuance</label>
                            <span className='num'>15.4 Cr</span>
                            <div className='mod-tag typ-border'>
                                <span className='value'>29%</span>
                                <span className='icon icon-trending'></span>
                            </div>
                        </div>
                        <div className='bar' style={{ height: `calc(75% - 150px)`, background: `${winDimensions[0] <= 768 ? '#C1D3FE' : '#F9C99C'}` }}>
                            <div className='indicator' style={{ background: `${winDimensions[0] <= 768 ? '#C1D3FE' : '#F9C99C'}` }}></div>
                        </div>
                    </div>
                </li>
                <li className='b-item'>
                    <div className='bar-wrap'>
                        <div className='info-wrap'>
                            <label className='pair'>Post issued leak </label>
                            <span className='num'>14.2 Cr</span>
                            <div className='mod-tag typ-border'>
                                <span className='value'>29%</span>
                                <span className='icon icon-trending'></span>
                            </div>
                        </div>
                        <div className='bar' style={{ height: `calc(70% - 150px)`, background: `${winDimensions[0] <= 768 ? '#CDDBFE' : '#FBD4B0'}` }}>
                            <div className='indicator' style={{ background: `${winDimensions[0] <= 768 ? '#CDDBFE' : '#FBD4B0'}` }}></div>
                        </div>
                    </div>
                </li>
                <li className='b-item'>
                    <div className='bar-wrap'>
                        <div className='info-wrap'>
                            <label className='pair'>Net issuance</label>
                            <span className='num'>12.8 Cr</span>
                            <div className='mod-tag typ-border'>
                                <span className='value'>29%</span>
                                <span className='icon icon-trending'></span>
                            </div>
                        </div>
                        <div className='bar' style={{ height: `calc(65% - 150px)`, background: `${winDimensions[0] <= 768 ? '#DAE4FF' : '#f6a961'}` }}>
                            <div className='indicator' style={{ background: `${winDimensions[0] <= 768 ? '#DAE4FF' : '#f6a961'}` }}></div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default StockBar