import React from 'react'
import logo from '../../assets/images/logo.svg'

function Loader() {
    return (
        <div className='bs-loader'>
            <div className='logo-wrap'>
                <img src={logo} alt='logo' />
            </div>
        </div>
    )
}

export default Loader