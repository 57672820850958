import React from 'react'
import Modal from '@mui/material/Modal';
import errorImg from '../../../common/assets/images/error.png'
import Button from '@mui/material/Button';
import { useGeneralStore } from '../../../store/generalStore';
import { useNavigate } from 'react-router-dom';

const Errormodal = () => {
    const navigate = useNavigate();
    const open = useGeneralStore((state) => state.open);
    const setOpen = useGeneralStore((state) => state.setOpen);
    const errorMessage = useGeneralStore((state) => state.errorMessage);

    const handleClose = () => {
        setOpen(false)
        navigate('/');

    }
    return (
        <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className='bs-modal'
        >
            <div className='modal-wrap'>
                <span className='icon icon-close close-btn' onClick={handleClose}></span>
                <div className='icon-wrap'>
                    <img src={errorImg} alt='checkImg' />
                </div>
                <h3 className='m-title'>{errorMessage}</h3>
                <div className='action-wrap'>
                    <Button className="btn btn-default" onClick={handleClose}>Login Again</Button>
                </div>
            </div>
        </Modal>
    )
}

export default Errormodal