import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// import IconButton from '@mui/material/IconButton';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

function GridData() {
    // const [age, setAge] = React.useState('');

    // const handleChange = (event) => {
    //     setAge(event.target.value);
    // };
    return (
        <div className='lyt-grid-table'>
            <Accordion className='bs-table-accordion typ-2'>
                <AccordionSummary
                    expandIcon={<span className='icon icon-chevron-down'></span>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <span className='pair'>Zone 1</span>
                        </li>
                        <li className='acc-item'>
                            <div className='bs-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>PPT&gt;=10</th>
                                            <th>PPT&lt;=10</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='bg-grey'>
                                            <td>PAR</td>
                                            <td>43%</td>
                                            <td>27%</td>
                                        </tr>
                                        <tr>
                                            <td>NO PAR</td>
                                            <td>12%</td>
                                            <td>12.8%</td>
                                        </tr>
                                        <tr className='bg-grey'>
                                            <td>ULIP</td>
                                            <td>18%</td>
                                            <td>11%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                    </ul>
                </AccordionSummary>
                <AccordionDetails>
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <div className='bs-table'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>TERM</td>
                                            <td>22.6%</td>
                                            <td>13.3%</td>
                                        </tr>
                                        <tr className='bg-grey'>
                                            <td>ANNUITY</td>
                                            <td>12.9%</td>
                                            <td>11.2%</td>
                                        </tr>
                                        <tr>
                                            <td>PENSION</td>
                                            <td>12.9%</td>
                                            <td>11.2%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            <Accordion className='bs-table-accordion typ-2'>
                <AccordionSummary
                    expandIcon={<span className='icon icon-chevron-down'></span>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <span className='pair'>Zone 2</span>
                        </li>
                        <li className='acc-item'>
                            <div className='bs-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>PPT&gt;=10</th>
                                            <th>PPT&lt;=10</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='bg-grey'>
                                            <td>PAR</td>
                                            <td>43%</td>
                                            <td>27%</td>
                                        </tr>
                                        <tr>
                                            <td>NO PAR</td>
                                            <td>12%</td>
                                            <td>12.8%</td>
                                        </tr>
                                        <tr className='bg-grey'>
                                            <td>ULIP</td>
                                            <td>18%</td>
                                            <td>11%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                    </ul>
                </AccordionSummary>
                <AccordionDetails>
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <div className='bs-table'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>TERM</td>
                                            <td>22.6%</td>
                                            <td>13.3%</td>
                                        </tr>
                                        <tr className='bg-grey'>
                                            <td>ANNUITY</td>
                                            <td>12.9%</td>
                                            <td>11.2%</td>
                                        </tr>
                                        <tr>
                                            <td>PENSION</td>
                                            <td>12.9%</td>
                                            <td>11.2%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default GridData