import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import { amountUnit } from '../../../../../utils/helper';

function AgentGridData({ data, filterRange, filteredData }) {
    let apiData = data;
    const colname = (values) => {
        if (apiData?.columns.some(colData => colData.colname === values)) {
            const getDisplayName = apiData?.columns.find(colData => colData.colname === values);
            return getDisplayName?.displayName;
        }
    }

    const [currentPage, setCurrentPage] = useState(0);
    const recordsPerPage = 4;
    const totalPages = Math.ceil(filteredData?.length / recordsPerPage);

    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const currentRecords = filteredData?.slice(
        currentPage * recordsPerPage,
        (currentPage + 1) * recordsPerPage
    );


    return (
        <div className='lyt-grid-table'>
            {currentRecords?.map((item, index) => (
                <Accordion className='bs-table-accordion'>
                    <AccordionSummary
                        expandIcon={<span className='icon icon-chevron-down'></span>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <ul className='acc-list'>
                            <li className='acc-item'>
                                <span className='pair'> {item.agentname}</span>
                            </li>
                            {Object.entries(item).map(([key, value], i) => {
                                return (
                                    (key === "policyno" || key === "status" || key === "agentcode") && (

                                        <li className='acc-item' >
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>{value}</span>
                                        </li>
                                    )
                                )
                            })}
                        </ul>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul className='acc-list'>
                            {Object.entries(item).map(([key, value], i) => {
                                if (key === 'annualpremiumamount' || key === 'weightedpremium' || key === 'collectedpremium') {
                                    return (
                                        <li className='acc-item' >
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>{amountUnit(value, filterRange)}</span>
                                        </li>
                                    )
                                } else {
                                    return (
                                        (key === 'applicationno'
                                            || key === 'customername'
                                            || key === 'logindate'
                                            || key === 'nextpremiumduedate'
                                            || key === 'paymentfrequency'
                                            || key === 'productname'
                                            || key === 'freshrenewal'
                                        ) &&
                                        (
                                            <li className='acc-item' >
                                                <span className='key'>{colname(key)}</span>
                                                <span className='pair'> {value}</span>
                                            </li>
                                        )
                                    )
                                }
                            })}
                        </ul>
                    </AccordionDetails>
                </Accordion>

            ))
            }
            <div className='pagination-wrap'>
                <div className='left-wrap'>
                    <IconButton disableRipple className='btn btn-search' onClick={handlePrev} disabled={currentPage === 0}>
                        <span className='icon icon-prev'></span>
                    </IconButton>
                    <IconButton disableRipple className='btn btn-search' onClick={handleNext} disabled={currentPage === totalPages - 1}>
                        <span className='icon icon-next'></span>
                    </IconButton>
                </div>
                <div className='right-wrap'>
                    <span>Page</span>
                    <span>{currentPage + 1}</span>
                    <span>of {totalPages}</span>
                </div>
            </div>
        </div >

    )
}

export default AgentGridData