import React, { useState, useEffect } from 'react';
import loadIcon from '../../../common/assets/images/favicon.png'
import loadingImg from '../../../common/assets/images/three-dots.svg'
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';

function PMS() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            <div className='mb-32'></div>
            <Grid
                container
                rowSpacing={{ md: 5, xs: 3 }}
                columnSpacing={{ md: 1.5, xs: 1.5 }}
            >
                <Grid item md={12} sm={12} xs={12}>
                    <div className="bs-card typ-box yellow">
                        <div className='head-wrap'>
                            <div className='title-wrap'>
                                <div className='icon-wrap'>
                                    <span className="icon icon-account"></span>
                                </div>
                                <h2 className='title'>Personal Information</h2>
                            </div>
                        </div>
                        <div className='cont-wrap'>
                            {loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (
                                <div className='lyt-box'>
                                    <div className='mod-box'>
                                        <h2 className='title'>Code</h2>
                                        <div className='info-wrap'>
                                            <p className='val'>819889</p>
                                        </div>
                                    </div>
                                    <div className='mod-box'>
                                        <h2 className='title'>Month</h2>
                                        <div className='info-wrap'>
                                            <p className='val'>January</p>
                                        </div>
                                    </div>
                                    <div className='mod-box'>
                                        <h2 className='title'>Designation</h2>
                                        <div className='info-wrap'>
                                            <p className='val'>Senior sales manager</p>
                                        </div>
                                    </div>
                                    <div className='mod-box'>
                                        <h2 className='title'>GS start date</h2>
                                        <div className='info-wrap'>
                                            <p className='val'>Jan 1, 2024</p>
                                        </div>
                                    </div>
                                    <div className='mod-box'>
                                        <h2 className='title'>GS vintage</h2>
                                        <div className='info-wrap'>
                                            <p className='val'>M12</p>
                                        </div>
                                    </div>
                                    <div className='mod-box'>
                                        <h2 className='title'>IC38</h2>
                                        <div className='info-wrap'>
                                            <p className='val'>No data</p>
                                        </div>
                                    </div>
                                    <div className='mod-box'>
                                        <h2 className='title'>FG rockstar</h2>
                                        <div className='info-wrap'>
                                            <Button className="btn btn-link">Pending</Button>
                                        </div>
                                    </div>
                                    <div className='mod-box'>
                                        <h2 className='title'>PEP status</h2>
                                        <div className='info-wrap'>
                                            <p className='val'>WFYP PEP</p>
                                        </div>
                                    </div>
                                    <div className='mod-box span2'>
                                        <h2 className='title'>Promotion eligibility</h2>
                                        <div className='info-wrap'>
                                            <Button className="btn btn-link">Criteria not met</Button>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <div className="bs-card typ-box sky-blue">
                        <div className='head-wrap'>
                            <div className='title-wrap'>
                                <div className='icon-wrap'>
                                    <span className="icon icon-list"></span>
                                </div>
                                <h2 className='title'>KPI regular</h2>
                            </div>
                        </div>
                        <div className='cont-wrap'>
                            {loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (
                                <Grid
                                    container
                                    rowSpacing={{ md: 2, xs: 2 }}
                                    columnSpacing={{ md: 2, xs: 2 }}
                                >
                                    <Grid item md={4} sm={12} xs={12}>
                                        <div className="bs-card typ-sub-card yellow">
                                            <div className='head-wrap'>
                                                <div className='title-wrap'>
                                                    <div className='icon-wrap'>
                                                        <span className="icon icon-work"></span>
                                                    </div>
                                                    <h2 className='title'>MTD performance</h2>
                                                </div>
                                            </div>
                                            {loading ? (
                                                <div className='cm-loader'>
                                                    <div className='load-img'>
                                                        <img src={loadIcon} alt='loader' />
                                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='cont-wrap'>

                                                    <div className='bs-table typ-simple'>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Data</th>
                                                                    <th>TAR</th>
                                                                    <th>ACH</th>
                                                                    <th>ACH%</th>
                                                                    <th>WTD%</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>WFYP</td>
                                                                    <td>1,11,23,450</td>
                                                                    <td>7,198,512<span className='icon icon-up'></span></td>
                                                                    <td>15.12%<span className='icon icon-down'></span></td>
                                                                    <td>15.12%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>NCA</td>
                                                                    <td>91,450</td>
                                                                    <td>3273</td>
                                                                    <td>32.73%<span className='icon icon-up'></span></td>
                                                                    <td>32.73%<span className='icon icon-down'></span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>ACMM</td>
                                                                    <td>740</td>
                                                                    <td>1512</td>
                                                                    <td>15.12%<span className='icon icon-up'></span></td>
                                                                    <td>15.12%<span className='icon icon-down'></span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='action-wrap'>
                                                        <Button className="btn btn-default">MTD score:  38%</Button>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </Grid>
                                    <Grid item md={4} sm={12} xs={12}>
                                        <div className="bs-card typ-sub-card light-green">
                                            <div className='head-wrap'>
                                                <div className='title-wrap'>
                                                    <div className='icon-wrap'>
                                                        <span className="icon icon-calendar"></span>
                                                    </div>
                                                    <h2 className='title'>YTD performance</h2>
                                                </div>
                                            </div>
                                            {loading ? (
                                                <div className='cm-loader'>
                                                    <div className='load-img'>
                                                        <img src={loadIcon} alt='loader' />
                                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='cont-wrap'>

                                                    <div className='bs-table typ-simple'>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Data</th>
                                                                    <th>TAR</th>
                                                                    <th>ACH</th>
                                                                    <th>ACH%</th>
                                                                    <th>WTD%</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>WFYP</td>
                                                                    <td>1,11,23,450</td>
                                                                    <td>7,198,512<span className='icon icon-up'></span></td>
                                                                    <td>15.12%<span className='icon icon-down'></span></td>
                                                                    <td>15.12%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>NCA</td>
                                                                    <td>91,450</td>
                                                                    <td>3273</td>
                                                                    <td>32.73%<span className='icon icon-up'></span></td>
                                                                    <td>32.73%<span className='icon icon-down'></span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>ACMM</td>
                                                                    <td>740</td>
                                                                    <td>1512</td>
                                                                    <td>15.12%<span className='icon icon-up'></span></td>
                                                                    <td>15.12%<span className='icon icon-down'></span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='action-wrap'>
                                                        <Button className="btn btn-default">MTD score:  38%</Button>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </Grid>
                                    <Grid item md={4} sm={12} xs={12}>
                                        <div className="bs-card typ-sub-card purple">
                                            <div className='head-wrap'>
                                                <div className='title-wrap'>
                                                    <div className='icon-wrap'>
                                                        <span className="icon icon-hospital"></span>
                                                    </div>
                                                    <h2 className='title'>GTD performance</h2>
                                                </div>
                                            </div>
                                            {loading ? (
                                                <div className='cm-loader'>
                                                    <div className='load-img'>
                                                        <img src={loadIcon} alt='loader' />
                                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='cont-wrap'>

                                                    <div className='bs-table typ-simple'>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Data</th>
                                                                    <th>TAR</th>
                                                                    <th>ACH</th>
                                                                    <th>ACH%</th>
                                                                    <th>WTD%</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>WFYP</td>
                                                                    <td>1,11,23,450</td>
                                                                    <td>7,198,512<span className='icon icon-up'></span></td>
                                                                    <td>15.12%<span className='icon icon-down'></span></td>
                                                                    <td>15.12%</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>NCA</td>
                                                                    <td>91,450</td>
                                                                    <td>3273</td>
                                                                    <td>32.73%<span className='icon icon-up'></span></td>
                                                                    <td>32.73%<span className='icon icon-down'></span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>ACMM</td>
                                                                    <td>740</td>
                                                                    <td>1512</td>
                                                                    <td>15.12%<span className='icon icon-up'></span></td>
                                                                    <td>15.12%<span className='icon icon-down'></span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='action-wrap'>
                                                        <Button className="btn btn-default">MTD score:  38%</Button>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </Grid>
                                </Grid>
                            )}

                        </div>
                    </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <Grid
                        container
                        rowSpacing={{ md: 2, xs: 2 }}
                        columnSpacing={{ md: 1.25, xs: 1.25 }}
                    >
                        <Grid item md={4} sm={12} xs={12}>
                            <div className="bs-card typ-sub-card red">
                                <div className='head-wrap'>
                                    <div className='title-wrap'>
                                        <div className='icon-wrap'>
                                            <span className="icon icon-membership"></span>
                                        </div>
                                        <h2 className='title'>KPI probation</h2>
                                    </div>
                                </div>
                                <div className='cont-wrap'>
                                    {loading ? (
                                        <div className='cm-loader'>
                                            <div className='load-img'>
                                                <img src={loadIcon} alt='loader' />
                                                <img src={loadingImg} alt='loading' className='loading-gif' />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='bs-table typ-simple'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Data</th>
                                                        <th>WFYP</th>
                                                        <th>NCA</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>TAR</td>
                                                        <td>11,11,23,450</td>
                                                        <td>67,198,512</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ACH</td>
                                                        <td>49,660,557</td>
                                                        <td>3273</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shorfall</td>
                                                        <td>60,123,891</td>
                                                        <td>3273</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ACH%</td>
                                                        <td>91%<span className='icon icon-up'></span></td>
                                                        <td>51%<span className='icon icon-up'></span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12}>
                            <div className="bs-card typ-sub-card light-blue">
                                <div className='head-wrap'>
                                    <div className='title-wrap'>
                                        <div className='icon-wrap'>
                                            <span className="icon icon-calendar"></span>
                                        </div>
                                        <h2 className='title'>Persistency criteria</h2>
                                    </div>
                                </div>
                                <div className='cont-wrap'>
                                    {loading ? (
                                        <div className='cm-loader'>
                                            <div className='load-img'>
                                                <img src={loadIcon} alt='loader' />
                                                <img src={loadingImg} alt='loading' className='loading-gif' />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='bs-table typ-simple'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Data</th>
                                                        <th>TAR</th>
                                                        <th>ACH</th>
                                                        <th>ACH%</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>13M</td>
                                                        <td>11,11,23,450</td>
                                                        <td>67,198,512</td>
                                                        <td>15.12%<span className='icon icon-up'></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>25M</td>
                                                        <td>91,450</td>
                                                        <td>3273</td>
                                                        <td>32.73%<span className='icon icon-down'></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>36M</td>
                                                        <td>740</td>
                                                        <td>1512</td>
                                                        <td>15.12%<span className='icon icon-up'></span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12}>
                            <div className="bs-card typ-sub-card pink">
                                <div className='head-wrap'>
                                    <div className='title-wrap'>
                                        <div className='icon-wrap'>
                                            <span className="icon icon-calendar"></span>
                                        </div>
                                        <h2 className='title'>Shortfall</h2>
                                    </div>
                                </div>
                                <div className='cont-wrap'>
                                    {loading ? (
                                        <div className='cm-loader'>
                                            <div className='load-img'>
                                                <img src={loadIcon} alt='loader' />
                                                <img src={loadingImg} alt='loading' className='loading-gif' />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='bs-table typ-simple'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Data</th>
                                                        <th>TAR</th>
                                                        <th>ACH</th>
                                                        <th>ACH%</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>WFYP</td>
                                                        <td>11,11,23,450</td>
                                                        <td>67,198,512</td>
                                                        <td>15.12%<span className='icon icon-up'></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>NCA</td>
                                                        <td>91,450</td>
                                                        <td>3273</td>
                                                        <td>32.73%<span className='icon icon-down'></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>ACMM</td>
                                                        <td>740</td>
                                                        <td>1512</td>
                                                        <td>15.12%<span className='icon icon-up'></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Agent business<br /> share</td>
                                                        <td>740</td>
                                                        <td>1512</td>
                                                        <td>15.12%<span className='icon icon-up'></span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}

export default PMS