import { create } from "zustand";
const findUserType = localStorage.getItem('usertype')
var findChannel = localStorage.getItem('userChannel')
var apiUrlPrefix = ""
if (findChannel !== "BANK" && findChannel !== "ALNT" && findChannel !== "TIED" && findChannel !== "agency" && findChannel !== "DRCT") {
    apiUrlPrefix = "company";
}
export const useGeneralStore = create((set) => ({
    open: null,
    errorMessage: [],
    setOpen: (payload) => {
        set(() => ({
            open: payload,
        }));
    },
    setErrorMessage: (payload) => {
        set(() => ({
            errorMessage: payload,
        }));
    }
}));

export const useFilterRange = create((set) => ({
    filterRange: findUserType === "agent" || findUserType === "Agent" ? 'absolute' : apiUrlPrefix === "company" ? 'cr' : 'lacs',
    setFilterRange: (payload) => {
        set(() => ({
            filterRange: payload,
        }));
    },
}));

const minDate = new Date(new Date().getFullYear(), 0, 1);
const maxDate = new Date();

export const useDateRange = create((set) => ({
    dateValue: [minDate, maxDate],
    setDateValue: (payload) => {
        set(() => ({
            dateValue: payload,
        }));
    },
}));




