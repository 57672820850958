import React from 'react'
import Button from '@mui/material/Button';

function StockTable(props) {
    return (
        <div className={`bs-card ${props.colorName ? props.colorName : ''} ${props.activeClass ? props.activeClass : ''}`} onClick={() => { props.handleLiClick(props.activeIndex) }}>
            <div className='head-wrap'>
                <div className='title-wrap'>
                    <div className='icon-wrap'>
                        <span className={`icon icon-${props.icon ? props.icon : 'wallet'}`}></span>
                    </div>
                    <h2 className='title'>
                        <span>Premium</span>
                        24.4 Cr
                    </h2>
                </div>
                <div className='stock-wrap'>
                    <div className='mod-stock-compare'>
                        <span className='icon icon-up-indicator'></span>
                        <span className='text'>15.13% vs Plan</span>
                    </div>
                    <div className='mod-stock-compare down'>
                        <span className='icon icon-down-indicator'></span>
                        <span className='text'>16.14% vs LYSM</span>
                    </div>
                </div>
            </div>
            <div className='cont-wrap'>
                <div className='bs-table typ-simple'>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>T-1</th>
                                <th>FTD</th>
                                <th>MTD</th>
                                <th>YTD</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Actual</td>
                                <td>99,00,00,000</td>
                                <td>99,00,00,000<span className='icon icon-up'></span></td>
                                <td>99,00,00,000<span className='icon icon-down'></span></td>
                                <td>99,00,00,000</td>
                            </tr>
                            <tr>
                                <td>Plan</td>
                                <td>9,99,99,99,999</td>
                                <td>9,99,99,99,999</td>
                                <td>9,99,99,99,999<span className='icon icon-up'></span></td>
                                <td>9,99,99,99,999</td>
                            </tr>
                            <tr>
                                <td>%</td>
                                <td>-</td>
                                <td>-</td>
                                <td>83%</td>
                                <td>83%</td>
                            </tr>
                            <tr>
                                <td>LYSM</td>
                                <td>90</td>
                                <td>90<span className='icon icon-up'></span></td>
                                <td>90<span className='icon icon-down'></span></td>
                                <td>90</td>
                            </tr>
                            <tr>
                                <td>LYSM %</td>
                                <td>111%</td>
                                <td>111%</td>
                                <td>111%</td>
                                <td>111%</td>
                            </tr>
                            <tr>
                                <td>LM</td>
                                <td>105</td>
                                <td>105<span className='icon icon-down'></span></td>
                                <td>105<span className='icon icon-up'></span></td>
                                <td>105</td>
                            </tr>
                            <tr>
                                <td>LM %</td>
                                <td>111%</td>
                                <td>111%</td>
                                <td>111%</td>
                                <td>111%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='action-wrap cm-hidden-md'>
                    <Button className="btn btn-link typ-underline">VIEW DETAILS</Button>
                </div>
            </div>
            <div className='indicator'></div>
        </div>
    )
}

export default StockTable