import React, { useState, useEffect } from 'react';
import "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js/auto";
import useSize from '../../../../../hooks/common/useSize'

ChartJS.register(...registerables);

function BarChart({ chartData, filterRange }) {
    const winDimensions = useSize();
    const [dataBar, setDataBar] = useState({
        labels: ['Data'],
        datasets: []
    });

    useEffect(() => {
        if (chartData && chartData.length > 0) {
            const datasets = chartData.map(dataset => ({
                label: dataset?.label,
                data: [filterRange === "lacs" ? (dataset?.data / 100000).toFixed(2) : dataset?.data
                    &&
                    filterRange === "cr" ? (dataset?.data / 10000000).toFixed(2) : dataset?.data],
                backgroundColor: dataset?.backgroundColor,
                hidden: dataset?.hidden || false,

            }));
            setDataBar({
                labels: ['Data'],
                datasets
            });
        }
    }, [chartData, filterRange]);

    const options = {
        type: 'horizontalBar',
        indexAxis: 'y',
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                align: 'start',
                labels: {
                    usePointStyle: true,
                    boxWidth: 12,
                    boxHeight: 8,
                    padding: 20,
                    font: {
                        size: `${winDimensions[0] <= 768 ? 9 : (winDimensions[0] <= 1600 ? 12 : 16)}`,
                        family: '"Roboto", sans-serif',
                    },

                    color: '#707070',

                },
                onClick: (e, legendItem, legend) => {
                    const index = legendItem.datasetIndex;
                    const ci = legend.chart;
                    const meta = ci.getDatasetMeta(index);
                    meta.hidden = !meta.hidden;
                    setDataBar(prevState => {
                        const newDatasets = [...prevState.datasets];
                        newDatasets[index].hidden = meta.hidden;
                        return {
                            ...prevState,
                            datasets: newDatasets
                        };
                    });
                    ci.update();
                }
            },
            customDataLabels: {
                id: 'customDataLabels',
                afterDatasetsDraw(chart) {
                    const { ctx, data } = chart;
                    ctx.save();
                    data.datasets.forEach((dataset, datasetIndex) => {
                        if (dataset.hidden) return;
                        chart.getDatasetMeta(datasetIndex).data.forEach((bar, index) => {
                            const value = dataset.data[index];
                            const label = dataset.label;
                            const text = `${label}: ${value}`;
                            ctx.fillStyle = 'black';
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            ctx.font = '14px Roboto';
                            const position = bar.tooltipPosition();

                            // Measure the text to get the width and height
                            const textWidth = ctx.measureText(text).width;
                            const textHeight = 14; // Approximate height of the text

                            // Set background color and draw rectangle
                            ctx.fillStyle = 'rgba(255, 255, 255, 0.8)'; // Background color
                            ctx.fillRect(position.x - textWidth / 2 - 5, position.y - textHeight / 2 - 5, textWidth + 10, textHeight + 10);

                            // Draw text over the background
                            ctx.fillStyle = 'black';
                            ctx.fillText(text, position.x, position.y - 10);
                        });
                    });
                    ctx.restore();
                }
            }
        },
        // barThickness: `${winDimensions[0] <= 768 ? 6 : 12}`,
        barPercentage: 0.4,
        categoryPercentage: 0.85,
        // categorySpacing: 12,
        scales: {
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },
        },
    };
    ChartJS.register({
        id: 'customDataLabels',
        afterDatasetsDraw(chart, args, options) {
            const { ctx, data } = chart;
            ctx.save();
            data.datasets.forEach((dataset, datasetIndex) => {
                if (dataset.hidden) return;

                chart.getDatasetMeta(datasetIndex).data.forEach((bar, index) => {
                    const value = dataset.data[index];
                    const label = dataset.label;
                    const text = `${label}: ${value}`;
                    ctx.fillStyle = 'black';
                    ctx.textAlign = 'left';
                    ctx.textBaseline = 'middle';
                    ctx.font = `${winDimensions[0] <= 768 ? "6px" : "10px"} Roboto`;
                    const position = bar.tooltipPosition();

                    // Measure the text to get the width and height
                    // const textWidth = ctx.measureText(text).width;
                    // const textHeight = 14; 
                    // Approximate height of the text

                    // Set background color and draw rectangle
                    // ctx.fillStyle = 'rgba(255, 255, 255, 0.8)'; 
                    // ctx.fillRect(position.x - textWidth / 2 - 5, position.y - textHeight / 2 - 5, textWidth + 10, textHeight + 10);

                    // Draw text over the background
                    ctx.fillStyle = '#333';
                    // ctx.fillText(text, position.x + 40, position.y - 12);
                    ctx.fillText(text, `${winDimensions[0] <= 768 ? position.x + 5 : position.x + 5}`, position.y);
                });
            });
            ctx.restore();
        }
    });

    return (
        <div className='bs-chart typ-full'>
            <Bar data={dataBar} options={options} width={100} height={`${winDimensions[0] <= 768 ? 100 : 50}`} />
        </div>
    )
}

export default BarChart;
