import React from 'react'
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';

function Filter() {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const top100Films = [
        { policy: 'B7912718912' },
        { policy: 'B7912718912' },
        { policy: 'B7912718912' },
        { policy: 'B7912718912' },
    ]
    return (
        <div className='bs-form'>
            <form>
                <Grid
                    container
                    rowSpacing={{ md: 3, xs: 3 }}
                    columnSpacing={{ md: 3, xs: 3 }}
                >
                    <Grid item md={12} sm={12} xs={12}>
                        <div className='bs-select'>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" className='form-label'>APE</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Age"
                                    onChange={handleChange}
                                    IconComponent={(props) => (
                                        <span
                                            {...props}
                                            className={`icon-chevron-down ${props.className}`}
                                        ></span>
                                    )}
                                    MenuProps={{
                                        classes: {
                                            paper: "bs-menu-dropdown",
                                        },
                                    }}
                                    displayEmpty
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value={10}>APE Ten</MenuItem>
                                    <MenuItem value={20}>APE Twenty</MenuItem>
                                    <MenuItem value={30}>APE Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <div className='bs-select'>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" className='form-label'>WFYP</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Age"
                                    onChange={handleChange}
                                    IconComponent={(props) => (
                                        <span
                                            {...props}
                                            className={`icon-chevron-down ${props.className}`}
                                        ></span>
                                    )}
                                    MenuProps={{
                                        classes: {
                                            paper: "bs-menu-dropdown",
                                        },
                                    }}
                                    displayEmpty
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value={10}>WFYP Ten</MenuItem>
                                    <MenuItem value={20}>WFYP Twenty</MenuItem>
                                    <MenuItem value={30}>WFYP Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <div className='bs-select'>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" className='form-label'>CP</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Age"
                                    onChange={handleChange}
                                    IconComponent={(props) => (
                                        <span
                                            {...props}
                                            className={`icon-chevron-down ${props.className}`}
                                        ></span>
                                    )}
                                    MenuProps={{
                                        classes: {
                                            paper: "bs-menu-dropdown",
                                        },
                                    }}
                                    displayEmpty
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value={10}>CP Ten</MenuItem>
                                    <MenuItem value={20}>CP Twenty</MenuItem>
                                    <MenuItem value={30}>CP Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <div className='cm-divider'></div>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <div className='bs-search'>
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                options={top100Films.map((option) => option.policy)}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        InputProps={{
                                            ...params.InputProps,

                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <span className='icon icon-search'></span>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder='Search' />
                                )}
                                classes={{ paper: 'bs-menu-dropdown' }}

                            />
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default Filter