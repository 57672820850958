import React, { useState, useEffect } from 'react';
import loadIcon from '../../../common/assets/images/favicon.png'
import loadingImg from '../../../common/assets/images/three-dots.svg'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import checkImg from '../../../common/assets/images/check.png'
import errorImg from '../../../common/assets/images/error.png'

function Statement() {
    const [loginLoader, setLoginLoader] = useState(false);

    const [statementCategory, setStatementCategory] = React.useState('');
    const [downloadUrl, setDownloadUrl] = React.useState('');
    const [downloadMsg, setDownloadMsg] = React.useState(true);

    const handleStatementCategory = (event) => {
        setStatementCategory(event.target.value);
    };

    const [lang, setlang] = React.useState('English');

    const handleLang = (event) => {
        setlang(event.target.value);
    };

    const currentYear = new Date().getFullYear();
    const getYearsArray = (startYear) => {
        const years = [];

        for (let year = startYear; year <= currentYear; year++) {
            years.push(year);
        }

        return years;
    };

    const monthsArray = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ]

    const yearsArray = getYearsArray(2022);


    const [loading, setLoading] = useState(true);

    const validationSchema = Yup.object(
        {
            yearly: Yup.string()
                .required('Please select year'),
            monthly: statementCategory === "Monthly" || statementCategory === "Weekly" ? Yup.string().required('Please select month') : Yup.string(),
            weekly: statementCategory === "Weekly" ? Yup.string().required('Please select weekly cycle') : Yup.string(),
        }
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    const agent_code = localStorage.getItem('userId')
    const handleSubmit = async (values, { resetForm }) => {
        var year = values.yearly
        var month = monthsArray.indexOf(values.monthly)
        var day = ""
        var startDate = ""
        if (values.weekly === "C1") {
            day = "01"
        } else if (values.weekly === "C2") {
            day = "10"
        } else if (values.weekly === "C3") {
            day = "18"
        } else {
            day = "26"
        }
        if (statementCategory === "Monthly") {
            startDate = `01/${month < 10 ? '0' + month : month}/${year}`
        } else if (statementCategory === "Weekly") {
            startDate = `${day}/${month < 10 ? '0' + month : month}/${year}`
        } else {
            startDate = `01/04/${year}`
        }
        setLoginLoader(true);
        const data = JSON.stringify({
            "language": lang,
            // "agent_code": "80168404",
            "agent_code": agent_code,
            "start_date": startDate,
            "type": statementCategory
        });
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://lfagentapigw-rsg.azure-api.net/AgentHLWebservices/commissionstatement/CommissionStmtAPI/CommStmtApi',
            headers: {
                'IR_USERID': 'modeler',
                'Authorization': 'Basic aGx1c2VyOmgzckAxOTcwZy5D',
                'Ocp-Apim-Subscription-Key': '378b2be0c6ff40cf8dca2ec15782dfb3',
                'Content-Type': 'application/json'
            },
            data: data
        }
        axios.request(config)
            .then(
                (response) => {
                    console.log(response, 'response')
                    if (response.status === 200) {
                        setLoginLoader(false);
                        setOpen(true)
                        setDownloadUrl(response?.data[1].replace('URL: ', ''))
                        resetForm()
                    } else {
                        setLoginLoader(false);
                        setOpen(true)
                        setDownloadMsg(false)
                        resetForm()
                    }
                }).catch((error) => {
                    console.log(error, "error");
                    setLoginLoader(false);
                    setOpen(true)
                    setDownloadMsg(false)
                });

    }
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const handleDownload = () => {
        window.location.href = downloadUrl
        console.log(downloadUrl)
        setOpen(false)
    };

    return (
        <div className='container'>
            <Grid
                container
                rowSpacing={{ md: 5, xs: 3 }}
                columnSpacing={{ md: 3, xs: 3 }}
            >
                <Grid item md={12} sm={12} xs={12}>
                    <div className='bs-panel typ-border typ-transparent'>
                        <div className='panel-head'>
                            <div className='left-wrap'>
                                <h2 className='p-title'>SELECT STATEMENT</h2>
                            </div>
                        </div>
                        <div className='panel-content'>
                            {loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (
                                <div className='bs-form'>
                                    <Formik
                                        initialValues={{ yearly: '', monthly: '', weekly: '' }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    // onSubmit={(values) => {
                                    //     console.log(values);
                                    // }}
                                    >
                                        {({ errors, touched, values, handleChange, handleBlur }) => (
                                            <Form>
                                                <Grid
                                                    container
                                                    rowSpacing={{ md: 4, xs: 3 }}
                                                    columnSpacing={{ md: 5, xs: 3 }}
                                                >
                                                    <Grid item md={3} sm={12} xs={12}>
                                                        <div className='bs-select'>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="demo-simple-select-label" className='form-label'>Commission Statement</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={statementCategory}
                                                                    renderValue={(selected) => statementCategory === "" ? "Select from list" : statementCategory}
                                                                    onChange={handleStatementCategory}
                                                                    IconComponent={(props) => (
                                                                        <span
                                                                            {...props}
                                                                            className={`icon-chevron-down ${props.className}`}
                                                                        ></span>
                                                                    )}
                                                                    MenuProps={{
                                                                        classes: {
                                                                            paper: "bs-menu-dropdown",
                                                                        },
                                                                    }}
                                                                    displayEmpty
                                                                >
                                                                    <MenuItem value="Yearly">Yearly</MenuItem>
                                                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                                                    <MenuItem value="Weekly">Weekly</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={3} sm={12} xs={12}>
                                                        <div className='form-group'>
                                                            <div className='bs-select'>
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-simple-select-label" className='form-label'>Select Language</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={lang}
                                                                        onChange={handleLang}
                                                                        IconComponent={(props) => (
                                                                            <span
                                                                                {...props}
                                                                                className={`icon-chevron-down ${props.className}`}
                                                                            ></span>
                                                                        )}
                                                                        MenuProps={{
                                                                            classes: {
                                                                                paper: "bs-menu-dropdown",
                                                                            },
                                                                        }}
                                                                        displayEmpty
                                                                    >
                                                                        <MenuItem value="English">English</MenuItem>
                                                                        <MenuItem value="Hindi">Hindi</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    {statementCategory !== "" &&
                                                        <Grid item md={12} sm={12} xs={12}>
                                                            <div className='form-group'>
                                                                <Grid
                                                                    container
                                                                    rowSpacing={{ md: 4, xs: 2 }}
                                                                    columnSpacing={{ md: 5, xs: 3 }}
                                                                    alignItems="end"
                                                                >

                                                                    <Grid item md={3} sm={12} xs={12}>
                                                                        <div className='form-group'>
                                                                            <label className='form-label'>Select Financial Year</label>
                                                                            <div className='bs-select typ-normal'>
                                                                                <FormControl fullWidth>
                                                                                    <Select
                                                                                        labelId="demo-simple-select-label"
                                                                                        id="yearly"
                                                                                        name="yearly"
                                                                                        value={values.yearly}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        renderValue={(selected) => values.yearly === "" ? "Select Year" : values.yearly}
                                                                                        IconComponent={(props) => (
                                                                                            <span
                                                                                                {...props}
                                                                                                className={`icon-chevron-down ${props.className}`}
                                                                                            ></span>
                                                                                        )}
                                                                                        MenuProps={{
                                                                                            classes: {
                                                                                                paper: "bs-menu-dropdown",
                                                                                            },
                                                                                        }}
                                                                                        displayEmpty
                                                                                    >
                                                                                        {yearsArray.map((item, index) => <MenuItem value={item} key={index}>{item}</MenuItem>)}
                                                                                    </Select>
                                                                                    <FormHelperText>{touched.yearly && errors.yearly ? errors.yearly : ''}</FormHelperText>
                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                    {statementCategory !== "Yearly" &&
                                                                        <Grid item md={3} sm={12} xs={12}>
                                                                            <div className='form-group'>
                                                                                <label className='form-label'>Select Month</label>
                                                                                <div className='bs-select typ-normal'>
                                                                                    <FormControl fullWidth>
                                                                                        <Select
                                                                                            labelId="demo-simple-select-label"
                                                                                            id="monthly"
                                                                                            name="monthly"
                                                                                            value={values.monthly}
                                                                                            onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            renderValue={(selected) => values.monthly === "" ? "Select Month" : values.monthly}
                                                                                            IconComponent={(props) => (
                                                                                                <span
                                                                                                    {...props}
                                                                                                    className={`icon-chevron-down ${props.className}`}
                                                                                                ></span>
                                                                                            )}
                                                                                            MenuProps={{
                                                                                                classes: {
                                                                                                    paper: "bs-menu-dropdown",
                                                                                                },
                                                                                            }}
                                                                                            displayEmpty
                                                                                        >
                                                                                            {monthsArray.map((item, index) => <MenuItem value={item} key={index}>{item}</MenuItem>)}
                                                                                        </Select>
                                                                                        <FormHelperText>{touched.monthly && errors.monthly ? errors.monthly : ''}</FormHelperText>
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    }
                                                                    {statementCategory !== "Yearly" && statementCategory !== "Monthly" &&
                                                                        <Grid item md={3} sm={12} xs={12}>
                                                                            <div className='form-group'>
                                                                                <label className='form-label'>Select Weekly Cycle</label>
                                                                                <div className='bs-select typ-normal'>
                                                                                    <FormControl fullWidth>
                                                                                        <Select
                                                                                            labelId="demo-simple-select-label"
                                                                                            id="weekly"
                                                                                            name="weekly"
                                                                                            value={values.weekly}
                                                                                            onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            renderValue={(selected) => values.weekly === "" ? "Select Week" : values.weekly}
                                                                                            IconComponent={(props) => (
                                                                                                <span
                                                                                                    {...props}
                                                                                                    className={`icon-chevron-down ${props.className}`}
                                                                                                ></span>
                                                                                            )}
                                                                                            MenuProps={{
                                                                                                classes: {
                                                                                                    paper: "bs-menu-dropdown",
                                                                                                },
                                                                                            }}
                                                                                            displayEmpty
                                                                                        >
                                                                                            <MenuItem value="C1">C1</MenuItem>
                                                                                            <MenuItem value="C2">C2</MenuItem>
                                                                                            <MenuItem value="C3">C3</MenuItem>
                                                                                            <MenuItem value="C4">C4</MenuItem>
                                                                                        </Select>
                                                                                        <FormHelperText>{touched.weekly && errors.weekly ? errors.weekly : ''}</FormHelperText>
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    }

                                                                    <Grid item md={3} sm={12} xs={12}>
                                                                        <div className='action-wrap'>
                                                                            <Button type="submit" className="btn btn-default">Generate Statement <span className='icon icon-download'></span></Button>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            )}

                        </div>
                    </div>
                </Grid>
            </Grid>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='bs-modal'
            >
                <div className='modal-wrap'>
                    <span className='icon icon-close close-btn' onClick={handleClose}></span>
                    <div className='icon-wrap'>
                        <img src={downloadMsg ? checkImg : errorImg} alt='checkImg' />
                    </div>
                    <h3 className='m-title'>
                        {downloadMsg ? "Your request for Commission Statement has been generated successfully!" : "Your request for Commission Statement has not been generated"}
                    </h3>
                    <div className='action-wrap'>
                        {downloadMsg ?
                            <Button className="btn btn-default" onClick={handleDownload}>Download Now</Button> :
                            <Button className="btn btn-default" onClick={handleClose}>Please Try Again</Button>
                        }
                    </div>
                </div>
            </Modal>
            {loginLoader &&
                <div className='loader-wrap opaque'>
                    <div className="img-wrap">
                        <div className="loader"></div>
                        <span className="text">Loading...</span>
                    </div>
                </div>
            }
        </div>
    )
}
export default Statement