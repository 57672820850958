const formatValue = (value) => {
    if (value === "None" || value === "-") {
        return value
    }
    const floatValue = parseFloat(value);
    if (typeof value == 'number') {
        if (value?.toLocaleString()?.includes('%')) {
            const newValue = value?.split("%");
            const formatNewValue = `${Number(newValue[0])?.toFixed(2)}%`
            // Check if the value contains a percentage sign
            return formatNewValue; // If it contains a percentage sign, return the exact value
        } else if (Number(floatValue) % 1 !== 0) { // Check if the value has decimals
            return Number(floatValue).toFixed(2); // If it has decimals, round to two decimal places
        }
        else {
            return Number(value).toLocaleString(); // If it's an integer, return the exact value
        }
    } else {
        if (value?.includes('%')) {
            const newValue = value?.split("%");
            const formatNewValue = `${Number(newValue[0])?.toFixed(2)}%`
            // Check if the value contains a percentage sign
            return formatNewValue; // If it contains a percentage sign, return the exact value
        } else if (Number(floatValue) % 1 !== 0) { // Check if the value has decimals
            return Number(floatValue).toFixed(2); // If it has decimals, round to two decimal places
        }
        else {
            return Number(value).toLocaleString(); // If it's an integer, return the exact value
        }
    }

};

const amountUnit = (value, filterRange) => {
    if (filterRange === "lacs") {
        return (value / 100000).toFixed(2).toLocaleString()
        // return Number((value / 100000).toFixed(2)).toLocaleString()
    } else if (filterRange === "cr") {
        return (value / 10000000).toFixed(2).toLocaleString()
        // return Number((value / 10000000).toFixed(2)).toLocaleString()
    } else {
        return Number(value).toLocaleString()
    }
};

export { formatValue, amountUnit };