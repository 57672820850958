import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function PersistencyTable() {

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const top100Films = [
        { policy: 'B7912718912' },
        { policy: 'B7912718912' },
        { policy: 'B7912718912' },
        { policy: 'B7912718912' },
    ]
    return (
        <div className='bs-panel light-blue'>
            <div className='panel-head'>
                <div className='left-wrap'>
                    <h2 className='p-title'>PREMIUM DETAILS</h2>
                </div>
                <div className='right-wrap'>
                    <div className='bs-search'>
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={top100Films.map((option) => option.policy)}
                            renderInput={(params) => (
                                <TextField {...params}
                                    InputProps={{
                                        ...params.InputProps,

                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <span className='icon icon-search'></span>
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder='Search' />
                            )}
                            classes={{ paper: 'bs-menu-dropdown' }}

                        />
                    </div>
                    <div className='bs-select typ-sm'>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleChange}
                                IconComponent={(props) => (
                                    <span
                                        {...props}
                                        className={`icon-chevron-down ${props.className}`}
                                    ></span>
                                )}
                                MenuProps={{
                                    classes: {
                                        paper: "bs-menu-dropdown",
                                    },
                                }}
                                displayEmpty
                            >
                                <MenuItem value="">Filters</MenuItem>
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
            <div className='panel-content'>
                <div className='bs-table typ-persistency'>
                    <table>
                        <thead>
                            <tr>
                                <th rowSpan='2'>Agent name</th>
                                <th colSpan='2'>13th MONTH PAID</th>
                                <th colSpan='2'>13th MONTH NOT PAID</th>
                                <th colSpan='2'>13th MONTH TOTAL</th>
                                <th colSpan='2'>13th MONTH %</th>
                                <th colSpan='2'>MTD</th>
                            </tr>
                            <tr>
                                <th>NOP</th>
                                <th>APE</th>
                                <th>NOP</th>
                                <th>APE</th>
                                <th>NOP</th>
                                <th>APE</th>
                                <th>NOP</th>
                                <th>APE</th>
                                <th>NOP</th>
                                <th>APE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sanjeev Kumar Singh</td>
                                <td>3902</td>
                                <td>3903.1</td>
                                <td>4021</td>
                                <td>3902</td>
                                <td>1902.2</td>
                                <td>4091.9</td>
                                <td>
                                    <div className='mod-tag'>
                                        <span className='value'>29%</span>
                                        <span className='icon icon-trending'></span>
                                    </div>
                                </td>
                                <td>
                                    <div className='mod-tag down'>
                                        <span className='value'>29%</span>
                                        <span className='icon icon-trending'></span>
                                    </div>
                                </td>
                                <td>43</td>
                                <td>23</td>
                            </tr>
                            <tr>
                                <td>Prashant Todi</td>
                                <td>3902</td>
                                <td>3903.1</td>
                                <td>4021</td>
                                <td>3902</td>
                                <td>1902.2</td>
                                <td>4091.9</td>
                                <td>
                                    <div className='mod-tag'>
                                        <span className='value'>29%</span>
                                        <span className='icon icon-trending'></span>
                                    </div>
                                </td>
                                <td>
                                    <div className='mod-tag down'>
                                        <span className='value'>29%</span>
                                        <span className='icon icon-trending'></span>
                                    </div>
                                </td>
                                <td>43</td>
                                <td>23</td>
                            </tr>
                            <tr>
                                <td>Vaibhav Kansal</td>
                                <td>3902</td>
                                <td>3903.1</td>
                                <td>4021</td>
                                <td>3902</td>
                                <td>1902.2</td>
                                <td>4091.9</td>
                                <td>
                                    <div className='mod-tag'>
                                        <span className='value'>29%</span>
                                        <span className='icon icon-trending'></span>
                                    </div>
                                </td>
                                <td>
                                    <div className='mod-tag down'>
                                        <span className='value'>29%</span>
                                        <span className='icon icon-trending'></span>
                                    </div>
                                </td>
                                <td>43</td>
                                <td>23</td>
                            </tr>
                            <tr>
                                <td>Vikas Choudhury</td>
                                <td>3902</td>
                                <td>3903.1</td>
                                <td>4021</td>
                                <td>3902</td>
                                <td>1902.2</td>
                                <td>4091.9</td>
                                <td>
                                    <div className='mod-tag'>
                                        <span className='value'>29%</span>
                                        <span className='icon icon-trending'></span>
                                    </div>
                                </td>
                                <td>
                                    <div className='mod-tag down'>
                                        <span className='value'>29%</span>
                                        <span className='icon icon-trending'></span>
                                    </div>
                                </td>
                                <td>43</td>
                                <td>23</td>
                            </tr>
                            <tr>
                                <td>Girish Agarwal</td>
                                <td>3902</td>
                                <td>3903.1</td>
                                <td>4021</td>
                                <td>3902</td>
                                <td>1902.2</td>
                                <td>4091.9</td>
                                <td>
                                    <div className='mod-tag'>
                                        <span className='value'>29%</span>
                                        <span className='icon icon-trending'></span>
                                    </div>
                                </td>
                                <td>
                                    <div className='mod-tag down'>
                                        <span className='value'>29%</span>
                                        <span className='icon icon-trending'></span>
                                    </div>
                                </td>
                                <td>43</td>
                                <td>23</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>GRAND TOTAL</td>
                                <td>13902</td>
                                <td>1070.3</td>
                                <td>19592</td>
                                <td>17820</td>
                                <td>14590</td>
                                <td>10782</td>
                                <td>
                                    <div className='mod-tag dark'>
                                        <span className='value'>29%</span>
                                        <span className='icon icon-trending'></span>
                                    </div>
                                </td>
                                <td>
                                    <div className='mod-tag dark down'>
                                        <span className='value'>29%</span>
                                        <span className='icon icon-trending'></span>
                                    </div>
                                </td>
                                <td>215</td>
                                <td>181.2</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PersistencyTable