import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import useSize from '../../../../../hooks/common/useSize'

ChartJS.register(ArcElement, Tooltip, Legend);

function Donut() {
    const winDimensions = useSize();
    const data = {
        labels: ['Par 2.52 CR (35%)', 'No Par 1.44 CR (20%)', 'ULIP 1.08 CR (15%)', 'Term 1.15 CR (16%)', 'Annuity 0.72 CR (10%)', 'Pension 0.28 CR (4%)'],
        datasets: [
            {
                label: 'SALES BY ZONE',
                data: [20, 20, 15, 16, 14, 15],
                backgroundColor: [
                    '#43A4EC',
                    '#3DDF94',
                    '#FFD643',
                    '#FF714E',
                    '#B4C6D9',
                    '#E463EF',
                ],
                // borderColor: [
                //     'rgba(255, 99, 132, 1)',
                //     'rgba(54, 162, 235, 1)',
                //     'rgba(255, 206, 86, 1)',
                //     'rgba(75, 192, 192, 1)',
                //     'rgba(153, 102, 255, 1)',
                //     'rgba(255, 159, 64, 1)',
                // ],
                borderWidth: 0,
            },
        ],
    };
    const options = {
        plugins: {
            title: {
                display: false,
                text: 'PPT<=10',
            },
            legend: {
                display: false,
                position: `${winDimensions[0] <= 768 ? 'bottom' : 'right'}`,
                align: "center",
                // fullWidth: true,
                labels: {
                    usePointStyle: true,
                    boxWidth: 12,
                    boxHeight: 8,
                    padding: 15,
                    font: {
                        size: `${winDimensions[0] <= 768 ? 12 : 14}`,
                        family: '"Roboto", sans-serif',
                    },
                    color: `${winDimensions[0] <= 768 ? '#333333' : '#707070'}`,
                },
            },
            tooltip: {
                enabled: true,
            },
        },
        cutout: "80%",
        spacing: 10,
    };






    return (
        <div className='donut-graph-wrap'>
            <Doughnut data={data} options={options} />
        </div>
    )
}

export default Donut