import React, { useEffect } from "react";
import Header from "../common/components/Header";
import Menu from "../common/components/Menu";
import shape1 from '../common/assets/images/reactangle.png'
import shape2 from '../common/assets/images/dashboard-shape2.png'
import { useLocation } from 'react-router-dom';

const AuthLayout = (props) => {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation();

    return (
        <React.Fragment>
            <div className={`lyt-dashboard ${location?.pathname === "/support" || location?.pathname === "/statement" ? 'typ-support' : ''}`}>
                <div className='shape1'>
                    <img src={shape1} alt='shape' />
                </div>
                <div className='shape2'>
                    <img src={shape2} alt='shape' />
                </div>
                <div className='container'>
                    <Header />
                    <div className={`${location?.pathname === "/support" || location?.pathname === "/statement" ? 'lyt-support' : 'lyt-content'}`}>
                        {location?.pathname !== "/support" && location?.pathname !== "/statement" &&
                            <Menu />
                        }
                        {props.children}
                    </div>
                </div>
                <div className="bs-footer">
                    <p>Copyright © 2024. Future Generali India Life Insurance Company Ltd. All Rights Reserved.</p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AuthLayout;