import React, { useEffect, useRef } from "react";
import logo from '../../assets/images/logo.svg'
import profile from '../../assets/images/profile.png'
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { NavLink, Link, useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
// import Genie from "../Genie";
import Modal from '@mui/material/Modal';
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
// import Snackbar from '@mui/material/Snackbar';
// import Alert from '@mui/material/Alert';

function Header() {
    // const [profileDropdown, setProfileDropdown] = React.useState(false)
    const userName = localStorage.getItem("username");
    // const handleProfileDropdown = () => {
    //     setProfileDropdown(!profileDropdown)
    // }
    const [menuOpen, setMenuOpen] = React.useState(false)
    const handleMenu = () => {
        setMenuOpen(!menuOpen)
        if (menuOpen) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
    }

    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // const [tooltipOpen, setTooltipOpen] = React.useState(true);

    // const handleTooltipClick = () => {
    //     setTooltipOpen(true);
    // };

    // const handleTooltipClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }

    //     setTooltipOpen(false);
    // };

    const location = useLocation();
    const handleLogout = () => {
        localStorage.clear();
        window.location = "/";
        // window.location.reload();
    }
    // const date = new Date();
    // let hour = date.getHours();

    const profileWrapRef = useRef(null);
    const linkWrafRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            // if (profileWrapRef.current && !profileWrapRef.current.contains(event.target)) {
            //     setProfileDropdown(false);
            // }
            if (linkWrafRef.current && !linkWrafRef.current.contains(event.target)) {
                setMenuOpen(false);
                document.body.style.overflow = 'auto';
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [profileWrapRef]);



    return (
        <>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <header className="bs-header">
                        <div className='left-wrap'>
                            <div className='menu-action cm-hidden-md'>
                                <NavLink to="/dashboard">
                                    <IconButton disableRipple className='btn'>
                                        <span className='icon icon-home'></span>
                                    </IconButton>
                                </NavLink>
                            </div>
                            <div className='logo-wrap'>
                                <img src={logo} alt='logo' />
                            </div>
                            <Button className="btn btn-link cm-hidden-sm">Sales Analytics Tool <span className="text">v2.2.0</span></Button>
                        </div>
                        <div className='right-wrap'>
                            <div className='menu-action'>
                                <IconButton disableRipple className='btn' onClick={handleMenu}>
                                    <span className='icon icon-menu'></span>
                                </IconButton>
                            </div>
                            <div className={`link-wrap ${menuOpen ? 'open' : ''}`} ref={linkWrafRef}>
                                <div className="head-wrap">
                                    <div className="mod-profile">
                                        <div className='img-wrap'>
                                            <img src={profile} alt='profile' />
                                        </div>
                                        <div className="name-wrap">
                                            <p><strong>{userName !== "" ? userName : "Username"}</strong>
                                                <span className='sub-text'>User Designation</span></p>
                                        </div>
                                    </div>
                                    {/* <h2 className='title'>Menu</h2> */}
                                    <IconButton className='btn' onClick={handleMenu}>
                                        <span className='icon icon-close'></span>
                                    </IconButton>
                                </div>


                                <NavLink to="/dashboard" activeclassname="active" className={`link ${location?.pathname !== "/support" && location?.pathname !== "/statement" ? "active" : ""}`} onClick={handleMenu}>
                                    <Button className="btn btn-icon" disableRipple><span className='icon icon-dashboard'></span> Dashboard</Button>
                                </NavLink>
                                <NavLink to="/support" activeclassname="active" className='link' onClick={handleMenu}>
                                    <Button className="btn btn-icon" disableRipple><span className='icon icon-account'></span> Support</Button>
                                </NavLink>
                                {/* <NavLink to="/statement" activeclassname="active" className='link' onClick={handleMenu}>
                                    <Button className="btn btn-icon" disableRipple><span className='icon icon-description'></span> Statements & Opportunities</Button>
                                </NavLink> */}
                                <div className='link'>
                                    <Button className="btn btn-icon" onClick={handleLogout}><span className='icon icon-logout'></span> Log out</Button>
                                </div>
                            </div>
                            {/* <div className='notification-wrap'>
                                <IconButton className='btn' onClick={toggleDrawer(anchor, true)}>
                                    <span className='icon icon-notification'></span>
                                </IconButton>
                                <span className='count'>10</span>
                            </div> */}
                            {/* <div className='profile-wrap' onClick={handleProfileDropdown} ref={profileWrapRef}>
                                <div className='img-wrap'>
                                    <img src={profile} alt='profile' />
                                </div>
                                <IconButton>
                                    <span className='icon icon-chevron-down'></span>
                                </IconButton>
                                <div className={`menu-dropdown ${profileDropdown ? 'active' : ''}`}>
                                    <ul className='menu-list'>
                                        <li className='menu-item'>
                                            <Button className="btn btn-icon"><span className='icon icon-user'></span> <span>{userName !== "" ? userName : "Username"}<span className='sub-text'>User Designation</span></span></Button>
                                        </li>
                                        <li className='menu-item'>
                                            <Button className="btn btn-icon" onClick={handleLogout}><span className='icon icon-logout'></span> Log out</Button>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className='message-wrap'>
                            <p>{(hour > 5 && hour < 12 ? 'Good Morning' : 'Good Evening') || (hour > 12 && hour < 18 ? 'Good Afternoon' : 'Good Evening')}</p>
                            <p><strong>{userName !== "" ? userName : "Username"}</strong></p>
                        </div> */}
                    </header>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        className='bs-drawer typ-notification'
                    >
                        <div className='head-wrap'>
                            <h2 className='d-title'>Notifications</h2>
                            <IconButton disableRipple className='btn close-btn' onClick={toggleDrawer(anchor, false)}>
                                <span className='icon icon-close'></span>
                            </IconButton>
                        </div>
                        <div className='cont-wrap'>
                            <Link to="#" className='link'>
                                <div className='mod-notification'>
                                    <div className='info-wrap'>
                                        <h3 className='n-title'>Data table update</h3>
                                        <p className='n-desc'>Lorem Ipsum dolor sit amet new graph added</p>
                                        <span className='n-date'>12/09/2023</span>
                                    </div>
                                    <span className='icon icon-chevron-right'></span>
                                </div>
                            </Link>
                            <Link to="#" className='link'>
                                <div className='mod-notification'>
                                    <div className='info-wrap'>
                                        <h3 className='n-title'>Data table update</h3>
                                        <p className='n-desc'>Lorem Ipsum dolor sit amet new graph added</p>
                                        <span className='n-date'>12/09/2023</span>
                                    </div>
                                    <span className='icon icon-chevron-right'></span>
                                </div>
                            </Link>
                            <Link to="#" className='link'>
                                <div className='mod-notification'>
                                    <div className='info-wrap'>
                                        <h3 className='n-title'>Data table update</h3>
                                        <p className='n-desc'>Lorem Ipsum dolor sit amet new graph added</p>
                                        <span className='n-date'>12/09/2023</span>
                                    </div>
                                    <span className='icon icon-chevron-right'></span>
                                </div>
                            </Link>
                            <Link to="#" className='link'>
                                <div className='mod-notification'>
                                    <div className='info-wrap'>
                                        <h3 className='n-title'>Data table update</h3>
                                        <p className='n-desc'>Lorem Ipsum dolor sit amet new graph added</p>
                                        <span className='n-date'>12/09/2023</span>
                                    </div>
                                    <span className='icon icon-chevron-right'></span>
                                </div>
                            </Link>
                            <Link to="#" className='link'>
                                <div className='mod-notification'>
                                    <div className='info-wrap'>
                                        <h3 className='n-title'>Data table update</h3>
                                        <p className='n-desc'>Lorem Ipsum dolor sit amet new graph added</p>
                                        <span className='n-date'>12/09/2023</span>
                                    </div>
                                    <span className='icon icon-chevron-right'></span>
                                </div>
                            </Link>
                            <Link to="#" className='link'>
                                <div className='mod-notification'>
                                    <div className='info-wrap'>
                                        <h3 className='n-title'>Data table update</h3>
                                        <p className='n-desc'>Lorem Ipsum dolor sit amet new graph added</p>
                                        <span className='n-date'>12/09/2023</span>
                                    </div>
                                    <span className='icon icon-chevron-right'></span>
                                </div>
                            </Link>
                            <Link to="#" className='link'>
                                <div className='mod-notification'>
                                    <div className='info-wrap'>
                                        <h3 className='n-title'>Data table update</h3>
                                        <p className='n-desc'>Lorem Ipsum dolor sit amet new graph added</p>
                                        <span className='n-date'>12/09/2023</span>
                                    </div>
                                    <span className='icon icon-chevron-right'></span>
                                </div>
                            </Link>
                        </div>
                    </Drawer>
                </React.Fragment>
            ))
            }
            {/* <Genie /> */}
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='bs-modal'
            >
                <div className='modal-wrap'>
                    <span className='icon icon-close close-btn' onClick={handleClose}></span>
                    <h2 className='main-title'>Change Password</h2>
                    <div className="bs-form">
                        <form>
                            <Grid
                                container
                                rowSpacing={{ md: 2, xs: 2 }}
                                columnSpacing={{ md: 3, xs: 4 }}
                            >
                                <Grid item md={12} sm={12} xs={12}>
                                    <div className='form-group'>
                                        <label className='form-label'>Old Password</label>
                                        <TextField id="outlined-basic" variant="outlined" className='form-control' />
                                    </div>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <div className='form-group'>
                                        <label className='form-label'>New Password</label>
                                        <TextField id="outlined-basic" variant="outlined" className='form-control' />
                                    </div>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <div className='form-group'>
                                        <label className='form-label'>Confirm Password</label>
                                        <TextField id="outlined-basic" variant="outlined" className='form-control' />
                                    </div>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <div className='action-wrap'>
                                        <Button className="btn btn-default" onClick={handleClose}>Save</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Modal>
            {/* <Snackbar
                open={tooltipOpen}
                autoHideDuration={6000}
                onClose={handleTooltipClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                className="bs-tooltip"
            >
                <Alert
                    onClose={handleTooltipClose}
                    severity="success"
                    variant="filled"
                >
                    This is a success Alert inside a Snackbar!
                </Alert>
            </Snackbar> */}
        </ >

    )
}

export default Header