import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import { amountUnit, formatValue } from '../../../../../utils/helper';
import Button from '@mui/material/Button';

function CompanyGridData({ data, filterRange, cardName, advisorcode, setAdvisorcode, filteredData, handleCompAdvisorClick }) {
    let apiData = data;
    const colname = (values) => {
        if (apiData?.columnname.some(colData => colData.colname === values)) {
            const getDisplayName = apiData?.columnname.find(colData => colData.colname === values);
            return getDisplayName?.displayName;
        }
    }

    const [currentPage, setCurrentPage] = useState(0);
    const recordsPerPage = 4;
    const totalPages = Math.ceil(filteredData?.length / recordsPerPage);

    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const currentRecords = filteredData?.slice(
        currentPage * recordsPerPage,
        (currentPage + 1) * recordsPerPage
    );


    return (
        <div className='lyt-grid-table'>
            {currentRecords?.map((item, index) => (
                <Accordion className='bs-table-accordion'>
                    <AccordionSummary
                        expandIcon={<span className='icon icon-chevron-down'></span>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <ul className='acc-list'>
                            <li className='acc-item' >
                                <span className='pair'>{item.agentname} - {item.channel}</span>
                            </li>
                            {Object.entries(item).map(([key, value], i) => {
                                return (
                                    (key === "mtdach%" || key === "ytdach%" || key === "lm%" || key === "par%" || key === "nonpar%" || key === "singlepremium%" || key === "term%" || key === "ulip%" || key === "health%") && (
                                        <li className='acc-item' >
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                <div className={`mod-tag ${value <= 85 ? "down" : ""} ${value > 85 && value < 95 ? "medium" : ""}`}>
                                                    <span className='value'>
                                                        {formatValue(value)}%
                                                    </span>
                                                    <span className='icon icon-trending'></span>
                                                </div>
                                            </span>
                                        </li>
                                    )
                                )
                            })}
                            {Object.entries(item).map(([key, value], i) => {
                                return (
                                    (key === "mtdplan" || key === "ytdplan" || key === "mtdach" || key === "ytdach" || key === "mtdshortfall" || key === "ytdshortfall" || key === "par" || key === "nonpar" || key === "singlepremium" || key === "term" || key === "ulip" || key === "health") && (
                                        <li className='acc-item'>
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                {(cardName.toLowerCase() === "premium" || cardName.toLowerCase() === "ape" || cardName.toLowerCase() === "cp" || cardName.toLowerCase().replaceAll(' ', '') === "productmix-ape") ?
                                                    amountUnit(value, filterRange) : formatValue(value)
                                                }
                                            </span>
                                        </li>
                                    )
                                )
                            })}
                        </ul>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul className='acc-list'>
                            {Object.entries(item).map(([key, value], i) => {
                                return (
                                    key !== 'id' && key !== "channel" && key !== "agentname" && key !== "mtdach%" && key !== "ytdach%" && key !== "lm%" && key !== "mtdplan" && key !== "ytdplan" && key !== "mtdach" && key !== "ytdach" && key !== "mtdshortfall" && key !== "ytdshortfall" && key !== "par" && key !== "nonpar" && key !== "singlepremium" && key !== "term" && key !== "ulip" && key !== "health" && key !== "par%" && key !== "nonpar%" && key !== "singlepremium%" && key !== "term%" && key !== "ulip%" && key !== "health%" && key !== "agentcode" && (
                                        <li className='acc-item' >
                                            <span className='key'>{colname(key)}</span>
                                            <span className='pair'>
                                                {key === "lysm%" ? <div className={`mod-tag ${value <= 85 ? "down" : ""} ${value > 85 && value < 95 ? "medium" : ""}`}>
                                                    <span className='value'>
                                                        {formatValue(value)}%
                                                    </span>
                                                    <span className='icon icon-trending'></span>
                                                </div> :
                                                    key === "t-1" || key === "ftd" ? (cardName.toLowerCase() === "premium" || cardName.toLowerCase() === "ape" || cardName.toLowerCase() === "cp" || cardName.toLowerCase().replaceAll(' ', '') === "productmix-ape") ?
                                                        amountUnit(value, filterRange) : formatValue(value)
                                                        : value
                                                }
                                            </span>
                                        </li>
                                    )
                                )
                            })}
                            {item.channel == "Grand Total" || item.channel == "Group NB" || item.channel == "Total Retail" ?
                                <></> :
                                <li className='acc-item action-item'>
                                    <Button className="btn btn-link" onClick={() => handleCompAdvisorClick(item.agentcode, item.agentname, item.channel)}>View</Button>
                                </li>
                            }
                        </ul>
                    </AccordionDetails>
                </Accordion>

            ))
            }
            <div className='pagination-wrap'>
                <div className='left-wrap'>
                    <IconButton disableRipple className='btn btn-search' onClick={handlePrev} disabled={currentPage === 0}>
                        <span className='icon icon-prev'></span>
                    </IconButton>
                    <IconButton disableRipple className='btn btn-search' onClick={handleNext} disabled={currentPage === totalPages - 1}>
                        <span className='icon icon-next'></span>
                    </IconButton>
                </div>
                <div className='right-wrap'>
                    <span>Page</span>
                    <span>{currentPage + 1}</span>
                    <span>of {totalPages}</span>
                </div>
            </div>
        </div>

    )
}

export default CompanyGridData