// import React from "react";
import React, { useCallback } from "react";
import 'rsuite/dist/rsuite.min.css';
import '../src/common/assets/styles/custom.scss';
import { Routes, Route } from "react-router-dom";
import { protectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import NonAuthLayout from "./Layout/NonAuthLayout";
import AuthLayout from "./Layout/AuthLayout";
import useIdleTimer from './hooks/common/useIdleTimer';
import Errormodal from "./common/components/Modal/Errormodal";


const App = () => {
  const handleLogout = useCallback(() => {
    localStorage.clear();
    window.location.href = '/';
  }, []);

  useIdleTimer(handleLogout, 1000 * 60 * 15);
  return (

    <>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route?.path}
            element={
              <Authmiddleware
                component={route.component}
                layout={NonAuthLayout}
                isAuthProtected={false}
              />
            }
            key={idx}
          />
        ))}
        {protectedRoutes.map((route, idx) => (
          <Route
            path={route?.path}
            element={
              <Authmiddleware
                component={route.component}
                layout={AuthLayout}
                isAuthProtected={true}
              />
            }
            key={idx}
          />
        ))}
      </Routes>
      <Errormodal />
    </>
  );
};

export default App;