import axios from "axios";
import { useGeneralStore } from "../../store/generalStore";

export const useFetchData = () => {
    const setErrorMessage = useGeneralStore((state) => state.setErrorMessage);
    const setOpen = useGeneralStore((state) => state.setOpen);

    const fetchData = async (method, url, data, postHeaders, tokenName) => {
        try {
            const base_url = process.env.REACT_APP_BASE_URL;
            const headers = {
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY,
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tokenName}`,
            };

            const config = {
                method: method,
                maxBodyLength: Infinity,
                url: base_url + url,
                headers: postHeaders ? postHeaders : headers,
                data: data
            };

            const response = await axios.request(config);
            return response;
        } catch (error) {
            console.log(error);
            setOpen(true);
            setErrorMessage(error.message || 'An error occurred');
        }
    };

    return fetchData;
};
