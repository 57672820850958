import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { amountUnit } from '../../../../../utils/helper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuFilter from '@mui/material/Menu';
import Fade from "@mui/material/Fade";

const CollapsibleTable = ({ summaryData, filterRange, summaryFilter, handleSummaryFilter, handleFilterClose, handleFilterClick, open, anchorEl, handleMenuItemClick }) => {

    const data = summaryData;

    const [isVisible, setIsVisible] = useState('')
    const handleIsVisible = (index) => {
        if (index === isVisible) {
            setIsVisible('')
        } else {
            setIsVisible(index)
        }
    }


    return (
        <div className='bs-panel'>
            <div className='panel-head'>
                <div className='left-wrap'>
                    <h2 className='p-title'>{data?.name}</h2>
                </div>
                <div className='right-wrap'>
                    <div className='bs-select typ-sm cm-hidden-sm'>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={summaryFilter}
                                onChange={handleSummaryFilter}
                                IconComponent={(props) => (
                                    <span
                                        {...props}
                                        className={`icon-chevron-down ${props.className}`}
                                    ></span>
                                )}
                                MenuProps={{
                                    classes: {
                                        paper: "bs-menu-dropdown",
                                    },
                                }}
                                displayEmpty
                            >
                                <MenuItem value="ape">APE</MenuItem>
                                <MenuItem value="nop">NOP</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className='cm-hidden-md'>
                        <IconButton
                            disableRipple
                            className='btn btn-search'
                            aria-controls={open ? 'fade-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleFilterClick}
                        >
                            <span className='icon icon-filter'></span>
                        </IconButton>
                        <MenuFilter
                            id="fade-menu"
                            className="bs-menu-dropdown"
                            MenuListProps={{
                                "aria-labelledby": "fade-button",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleFilterClose}
                            TransitionComponent={Fade}
                            elevation={0}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <MenuItem onClick={() => handleMenuItemClick('ape')} selected={summaryFilter === 'ape'}>APE</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('nop')} selected={summaryFilter === 'nop'}>NOP</MenuItem>
                        </MenuFilter>
                    </div>
                </div>
            </div>
            <div className='panel-content'>
                <div className='bs-table typ-persistency typ-collasible'>
                    <table>
                        <thead>
                            <tr>
                                {
                                    data?.columnname.map((colName, index) => (<th key={index}>{colName?.displayName}</th>))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {data[summaryFilter]?.map((item, index) =>
                            (
                                <>
                                    <tr key={index}>
                                        {
                                            data?.columnname.map((colName, colIndex) =>
                                                colIndex === 0 && item.hasOwnProperty('moreDetails') ?
                                                    <td>
                                                        {item?.[colName?.colname]}
                                                        <IconButton disableRipple className='btn' onClick={() => handleIsVisible(index)}>
                                                            <span className={`icon ${index === isVisible ? "icon-chevron-up" : "icon-chevron-down"}`}></span>
                                                        </IconButton>
                                                    </td> :
                                                    <td key={index}>
                                                        {colIndex === 0 ? item?.[colName?.colname] : summaryFilter === "ape" ? amountUnit(item?.[colName?.colname], filterRange) : item?.[colName?.colname]}
                                                    </td>

                                            )
                                        }
                                    </tr>
                                    {
                                        item.hasOwnProperty('moreDetails') && index === isVisible && item.moreDetails.map((moreDetailsItem, index) =>
                                            <tr key={index} className='sub-table'>
                                                {
                                                    data?.columnname.map((colName, index) =>

                                                        <td key={index}>{index === 0 ? moreDetailsItem?.[colName?.colname] : summaryFilter === "ape" ? amountUnit(moreDetailsItem?.[colName?.colname], filterRange) : moreDetailsItem?.[colName?.colname]}
                                                        </td>
                                                    )
                                                }
                                            </tr>
                                        )

                                    }
                                </>
                            )
                            )}

                        </tbody >
                    </table>
                </div>

            </div>
        </div>
    );
};

export default CollapsibleTable;
