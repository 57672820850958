import React, { useState, useEffect } from 'react';
import loadIcon from '../../../common/assets/images/favicon.png'
import loadingImg from '../../../common/assets/images/three-dots.svg'
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import Textarea from '@mui/joy/Textarea';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import checkImg from '../../../common/assets/images/check.png'
import { Link, NavLink } from 'react-router-dom';

function Support() {
    // const style = {
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    // };
    // const [age, setAge] = React.useState('');

    // const handleChange = (event) => {
    //     setAge(event.target.value);
    // };

    // const [text, setText] = React.useState('');

    const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <div className='content-wrap'>
                <div className='bs-form'>
                    <h3 className='title'>Support Service</h3>
                    {loading ? (
                        <div className='cm-loader'>
                            <div className='load-img'>
                                <img src={loadIcon} alt='loader' />
                                <img src={loadingImg} alt='loading' className='loading-gif' />
                            </div>
                        </div>
                    ) : (
                        <form>
                            <Grid
                                container
                                rowSpacing={{ md: 3, xs: 3 }}
                                columnSpacing={{ md: 3, xs: 3 }}
                            >
                                {/* <Grid item md={5} sm={12} xs={12}>
                                    <div className='form-group'>
                                        <label className='form-label'>Select type of issue </label>
                                        <div className='bs-select'>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label" className='form-label'>Issue category</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    label="Age"
                                                    onChange={handleChange}
                                                    IconComponent={(props) => (
                                                        <span
                                                            {...props}
                                                            className={`icon-chevron-down ${props.className}`}
                                                        ></span>
                                                    )}
                                                    MenuProps={{
                                                        classes: {
                                                            paper: "bs-menu-dropdown",
                                                        },
                                                    }}
                                                    displayEmpty
                                                >
                                                    <MenuItem value="">Select from list</MenuItem>
                                                    <MenuItem value={10}>APE Ten</MenuItem>
                                                    <MenuItem value={20}>APE Twenty</MenuItem>
                                                    <MenuItem value={30}>APE Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <div className='form-group'>
                                        <label className='form-label'>Add description</label>
                                        <Textarea
                                            placeholder="Enter details"
                                            value={text}
                                            onChange={(event) => setText(event.target.value)}
                                            minRows={2}
                                            maxRows={4}
                                            className="form-control"
                                            endDecorator={
                                                <span>
                                                    {text.length}/200
                                                </span>
                                            }
                                        />
                                    </div>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <div className='form-action'>
                                        <Button className="btn btn-default" onClick={handleOpen}>SUBMIT</Button>
                                    </div>
                                </Grid> */}
                                <Grid item md={12} sm={12} xs={12}>
                                    <div className="bs-support">
                                        <div className="steps-wrap">
                                            <p className="steps-title">Support & Feedback</p>
                                            <ul className="list">
                                                <li className="item">
                                                    <div className="step-icon-wrap">
                                                        <span className="icon icon-chevron-right"></span>
                                                    </div>
                                                    <p className="step-desc">Click on the link <Link className='link' to="https://delta.futuregenerali.in" target='_blank'>https://delta.futuregenerali.in</Link></p>
                                                </li>
                                                <li className="item">
                                                    <div className="step-icon-wrap">
                                                        <span className="icon icon-chevron-right"></span>
                                                    </div>
                                                    <p className="step-desc">Once Delta tab is open then click on “Query”</p>
                                                </li>
                                                <li className="item">
                                                    <div className="step-icon-wrap">
                                                        <span className="icon icon-chevron-right"></span>
                                                    </div>
                                                    <p className="step-desc">Click on + sign and then Add Query page will open</p>
                                                </li>
                                                <li className="item">
                                                    <div className="step-icon-wrap">
                                                        <span className="icon icon-chevron-right"></span>
                                                    </div>
                                                    <p className="step-desc">Select type of query as  “IRIS Data related”</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <div className='form-group cm-align-center'>
                                        <label className='form-label'>Click on below button for Feedback</label>
                                    </div>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <div className='action-wrap'>
                                        <NavLink to="https://forms.office.com/r/RarvsE6mZE" target='_blank'>
                                            <Button className="btn btn-default">
                                                For Feedback
                                            </Button>
                                        </NavLink>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    )}

                </div>
            </div>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='bs-modal'
            >
                <div className='modal-wrap'>
                    <span className='icon icon-close close-btn' onClick={handleClose}></span>
                    <div className='icon-wrap'>
                        <img src={checkImg} alt='checkImg' />
                    </div>
                    <h3 className='m-title'>Thank you. Your query is submitted</h3>
                    <p className='m-desc'>Thank you for writing and submitting your query. We will get back to you soon.</p>
                    <div className='action-wrap'>
                        <Button className="btn btn-default" onClick={handleClose}>OKAY</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Support