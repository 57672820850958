import React from 'react'
import Button from '@mui/material/Button';
import FormFields from '../../../common/components/FormFields';
import StockTable from '../../../common/components/StockTable';
import StockBar from '../../../common/components/StockBar';
import TablePagination from '../../../common/components/TablePagination';
import PersistencyTable from '../../../common/components/PersistencyTable';
import { Link } from 'react-router-dom';
import DateRange from '../../../common/components/DateRange';
// import DateRanje from '../DateRange';

function Components() {
    return (
        <>
            {/* <div style={{ marginBottom: '24px' }}>
                <FooterFixedTable />
            </div> */}
            <div style={{ marginBottom: '24px' }}>
                <DateRange />
            </div>
            <div style={{ marginBottom: '24px' }}>
                <PersistencyTable />
            </div>
            <div style={{ marginBottom: '24px' }}>
                <TablePagination />
            </div>
            <div style={{ marginBottom: '24px' }}>
                <StockBar />
            </div>
            <div style={{ marginBottom: '24px' }}>
                <Button className="btn btn-default">LOGIN SECURELY</Button>
                <Button className="btn btn-default typ-md">SUBMIT</Button>
                <Button className="btn btn-default typ-sm">DOWNLOAD <span className='icon icon-download'></span></Button>
                <Button className="btn btn-link">Forgot Password?</Button>
                <Button className="btn btn-link typ-underline">SIGN IN</Button>
                <Button className="btn btn-icon"><span className='icon icon-download-arrow'></span> Download</Button>
                <Button className="btn btn-icon typ-grey"><span className='icon icon-filter'></span> Filters</Button>
            </div>
            <div style={{ marginBottom: '24px' }}>
                <FormFields />
            </div>
            <div style={{ marginBottom: '24px' }}>
                <StockTable />
            </div>
            <div style={{ marginBottom: '24px' }}>
                <div className='mod-tag'>
                    <span className='value'>29%</span>
                    <span className='icon icon-trending'></span>
                </div>
                <div className='mod-tag down'>
                    <span className='value'>58%</span>
                    <span className='icon icon-trending'></span>
                </div>
                <div className='mod-tag typ-border'>
                    <span className='value'>58%</span>
                    <span className='icon icon-trending'></span>
                </div>
            </div>
            <div style={{ marginBottom: '24px' }}>
                <div className='bs-breadcrumb'>
                    <ul className='b-list'>
                        <li className='b-item'>
                            <Link to="#" className='link'>Dashboard</Link>
                        </li>
                        <li className='b-item'>
                            <Link to="#" className='link'>Zone</Link>
                        </li>
                        <li className='b-item'>
                            <Link to="#" className='link'>Retail</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Components