import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import useTableFormat from '../../../../../hooks/common/useTableFormat';
import AgentGridData from '../AgentGridData';
import useSize from '../../../../../hooks/common/useSize'
import { useFetchData } from '../../../../../hooks/common/useApiService';
import { formatDate } from '../../../../../hooks/common/useDateFormat';

import loadIcon from '../../../../../common/assets/images/favicon.png'
import loadingImg from '../../../../../common/assets/images/three-dots.svg'

import { amountUnit } from '../../../../../utils/helper';

import Pagination from '@mui/material/Pagination';


function AgentPolicyTable({ filterRange, advisorcode, setAdvisorcode, selectedDate, token }) {
    const fetchData = useFetchData();
    const winDimensions = useSize();
    const [data, setData] = useState(null);
    const [columnsData, setColumnsData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(0);
    let pageSize = 7;

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const payloadData = JSON.stringify({
            "date_column": selectedDate !== "" ? selectedDate : formatDate(),
            // "date_column": formatDate(),
            "agent_id": advisorcode,
            // "date_column": "2024-04-30T00:00:00",
            // "agent_id": "80145123"
        });
        setLoading(true)
        setPage(0)


        fetchData('post', process.env.REACT_APP_POLICY_COMPINE, payloadData, null, token)?.then((response) => {
            var cardData = response?.data?.response?.login;
            setData(cardData)
            setFilteredData(cardData?.details);
            const colData = (cardData?.columns ?? []).map(col => {
                if (col && col.colname && col.colname !== 'id'
                    && (col.colname === 'applicationno'
                        || col.colname === 'policyno'
                        || col.colname === 'status'
                        || col.colname === 'trandesc'
                        || col.colname === 'agentcode'
                        || col.colname === 'agentname'
                        || col.colname === 'agenttype'
                        || col.colname === 'customername'
                        || col.colname === 'logindate'
                        || col.colname === 'nextpremiumduedate'
                        || col.colname === 'annualpremiumamount'
                        || col.colname === 'weightedpremium'
                        || col.colname === 'collectedpremium'
                        || col.colname === 'sumassured'
                        || col.colname === 'premiumpayingterm'
                        || col.colname === 'policyterm'
                        || col.colname === 'paymentfrequency'
                        || col.colname === 'productname'
                        || col.colname === 'producttype'
                        || col.colname === 'freshrenewal')
                ) {
                    return {
                        field: col.colname,
                        headerName: col.displayName,
                        width: col.dWidth,
                    };
                }
                return null;
            }).filter(Boolean);
            setColumnsData(colData)
            setLoading(false)
        })
    }, [advisorcode, selectedDate, token]);



    const handleChangePage = (event, newPage) => {
        setPage(newPage - 1);
    };
    const [searchShow, setSearchShow] = React.useState(false)
    const handleSearch = () => {
        setSearchShow(true)
    }

    const { divRef, columns } = useTableFormat(columnsData);

    const CustomCellRenderer = ({ value, column, row }) => {
        if (column.field === 'annualpremiumamount' || column.field === 'weightedpremium' || column.field === 'collectedpremium' || column.field === 'sumassured') {
            return (
                <div>
                    {amountUnit(value, filterRange)}
                </div>
            )
        }

        return <div data-th={column?.headerName || ''}>{value}</div>;
    };



    const handleFilterSearch = (event, value) => {
        setSearchTerm(value);
        if (value === "") {
            setFilteredData(data?.details || []);
        } else {
            const lowercasedValue = value.toLowerCase();
            const filtered = data?.details.filter((item) =>
                Object.keys(item).some((key) =>
                    item[key]?.toString().toLowerCase().includes(lowercasedValue)
                )
            );
            setFilteredData(filtered);
            setPage(0)
        }
    };

    const handleSearchClear = () => {
        setSearchTerm("")
        setFilteredData(data?.details || []);
        setSearchShow(false)
    }

    return (
        <>
            <div className={`bs-panel typ-table typ-auto ${data?.headingcolour} ${data?.bgcolor}`}>
                <div className='panel-head'>
                    <div className='left-wrap'>
                        <h2 className='p-title'>{data?.name}</h2>
                    </div>
                    <div className='right-wrap'>
                        <div className={`bs-search  ${searchShow ? 'active' : ''}`}>
                            <Autocomplete
                                id="free-solo-demo"
                                freeSolo
                                open={false}
                                value={searchTerm}
                                options={filteredData?.flatMap(Object.values)
                                    .filter(value => value !== null && value !== undefined)
                                    .map(value => value.toString())}
                                onInputChange={handleFilterSearch}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        InputProps={{
                                            ...params.InputProps,

                                            endAdornment: (
                                                <InputAdornment position="end" onClick={handleSearchClear}>
                                                    <span className={`icon icon-${searchTerm === "" ? "search" : "close"}`}></span>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder='Search' />
                                )}
                                classes={{ paper: 'bs-menu-dropdown' }}

                            />
                        </div>
                        <div className='cm-hidden-md'>
                            <IconButton disableRipple className='btn btn-search' onClick={handleSearch}>
                                <span className='icon icon-search'></span>
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className='panel-content' ref={divRef}>
                    {winDimensions[0] <= 768 ?
                        (
                            loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (
                                <AgentGridData data={data} filterRange={filterRange} filteredData={filteredData} />
                            )
                        ) : (
                            loading ? (
                                <div className='cm-loader'>
                                    <div className='load-img'>
                                        <img src={loadIcon} alt='loader' />
                                        <img src={loadingImg} alt='loading' className='loading-gif' />
                                    </div>
                                </div>
                            ) : (
                                <DataGrid
                                    className='bs-data-table'
                                    rows={searchTerm ? filteredData.slice(page * pageSize, page * pageSize + pageSize) : data?.details?.length > 0 ? data?.details.slice(page * pageSize, page * pageSize + pageSize) : []}
                                    columns={columnsData.map(col => ({ ...col, renderCell: (params) => <CustomCellRenderer {...params} column={col} row={params.row} /> }))}
                                    pageSize={pageSize}
                                />
                            )
                        )}
                </div>
            </div>
            <div className='table-pagination'>
                <Pagination
                    count={Math.ceil(data?.details?.length / pageSize)}
                    page={page + 1}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                    className='bs-pagination'
                />
            </div>
        </>

    )
}

export default AgentPolicyTable