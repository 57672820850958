import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFilterRange } from '../../../store/generalStore'
function RupeesFilter() {
    const { filterRange, setFilterRange } = useFilterRange();

    const handleFilterRange = (event) => {
        setFilterRange(event.target.value);
    };
    return (
        <div className='bs-select typ-sm'>
            <FormControl fullWidth>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterRange}
                    onChange={handleFilterRange}
                    IconComponent={(props) => (
                        <span
                            {...props}
                            className={`icon-chevron-down ${props.className}`}
                        ></span>
                    )}
                    MenuProps={{
                        classes: {
                            paper: "bs-menu-dropdown",
                        },
                    }}
                    displayEmpty
                >
                    <MenuItem value="cr">Cr</MenuItem>
                    <MenuItem value="lacs">Lacs</MenuItem>
                    <MenuItem value="absolute">Absolute</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default RupeesFilter