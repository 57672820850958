import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function GridData() {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <div className='lyt-grid-table'>
            <Accordion className='bs-table-accordion'>
                <AccordionSummary
                    expandIcon={<span className='icon icon-chevron-down'></span>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <span className='key'>Channel Name:</span>
                            <span className='pair'>Channel 1</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>Channel Head Name</span>
                            <span className='pair'>Abhishek Acharya</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>FTD</span>
                            <span className='pair'>
                                <div className='mod-indicator'>
                                    32.4 <span className='icon icon-up' ></span>
                                </div>
                            </span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD </span>
                            <span className='pair'>122.9</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD Actual</span>
                            <span className='pair'>501.01 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD %</span>
                            <span className='pair'>
                                <div className='mod-tag'>
                                    <span className='value'>110.82%</span>
                                    <span className='icon icon-trending'></span>
                                </div>
                            </span>
                        </li>
                    </ul>
                </AccordionSummary>
                <AccordionDetails>
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <span className='key'>YTD</span>
                            <span className='pair'>39.19 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>YTD Actual</span>
                            <span className='pair'>501.01 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>YTD Percentage</span>
                            <span className='pair'>
                                <div className='mod-tag down'>
                                    <span className='value'>110.82%</span>
                                    <span className='icon icon-trending'></span>
                                </div>
                            </span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>LYSM</span>
                            <span className='pair'>12.8 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>LYSM Percentage</span>
                            <span className='pair'>
                                <div className='mod-tag'>
                                    <span className='value'>110.82%</span>
                                    <span className='icon icon-trending'></span>
                                </div>
                            </span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD Actual</span>
                            <span className='pair'>501.01 Cr</span>
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            <Accordion className='bs-table-accordion'>
                <AccordionSummary
                    expandIcon={<span className='icon icon-chevron-down'></span>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <span className='key'>Channel Name:</span>
                            <span className='pair'>Channel 2</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>Channel Head Name</span>
                            <span className='pair'>Abhishek Acharya</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>FTD</span>
                            <span className='pair'>
                                <div className='mod-indicator'>
                                    32.4 <span className='icon icon-up' ></span>
                                </div>
                            </span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD</span>
                            <span className='pair'>39.19 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD Actual</span>
                            <span className='pair'>501.01 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD Actual</span>
                            <span className='pair'>
                                <div className='mod-tag'>
                                    <span className='value'>110.82%</span>
                                    <span className='icon icon-trending'></span>
                                </div>
                            </span>
                        </li>
                    </ul>
                </AccordionSummary>
                <AccordionDetails>
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <span className='key'>YTD</span>
                            <span className='pair'>39.19 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>YTD Actual</span>
                            <span className='pair'>501.01 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>YTD Percentage</span>
                            <span className='pair'>
                                <div className='mod-tag down'>
                                    <span className='value'>110.82%</span>
                                    <span className='icon icon-trending'></span>
                                </div>
                            </span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>LYSM</span>
                            <span className='pair'>12.8 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>LYSM Percentage</span>
                            <span className='pair'>
                                <div className='mod-tag'>
                                    <span className='value'>110.82%</span>
                                    <span className='icon icon-trending'></span>
                                </div>
                            </span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD Actual</span>
                            <span className='pair'>501.01 Cr</span>
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            <Accordion className='bs-table-accordion'>
                <AccordionSummary
                    expandIcon={<span className='icon icon-chevron-down'></span>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <span className='key'>Channel Name:</span>
                            <span className='pair'>Retail Agent Channel</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>Channel Head Name</span>
                            <span className='pair'>Abhishek Acharya</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>FTD</span>
                            <span className='pair'>
                                <div className='mod-indicator'>
                                    32.4 <span className='icon icon-up' ></span>
                                </div>
                            </span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD</span>
                            <span className='pair'>39.19 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD Actual</span>
                            <span className='pair'>501.01 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD Actual</span>
                            <span className='pair'>
                                <div className='mod-tag'>
                                    <span className='value'>110.82%</span>
                                    <span className='icon icon-trending'></span>
                                </div>
                            </span>
                        </li>
                    </ul>
                </AccordionSummary>
                <AccordionDetails>
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <span className='key'>YTD</span>
                            <span className='pair'>39.19 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>YTD Actual</span>
                            <span className='pair'>501.01 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>YTD Percentage</span>
                            <span className='pair'>
                                <div className='mod-tag down'>
                                    <span className='value'>110.82%</span>
                                    <span className='icon icon-trending'></span>
                                </div>
                            </span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>LYSM</span>
                            <span className='pair'>12.8 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>LYSM Percentage</span>
                            <span className='pair'>
                                <div className='mod-tag'>
                                    <span className='value'>110.82%</span>
                                    <span className='icon icon-trending'></span>
                                </div>
                            </span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD Actual</span>
                            <span className='pair'>501.01 Cr</span>
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            <Accordion className='bs-table-accordion'>
                <AccordionSummary
                    expandIcon={<span className='icon icon-chevron-down'></span>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <span className='key'>Channel Name:</span>
                            <span className='pair'>Retail Channel 2</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>Channel Head Name</span>
                            <span className='pair'>Abhishek Acharya</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>FTD</span>
                            <span className='pair'>
                                <div className='mod-indicator'>
                                    32.4 <span className='icon icon-up' ></span>
                                </div>
                            </span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD</span>
                            <span className='pair'>39.19 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD Actual</span>
                            <span className='pair'>501.01 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD Actual</span>
                            <span className='pair'>
                                <div className='mod-tag'>
                                    <span className='value'>110.82%</span>
                                    <span className='icon icon-trending'></span>
                                </div>
                            </span>
                        </li>
                    </ul>
                </AccordionSummary>
                <AccordionDetails>
                    <ul className='acc-list'>
                        <li className='acc-item'>
                            <span className='key'>YTD</span>
                            <span className='pair'>39.19 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>YTD Actual</span>
                            <span className='pair'>501.01 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>YTD Percentage</span>
                            <span className='pair'>
                                <div className='mod-tag down'>
                                    <span className='value'>110.82%</span>
                                    <span className='icon icon-trending'></span>
                                </div>
                            </span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>LYSM</span>
                            <span className='pair'>12.8 Cr</span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>LYSM Percentage</span>
                            <span className='pair'>
                                <div className='mod-tag'>
                                    <span className='value'>110.82%</span>
                                    <span className='icon icon-trending'></span>
                                </div>
                            </span>
                        </li>
                        <li className='acc-item'>
                            <span className='key'>MTD Actual</span>
                            <span className='pair'>501.01 Cr</span>
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            <div className='pagination-wrap'>
                <div className='left-wrap'>
                    <IconButton disableRipple className='btn btn-search'>
                        <span className='icon icon-prev'></span>
                    </IconButton>
                    <IconButton disableRipple className='btn btn-search'>
                        <span className='icon icon-next'></span>
                    </IconButton>
                </div>
                <div className='right-wrap'>
                    <span>Page</span>
                    <div className='bs-select typ-sm'>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleChange}
                                IconComponent={(props) => (
                                    <span
                                        {...props}
                                        className={`icon-chevron-down ${props.className}`}
                                    ></span>
                                )}
                                MenuProps={{
                                    classes: {
                                        paper: "bs-menu-dropdown",
                                    },
                                }}
                                displayEmpty
                            >
                                <MenuItem value="">1</MenuItem>
                                <MenuItem value={1}>2</MenuItem>
                                <MenuItem value={2}>3</MenuItem>
                                <MenuItem value={3}>4</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <span>of 10</span>
                </div>
            </div>
        </div>

    )
}

export default GridData